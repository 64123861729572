package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable

@Serializable
data class NewUser(
    var uname: String,
    var eposta: String,
    var adsoyad: String,
    var pass: String,
    var snf: Int,
    var role: Int
)

@Serializable
data class OgrenciInfo(
    var uuid: String,
    var name: String,
    var eposta: String,
    var snf: Int,
    var snfid: Long = 0,
)






