package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.viewmodels.ProfileVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonBadge
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonType
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaEyeSlash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Page("/profil")
@Composable
fun Profil() {
    IfUserExist(Screens.Profil) {
        RouterPage()
    }
}

@Composable
fun ProfilPage() {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val context = rememberPageContext()
    val user = AppData.appUser
    val profileVM = AppData.ViewModel.profileVM

    var showPassDegis by remember { mutableStateOf(false) }


    PageBox(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(AppColors.Background)
            .minWidth(240.px)

    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(
                    if (breakpoint >= Breakpoint.MD)
                        84.percent else 96.percent
                )
                .padding(topBottom = 20.px, leftRight = 30.px)
                .maxWidth(700.px)
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .borderRadius(r = 8.px)
                .backgroundColor(Colors.White)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(bottom = 10.px)
                    .padding(leftRight = 4.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                SpanText(
                    "Hesap Bilgileri", modifier = Modifier
                        .fillMaxWidth()
                        .color(AppColors.PrimaryDark)
                        .fontSize(20.px)
                )
                BSButton(
                    text = "Kapat",
                    type = ButtonType.Button,
                    variant = ButtonVariant.DangerOutline,
                    size = ButtonSize.Small,

                    ) {
                    AppData.appRoutes.navigateTo(context, Screens.Home)
                }

            }

            VDivider()

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 2.px)
                    .padding(4.px), verticalAlignment = Alignment.Bottom

            ) {
                SpanText(
                    "Hesap Adı:", modifier = Modifier
                        .color(AppColors.Gray)
                        .fillMaxWidth()
                )
                SpanText(
                    AppData.appUser.uname, modifier = Modifier
                        .color(AppColors.GrayDark)
                        .fontWeight(FontWeight.Medium)
                        .fontSize(18.px)
                        .fillMaxWidth()
                )

            }

            profileLine("İsminiz:", AppData.appUser.adsoyad)

            if (user.role == 0) {
                OgrenciSnf.entries.find { it.snf == user.snf }?.let {
                    profileLine("Sınıfınız:", it.text)

                }
                if (user.scode.isNotEmpty()) {
                    profileLine("Sınıf Referans Kodu:", user.scode, true)
                    SpanText("Bir sınıfa kaydolmak için Sınıf Referans kodunuzu öğretmeninize gönderiniz.",
                        modifier = Modifier.fontSize(11.px)
                            .color(AppColors.TextColor)
                            .margin(top = (-9).px, left = 5.px)
                    )
                }
                if (user.vcode.isNotEmpty()) {
                    profileLine("Veli Referans Kodu:", user.vcode, true)
                    SpanText("Veli hesabından erişim için referans kodu.",
                        modifier = Modifier.fontSize(11.px)
                            .color(AppColors.TextColor)
                            .margin(top = (-9).px, left = 5.px)
                    )
                }
            }

            VDivider()

            BSButton(
                modifier = Modifier.margin(topBottom = 6.px),
                text = "Şifre Değiştir",
                type = ButtonType.Button,
                variant = ButtonVariant.SecondaryOutline,
                size = ButtonSize.Small,

                ) {
                showPassDegis = true
            }


        }

        if (showPassDegis) {
            DialogView {
                passChange(profileVM, scope) {
                    showPassDegis = false
                }
            }
        }
        if (profileVM.alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = profileVM.alertModel!!.message,
                dismissible = false,
                icon = profileVM.alertModel!!.icon,
                style = profileVM.alertModel!!.style
            )
        }

    }

}

@Composable
fun passChange(
    profileVM: ProfileVM, scope: CoroutineScope,
    onClose: () -> Unit
) {
    var oldPass by remember { mutableStateOf("") }
    var newPass1 by remember { mutableStateOf("") }
    var newPass2 by remember { mutableStateOf("") }

    Box(
        modifier = Modifier.fillMaxWidth()
            .maxWidth(480.px),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .boxShadow(offsetY = 1.px, offsetX = 1.px, color = AppColors.Background)
                .padding(bottom = 16.px, left = 4.px)
                .borderRadius(r = 10.px)
                .backgroundColor(Colors.White),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            DialogTitle("Şifre Değiştir", fontSize = 15, onClose = onClose)
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(10.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    BSInput(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(bottom = 14.px),
                        type = InputType.Password,
                        value = oldPass,
                        placeholder = "Mevcut Şifreniz",
                        required = true,
                        onValueChange = {
                            oldPass = it
                        }
                    )
                    FaEyeSlash(
                        size = IconSize.SM,
                        style = IconStyle.FILLED,
                        modifier = Modifier.color(AppColors.TextColor)
                            .margin(left = (-24).px, top = (-8).px)
                    )
                }

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    BSInput(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(bottom = 14.px),
                        type = InputType.Password,
                        value = newPass1,
                        placeholder = "Yeni Şifreniz",
                        required = true,
                        onValueChange = {
                            newPass1 = it
                        }
                    )
                    FaEyeSlash(
                        size = IconSize.SM,
                        style = IconStyle.FILLED,
                        modifier = Modifier.color(AppColors.TextColor)
                            .margin(left = (-24).px, top = (-8).px)
                    )
                }

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    BSInput(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(bottom = 14.px),
                        type = InputType.Password,
                        value = newPass2,
                        placeholder = "Yeni şifre tekrar",
                        required = true,
                        onValueChange = {
                            newPass2 = it
                        }
                    )
                    FaEyeSlash(
                        size = IconSize.SM,
                        style = IconStyle.FILLED,
                        modifier = Modifier.color(AppColors.TextColor)
                            .margin(left = (-24).px, top = (-8).px)
                    )
                }

                BSButton(
                    modifier = Modifier.margin(topBottom = 6.px),
                    text = "Şifre Değiştir",
                    type = ButtonType.Button,
                    variant = ButtonVariant.SuccessOutline,
                    size = ButtonSize.Small,

                    ) {
                    scope.launch {
                        val res = profileVM.changeUserPass(oldPass, newPass1, newPass2)
                        if (res) {
                            onClose()

                        }
                    }
                    //onChange(oldPass, newPass1)
                }
            }
        }

    }

}


@Composable
fun profileLine(title: String, value: String, code: Boolean = false) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .margin(bottom = 2.px)
            .padding(4.px), verticalAlignment = Alignment.Bottom

    ) {
        SpanText(
            title, modifier = Modifier
                .color(if (code) AppColors.Red.opacity(0.9f) else AppColors.Gray)
                .fillMaxWidth()
        )
        SpanText(
            value, modifier = Modifier
                .color(if (code) AppColors.DarkRed else AppColors.GrayDark)
                .fontWeight(if (code) FontWeight.SemiBold else FontWeight.Medium)
                .fontSize(if (code) 19.px else 18.px)
                .fillMaxWidth()
        )

    }
}