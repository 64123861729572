package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.Images
import com.kelimesoft.etutpro.models.Screens
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px


@Composable
fun SnfNavBar(snfVM: SinifVM) {
    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(AppColors.AppBarColor)
            .borderRadius(r = 12.px)

    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(6.px)
                .backgroundImage(AppColors.GradientPrimary)
                .borderRadius(r = 12.px)

        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                AppBrand()
                if (breakpoint > Breakpoint.SM) {
                    SnfMenuButtons(16, snfVM.aktifPage){
                        snfVM.aktifPage = it
                    }
                }
                Spacer()

                UserInfo(breakpoint)
            }
            if (breakpoint <= Breakpoint.SM) {
                SnfMenuButtons(14, snfVM.aktifPage){
                    snfVM.aktifPage = it
                }
            }
        }
    }


}


@Composable
fun SnfMenuButtons(size: Int, selected: SnfPage, onSelect:(SnfPage) -> Unit) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        AppBarMenuButton(
            "Özet", Images.Ozet,
            selected == SnfPage.Ozet, size
        ) {
            onSelect(SnfPage.Ozet)
        }
        AppBarMenuButton(
            "Etüt", Images.Etut,
            selected == SnfPage.Etut, size
        ) {
            onSelect(SnfPage.Etut)
        }
    }
}