package com.kelimesoft.etutpro.utils

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.asList
import kotlin.js.Date

object Funcs {
    fun getLanguage(): String{
        try {
            var lang = window.navigator.language
            if (lang.length > 1){
                return lang.substring(0,2)
            }
            val langs = window.navigator.languages
            return if (langs.isNotEmpty()){
                langs[0].substring(0,2)
            }else{
                return "en"
            }
        }catch (e: Exception){
            console.log(e.toString())
        }
        return "tr"
    }

    fun checkForm(id: String): Boolean {
        val form = document.getElementById(id) as HTMLFormElement
        if (!form.checkValidity()){
            form.reportValidity()
            return false
        }
        return true
    }

    fun getAnID(): Long {
        val idStr = Date.now().toLong().toString()
        return idStr.substring(idStr.length - 10).toLong()
    }


    fun closeDropDowns(){
        val menus = document.getElementsByClassName("app-menu")
        menus.asList().forEach { elm ->
            (elm as HTMLElement).style.display = "none"
        }
    }

    fun getWeekDay(date: Date): Int{
        var dayofweek = date.getDay()
        if (dayofweek == 0) dayofweek = 7
        return dayofweek
    }

    fun getWeekStartDay(date: Date): Date{
        var firstweekday = date.getDay()
        if (firstweekday == 0) firstweekday = 7
        return date.addDays(-(firstweekday) + 1)
    }


    fun isMobile(): Boolean {
        val userAgent = window.navigator.userAgent.lowercase()
        return userAgent.contains("android") || userAgent.contains("iphone") ||
                userAgent.contains("ipad") || userAgent.contains("ipod") ||
                userAgent.contains("mobile")
    }
}