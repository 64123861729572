package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable


@Serializable
data class DersItem(
    val id: Long = 0,
    var sid: Long,
    var oid: Long,
    var dersad: String,
    var renk: Int
)

@Serializable
data class DersProgItem(
    val id: Long = 0,
    var dersad: String = "",
    var renk: Int = 0,
    var gun: Int,
    var sira: Int,
)

@Serializable
data class DersItemBinding(
    var sid: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid,
    val ders: DersItem
)

@Serializable
data class NewDersProgBinding(
    var uuid: String = AppData.appUser.uuid,
    var did: Long,
    var gun: Int,
    var sira: Int

)

@Serializable
data class DersUpdateBinding(
    var sid: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid,
    val ders: DersProgItem,
    val gun: Int,
    val sira: Int
)

@Serializable
data class SnfScoreRequest(
    var uuid: String = AppData.appUser.uuid,
    val snfid: Long
)

@Serializable
data class SnfKatilRequest(
    val uuid: String,
    val snf: Int,
    val snfad: String,
    val code: String
)





