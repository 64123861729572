package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.*
import com.kelimesoft.etutpro.utils.*
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData
import kotlin.js.Date


class EtutVM: BaseVM() {
    var weekStartDate by mutableStateOf(Funcs.getWeekStartDay(Date()))

    var weekDays: List<String> by mutableStateOf(makeWeekDayList(weekStartDate))

    var userAward by mutableStateOf(0.0)
    var kitapSayfa by mutableStateOf(0)

    var snfScore by mutableStateOf(0.0)


    var allEtutList: List<EtutItem> = listOf()

    var etutListMap by mutableStateOf(mapOf<String, List<EtutItem>>())
    var etutListDersMap by mutableStateOf(mapOf<String, List<EtutItem>>())

    var etutSelectForCopy: EtutItem? by mutableStateOf(null)
    var copiedEtut: EtutItem? by mutableStateOf(null)
    var copiedUserUuid: String? by mutableStateOf("")
    var gunEtutList by mutableStateOf(listOf<EtutItem>())


    var searchText: String = ""

    var searchResult by mutableStateOf(listOf<EtutItem>())

    var etutDragSaat by mutableStateOf("")

    var kaynakDersOver by mutableStateOf("")

    var textGirEtutItem: EtutItem? = null


    var etutScrolling by mutableStateOf(false)

    suspend fun getEtutList(uuid: String? = null) {
        if (allEtutList.isEmpty()) {
            val json = Json {
                ignoreUnknownKeys = true
            }
            RestApi.getEtutList(weekStartDate.dateToDbStr(), uuid)?.let { res ->
                //console.log("etutlist:", res)
                json.decodeFromString<EtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        allEtutList = eres.data
                        handleEtutList(true)
                    }
                }
            }
        }
    }



    suspend fun replanUnfinished(): String? {
        var uuid = AppData.appUser.uuid
        if (AppData.appUser.role > 0) {
            if (AppData.ViewModel.ogrenciVM.selectedOgrenci != null) {
                uuid = AppData.ViewModel.ogrenciVM.selectedOgrenci!!.uuid
            } else {
                return "Öğrenci Seçiniz!"
            }
        }
        RestApi.replanUnfinished(weekStartDate.dateToDbStr(), uuid)?.let { res ->
            //console.log("etutlist:", res)
            Json.decodeFromString<EtutListResponse>(res).let { eres ->
                if (eres.data != null) {
                    allEtutList = eres.data
                    handleEtutList(true)
                }
            }
        }
        return null
    }

    suspend fun gotoNextWeek() {
        allEtutList = listOf()
        weekStartDate = Funcs.getWeekStartDay(weekStartDate.addDays(7))
        weekDays = makeWeekDayList(weekStartDate)
        etutListMap = mapOf()
        etutListDersMap = mapOf()
        if (AppData.appUser.role == 0) {
            getEtutList()
        } else if (AppData.ViewModel.ogrenciVM.selectedOgrenci != null) {
            getEtutList(AppData.ViewModel.ogrenciVM.selectedOgrenci!!.uuid)
        }
    }

    suspend fun gotoPreviousWeek() {
        allEtutList = listOf()
        weekStartDate = Funcs.getWeekStartDay(weekStartDate.addDays(-7))
        weekDays = makeWeekDayList(weekStartDate)
        etutListMap = mapOf()
        etutListDersMap = mapOf()
        if (AppData.appUser.role == 0) {
            getEtutList()
        } else if (AppData.ViewModel.ogrenciVM.selectedOgrenci != null) {
            getEtutList(AppData.ViewModel.ogrenciVM.selectedOgrenci!!.uuid)
        }

    }


    suspend fun addNewEtut(
        etutItem: NewEtutItem, uuid: String? = null,
        docs: List<String> = listOf(),
        uuids: List<String> = listOf()
    ): FuncResult {
        val vres = validateEtutItem(etutItem)
        if (vres != null) {
            return FuncResult(false, vres)
        }
        RestApi.addNewEtut(etutItem, uuid, docs, uuids)?.let { res ->
            Json.decodeFromString<AddEtutResponse>(res).let { eres ->
                if (eres.data != null) {
                    val nlist = allEtutList.toMutableList()
                    nlist.add(eres.data)
                    allEtutList = nlist.toList()
                    handleEtutList()
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun editExistingEtut(etutItem: EtutItem, docs: List<String> = listOf()): FuncResult {
        RestApi.editExistingEtut(etutItem, docs)?.let { res ->
            Json.decodeFromString<BoolResponse>(res).let { eres ->
                if (eres.data != null) {
                    val nlist = allEtutList.filter { it.id != etutItem.id }.toMutableList()
                    nlist.add(etutItem)
                    allEtutList = nlist.toList()
                    handleEtutList()
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }


    suspend fun getGunEtutList(uuid: String? = null) {
        if (gunEtutList.isEmpty()) {
            RestApi.getGunEtutList(Date().dateToDbStr(), uuid)?.let { res ->
                //console.log("etutlist:", res)
                Json.decodeFromString<EtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        gunEtutList = eres.data
                    }
                }
            }
        }
    }


    suspend fun getSearchEtut(text: String, uuid: String? = null) {
        //console.log("searchtext:", text)
        if (text.isNotEmpty())
            RestApi.getEtutSearch(text, uuid)?.let { res ->
                //console.log("etutlist:", res)
                Json.decodeFromString<EtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        searchText = text
                        searchResult = eres.data
                    }
                }
            }
    }


    suspend fun editEtutWithDrag(etutItem: EtutItem) {
        RestApi.editWithDrag(etutItem)?.let { res ->
            Json.decodeFromString<BoolResponse>(res).let { eres ->
                if (eres.data != null) {
                    val nlist = allEtutList.filter { it.id != etutItem.id }.toMutableList()
                    nlist.add(etutItem)
                    allEtutList = nlist.toList()
                    handleEtutList()
                }
            }
        }
    }


    suspend fun etutDone(etutItem: EtutItem, fromOzet: Boolean = false): FuncResult {
        var score = 0.0
        var dogru = 0
        var yanlis = 0
        if (etutItem.type == EtutType.Deneme.type || etutItem.type == EtutType.KonuTest.type) {
            score = etutItem.net
            dogru = etutItem.dogru
            yanlis = etutItem.yanlis
        }
        RestApi.etutDone(etutItem, dogru, yanlis, score)?.let { res ->
            Json.decodeFromString<BoolResponse>(res).let { eres ->
                if (eres.data != null) {
                    updateAllList(etutItem, fromOzet)
                    handleEtutList(true)
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun etutDoneWithCevap(fromOzet: Boolean = false): FuncResult {
        textGirEtutItem?.let { etut ->
            var snf = AppData.appUser.snf
            if (AppData.appUser.role != 0){
                snf = AppData.ViewModel.ogrenciVM.selectedOgrenci?.snf ?: 0
            }
            RestApi.etutDoneWithCevap(etut, snf)?.let { res ->
                Json.decodeFromString<AddEtutResponse>(res).let { eres ->
                    if (eres.data != null) {
                        updateAllList(eres.data, fromOzet)
                        handleEtutList(true)
                        return FuncResult(true)
                    } else if (eres.error != null) {
                        return FuncResult(false, eres.error)
                    }
                }
            }
            return FuncResult(false, "Connection Error")
        }?:run {
            return FuncResult(true)
        }

    }


    suspend fun etutDelete(etut: EtutItem) {
        RestApi.deleteEtut(etut.id, etut.eid)?.let { res ->
            Json.decodeFromString<BoolResponse>(res).let { eres ->
                if (eres.data != null) {
                    etutDeleteUpdate(etut)
                    handleEtutList(true)
                }
            }
        }
    }



    //Sınıf Functions
    suspend fun getSinifScore(snfId: Long) {
        RestApi.getSnfScore(snfId)?.let { res ->
            //console.log("etutlist:", res)
            Json.decodeFromString<DoubleResponse>(res).let { eres ->
                if (eres.data != null) {
                    snfScore = eres.data
                }
            }
        }
    }

    suspend fun snfKatil(uuid: String, snf: Int, snfad: String, code: String): FuncResult {
        if (!Funcs.checkForm("sinif-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        try {
            RestApi.sinifaKatil(uuid, snf, snfad, code)?.let { res ->
                Json.decodeFromString<LongResponse>(res).let { ares ->
                    if (ares.data != null){
                        val snfId = ares.data
                        if (snfId > 0){
                            if (AppData.appUser.role == 2){
                                AppData.ViewModel.ogrenciVM.selectedOgrenci?.snfid = snfId
                            }else {
                                AppData.appUser.snfid = snfId
                            }
                            //sınıfa katıldktan sonra etüt ve kaynak bilgileri yenileniyor
                            getSinifScore(snfId)
                            AppData.ViewModel.kaynakVM.allKaynakList = listOf()
                            AppData.ViewModel.kaynakVM.getKaynakList(uuid)
                            allEtutList = listOf()
                            etutListMap = mapOf()
                            getEtutList(uuid)
                        }
                        return FuncResult(true)
                    }else if (ares.error != null){
                        return FuncResult(false, ares.error)
                    }
                }
            }
        }finally {

        }

        return FuncResult(false, "Bağlantı hatası")
    }


    private fun makeWeekDayList(start: Date): List<String> {
        val daysList: MutableList<String> = mutableListOf()
        daysList.add(start.dateToDbStr())
        (1..6).forEach {
            val ndate = start.addDays(it)
            daysList.add(ndate.dateToDbStr())
        }
        return daysList
    }

    private fun handleEtutList(calculate: Boolean = false) {
        etutListMap = allEtutList.groupBy { it.tarih }
        etutListDersMap = allEtutList.groupBy { it.ders }
        if (calculate) {
            //kullanıcı award puan hesapla
            var konuPuan = 0
            var netPuan = 0.0
            var kitapsyf = 0
            allEtutList.filter { it.done }.forEach {
                if (it.type == EtutType.Kitap.type) {
                    //kitap okumadan max 8 puan almak için
                    if (it.puan > 0) kitapsyf += if (it.puan > 64) 64 else it.puan
                } else if (it.type == EtutType.Kuran.type) {
                    //kitap okumadan max 8 puan almak için
                    konuPuan += it.puan * 5
                } else if (it.type == EtutType.Konu.type ||
                    it.type == EtutType.Video.type) {
                    konuPuan += it.puan
                    //else if(it.soru > 0) puan += it.soru
                }else{
                    netPuan += it.net
                }
            }
            userAward = (netPuan + konuPuan + (kitapsyf / 8).toDouble()).rountTo(1)
            kitapSayfa = kitapsyf
        }
        etutListMap[Date().dateToDbStr()]?.let { gunlist ->
            gunEtutList = gunlist
        }
    }

    private fun updateAllList(old: EtutItem, fromOzet: Boolean = false) {
        val netut = old.copy(done = !old.done)
        var nEtutList = allEtutList.toMutableList()
        nEtutList.indexOfFirst { it.id == netut.id }.let { idx ->
            try {
                nEtutList[idx] = netut
                allEtutList = nEtutList.toList()
            } catch (_: Exception) {
            }

        }
        if (fromOzet) {
            val gEtutList = gunEtutList.toMutableList()
            gEtutList.indexOfFirst { it.id == netut.id }.let { idx ->
                //eğer index mevcut değilse -1 sonucu
                try {
                    gEtutList[idx] = netut
                    gunEtutList = gEtutList.toList()
                } catch (_: Exception) {
                }
            }
        }
        //println(etutListMap.toString())
    }

    private fun etutDeleteUpdate(old: EtutItem) {
        var nEtutList = allEtutList.filter { it.id != old.id }
        allEtutList = nEtutList.toList()
        //println(etutListMap.toString())
    }

    private fun validateEtutItem(etutItem: NewEtutItem): String? {
        console.log("add-etut:", etutItem.toString())
        if (etutItem.type == EtutType.KonuTest.type || etutItem.type == EtutType.Konu.type) {
            if (etutItem.ders.isEmpty()) return "Ders Seçiniz!"
            /*
            if (etutItem.type == EtutType.Konu.type
                && etutItem.link.isEmpty()) return "Video linkini ekleyiniz!"*/
        }
        return null
    }

    fun selectForCopy(etut: EtutItem) {
        if (etut.id == etutSelectForCopy?.id) {
            copiedEtut = null
            etutSelectForCopy = null
            copiedUserUuid = ""
        } else {
            copiedEtut = null
            etutSelectForCopy = etut
        }
    }


    suspend fun copyExistingEtut(tarih: String, uuid: String? = null) {
        copiedEtut?.let {
            try {
                if (it.tarih == tarih && copiedUserUuid == uuid) return
                val newEtutItem = it.copy(tarih = tarih, addBy = AppData.appUser.role)
                RestApi.copyExistingEtut(newEtutItem, uuid)?.let { res ->
                    Json.decodeFromString<AddEtutResponse>(res).let { eres ->
                        if (eres.data != null) {
                            val nlist = allEtutList.toMutableList()
                            nlist.add(eres.data)
                            allEtutList = nlist.toList()
                            handleEtutList()
                        }
                    }
                }
            } finally {
                etutSelectForCopy = null
                copiedEtut = null
                copiedUserUuid = ""

            }

        }
    }


    fun findEtutStartTime(date: String): String {
        etutListMap[date]?.let { etuts ->
            etuts.maxByOrNull { it.saat }?.let { last ->
                var addMin = 30
                if (last.soru > 0) {
                    addMin = (last.soru.toDouble() * 1.9).toInt()
                }
                return addMinutesToTime(last.saat, addMin)
            }
        }
        return "06:15"
    }

    private fun addMinutesToTime(timeStr: String, minutesToAdd: Int): String {
        val (hours, minutes) = timeStr.split(":").map { it.toInt() }
        var totalMinutes = (hours * 60) + minutes + minutesToAdd
        var newHours = totalMinutes / 60
        var newMinutes = totalMinutes % 60
        newMinutes = ((newMinutes + 9) / 10) * 10

        if (newMinutes == 60) {
            newMinutes = 0
            newHours += 1
        }
        newHours %= 24
        return "${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}"
    }
}