package com.kelimesoft.etutpro.models

import com.varabyte.kobweb.compose.ui.graphics.Colors
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DersKaynak(
    val id: Long = 0L,
    val sid: Long = 0L,
    val kid: Long = 0L,
    val did: Long = 0L,
    var ders: String,
    val isim: String,
    val sayfa: Int,
    val kapak: String = ""
)


@Serializable
enum class KaynakType(var text: String) {
    @SerialName("video")
    Video("Ders Videosu"),
    /*
    @SerialName("pdf")
    Pdf("Pdf, Belge"),*/
}

/*
data class DraggingType(
    var type: KaynakType,
    var ders: String
)*/


@Serializable
data class NewKaynakBinding(
    var sid: Long = AppData.appUser.id,
    val kaynak: DersKaynak,
    var uuid: String = AppData.appUser.uuid,
    val uuids: List<String> = listOf()
)

