package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.AddOgrenci
import com.kelimesoft.etutpro.components.AddSnfOgrenci
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.components.PageBox
import com.kelimesoft.etutpro.models.AlertModel
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.Screens
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.pages.ders.AddNewDers
import com.kelimesoft.etutpro.pages.sinif.*
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSSpinner
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.SpinnerVariant
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@Page("/snf")
@Composable
fun SinifPage() {
    IfUserExist(Screens.Snf) {
        SinifTakip()
    }
}

@Composable
fun SinifTakip() {
    val scope = rememberCoroutineScope()

    val snfVM = SinifVM()
    var showAddSnf by remember { mutableStateOf(false) }

    var showSnfInfo by remember { mutableStateOf(false) }

    var showAddOgrenci by remember { mutableStateOf(false) }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    LaunchedEffect(true) {
        snfVM.getSnfData()
    }

    PageBox {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .maxWidth(1920.px)
                .backgroundColor(AppColors.ViewWhite)
                .borderRadius(r = 12.px)
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SnfNavBar(snfVM)
            Row(modifier = Modifier.fillMaxSize()
                .overflow(Overflow.Hidden)) {
                SnfDataView(snfVM, onSnfAdd = {
                    showAddSnf = true
                }, showInfo = {
                    showSnfInfo = true
                }, addOgrenci = {snfId ->
                    showAddOgrenci = true
                })

                Box(modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden)) {
                    if (snfVM.aktifPage == SnfPage.Etut) {
                        SnfEtutMain(snfVM)
                    } else {
                        SnfOzet(snfVM)
                    }
                    if (snfVM.loading){
                        Box(modifier = Modifier.fillMaxSize()

                            .backgroundColor(Colors.Gray.opacity(0.1f))
                            .styleModifier {
                                property("backdrop-filter", "blur(1px)") // Apply CSS blur effect
                                property("filter", "blur(1px)") // Fallback for browsers that don't support backdrop-filter
                            },

                            contentAlignment = Alignment.Center) {
                            BSSpinner(variant = SpinnerVariant.Default)
                        }
                    }
                }

            }


        }

        if (showAddSnf) {
            DialogView {
                AddNewSnf(
                    snfVM,
                    onClose = {
                        showAddSnf = false
                    })
            }
        }

        if (showSnfInfo) {
            DialogView {
                SnfInfoDialog(
                    snfVM,
                    onClose = {
                        showSnfInfo = false
                    })
            }
        }

        if (showAddOgrenci) {
            DialogView {
                AddSnfOgrenci(
                    snfVM = snfVM,
                    onClose = {
                    showAddOgrenci = false
                }, onAdded = {
                    scope.launch {
                        showAddOgrenci = false
                        alertModel = AlertModel(
                            "Yeni Öğrenci Eklendi",
                            AlertIcon.Checkmark, AlertStyle.Success
                        )
                        delay(3000)
                        alertModel = null
                    }
                })
            }
        }




        if (alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style
            )
        }
    }

}










