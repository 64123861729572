package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.models.AppData.ViewModel.ogrenciVM
import com.kelimesoft.etutpro.pages.ozet.BugunDersler
import com.kelimesoft.etutpro.pages.ozet.BugunEtut
import com.kelimesoft.etutpro.pages.ozet.HedefEkle
import com.kelimesoft.etutpro.pages.ozet.PeriodOzet
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px


@Page("/ozet")
@Composable
fun OzetPage() {
    IfUserExist(Screens.Ozet) {
        OzetContent()
    }
}

@Composable
fun OzetContent() {
    val scope = rememberCoroutineScope()
    val ozetVM = AppData.ViewModel.ozetVM
    val dersVM = AppData.ViewModel.dersVM
    val etutVM = AppData.ViewModel.etutVM
    var selectedPeriod = HedefPeriod.Hafta

    var showAddOgrenci by remember { mutableStateOf(false) }

    var secilenHedef: Hedef? by remember { mutableStateOf(null) }
    var showAddHedef by remember { mutableStateOf(false) }
    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    LaunchedEffect(true, ogrenciVM.selectedOgrenci) {

        var uuid: String? = null
        if (AppData.appUser.role > 0) {
            ogrenciVM.getOgrenciList()
            ogrenciVM.selectedOgrenci?.let {
                uuid = it.uuid
            }
        }
        //dersVM.getDersList(uuid)
        //ozetVM.getHedefList(uuid)
        ozetVM.getFaaliyetOzet(uuid)
        etutVM.getGunEtutList(uuid)

    }

    PageBox(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(AppColors.Background)
            .minWidth(240.px)
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .maxWidth(1920.px)
                .backgroundColor(AppColors.ViewWhite)
                .borderRadius(r = 12.px)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MyNavBar(page = Screens.Ozet, onSelect = {
                /*
                scope.launch {
                    ozetVM.getFaaliyetOzet(ogrenciVM.selectedOgrenci!!.uuid)
                }*/
            }, addOgrenci = {
                showAddOgrenci = true
            }, onSnf = {})

            SimpleGrid(
                numColumns(base = 1, md = 3), modifier = Modifier.fillMaxWidth()
            ) {
                /*
                Hedeflerim {
                    if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci == null){
                        scope.launch {
                            alertModel = AlertModel("Öğrenci Seçiniz", AlertIcon.Warning, AlertStyle.Danger)
                            delay(3000)
                            alertModel = null
                        }

                    }else{
                        selectedPeriod = it
                        showAddHedef = true
                    }

                }*/
                //BugunDersler(dersVM.daysMapList)

                BugunEtut()
                PeriodOzet(
                    "Hafta Özeti",
                    ozetVM.haftaDersMap,
                    ozetVM.haftaSayfa,
                    ozetVM.haftaScore,
                    ozetVM.haftaKitapList,
                    ozetVM.haftaDeneme
                )



                    PeriodOzet(
                        "Aylık Özet",
                        ozetVM.ayDersMap,
                        ozetVM.aySayfa,
                        ozetVM.ayScore,
                        ozetVM.ayKitapList,
                        ozetVM.ayDeneme
                    )



                /*
                TamamlanmayanEtut()



                BugunEtut()

                 */

            }

        }


        if (AppData.appUser.role != EtutRole.Ogrenci.ordinal) {
            if (ogrenciVM.ogrenciListem.isEmpty()){
                Column(modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
                    NoContentYet("Ders ve Etüt takibi için öğrencinizi ekleyiniz!")
                    BSButton(
                        text = "Öğrenci Ekle",
                        variant = ButtonVariant.Success
                    ) {
                        showAddOgrenci = true
                    }
                }
            }else if (ogrenciVM.selectedOgrenci == null) {
                NoContentYet("Öğrenci Seçiniz!")
            }
        }



        if (showAddHedef) {
            DialogView {
                HedefEkle(selectedPeriod,
                    onClose = {
                        showAddHedef = false
                    }, onSave = { hedef ->
                        scope.launch {
                            var uuid: String? = null
                            if (AppData.appUser.role > 0) {
                                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                            }
                            val res = ozetVM.addNewHedef(hedef, uuid)
                            if (res.result) {
                                showAddHedef = false
                                alertModel = AlertModel(
                                    if (secilenHedef != null) "Hedefiniz güncellendi" else "Yeni hedef eklendi",
                                    AlertIcon.Checkmark, AlertStyle.Success
                                )
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    })
            }
        }

        if (showAddOgrenci) {
            DialogView {
                AddOgrenci (onClose = {
                    showAddOgrenci = false
                }, onAdded = {
                    scope.launch {
                        showAddOgrenci = false
                        alertModel = AlertModel("Yeni Öğrenci Eklendi",
                            AlertIcon.Checkmark, AlertStyle.Success)
                        delay(3000)
                        alertModel = null
                    }
                })
            }
        }

        if (alertModel != null) {
            BSAlert(
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style
            )
        }


    }
}


@Composable
fun TutorOzet() {
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    PageBox(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(AppColors.Background)
            .minWidth(240.px)
    ) {
        AppBackImage()
        Column(

            modifier = Modifier
                .fillMaxWidth(
                    if (breakpoint >= Breakpoint.MD)
                        84.percent else 96.percent
                )
                .padding(topBottom = 20.px, leftRight = 30.px)
                .maxWidth(600.px)
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .borderRadius(r = 10.px)
                .backgroundColor(Colors.White),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                "Merhaba ${AppData.appUser.uname},",
                modifier = Modifier
                    .margin(bottom = 10.px)
                    .fontSize(32.px)
                    .fontWeight(FontWeight.Medium)
                    .color(AppColors.TextColor)
            )
            SpanText(
                "Öğrencinizin Ders ve Etüt takibini EtütPro ile planlı ve kolayca yapabilirsiniz",
                modifier = Modifier.fontSize(24.px)
                    .margin(bottom = 20.px)
                    .fontWeight(FontWeight.Medium)
                    .color(AppColors.TextColor)
            )

            Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween) {
                BSButton(
                    modifier = Modifier.margin(right = 10.px),
                    text = "Etüt Takibi",
                    variant = ButtonVariant.Success,
                    size = ButtonSize.Large
                ) {
                    //context.router.navigateTo("/etut")
                    AppData.appRoutes.navigateTo(context, Screens.Etut)
                }

                BSButton(
                    text = "Ders Programı",
                    variant = ButtonVariant.Success,
                    size = ButtonSize.Large
                ) {
                    AppData.appRoutes.activeRoute = Screens.Ders
                }

            }

        }
    }
}