package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ders.AddNewDers
import com.kelimesoft.etutpro.pages.etut.*
import com.kelimesoft.etutpro.pages.kaynak.AddNewKaynak
import com.kelimesoft.etutpro.pages.kaynak.EtutKaynakList
import com.kelimesoft.etutpro.pages.sinif.SinifaKatilDialog
import com.kelimesoft.etutpro.styles.ChevronButtonStyle
import com.kelimesoft.etutpro.utils.dateToDbStr
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.components.BSIconButton
import com.stevdza.san.kotlinbs.icons.BSIcons
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaBook
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.*
import org.jetbrains.compose.web.css.px
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent
import kotlin.js.Date

@Page("/etut")
@Composable
fun EtutPage() {
    IfUserExist(Screens.Etut) {
        EtutList()
    }
}

@Composable
fun EtutList() {
    val etutVM = AppData.ViewModel.etutVM
    val dersVM = AppData.ViewModel.dersVM
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()


    var selectedEtutDate: String by remember { mutableStateOf("") }

    var showAddOgrenci by remember { mutableStateOf(false) }

    var showAddEtut by remember { mutableStateOf(false) }
    var showAddVideoEtut by remember { mutableStateOf(false) }
    var showAddDenemeSnv by remember { mutableStateOf(false) }
    var showAddKaynak by remember { mutableStateOf(false) }
    var showAddNewDers by remember { mutableStateOf(false) }
    var showSnfKatil by remember { mutableStateOf(false) }

    var showReplanButton by remember { mutableStateOf(false) }

    var etutType: EtutType? = null
    var secilenEtut: EtutItem? = null

    var alertModel: AlertModel? by remember { mutableStateOf(null) }
    var noContent by remember { mutableStateOf(false) }
    var draggedEtut: EtutItem? by remember { mutableStateOf(null) }
    var draggedKaynak: DersKaynak? by remember { mutableStateOf(null) }
    var dragKaynakType: KaynakType? by remember { mutableStateOf(null) }

    var showKaynakMenu by remember { mutableStateOf(true) }

    //var newEtutSaat by remember { mutableStateOf("") }

    val escListener = remember {
        EventListener {
            it as KeyboardEvent
            if (it.keyCode == 27) {
                if (etutVM.copiedEtut != null
                    || etutVM.etutSelectForCopy != null
                ) {
                    etutVM.copiedEtut = null
                    etutVM.etutSelectForCopy = null
                }
                etutVM.kaynakDersOver = ""
            }
        }
    }

    fun saveDrag(it: EtutItem, tarih: String, saat: String) {
        if (it.tarih == tarih && it.saat == saat) {
            draggedEtut = null
            return
        }
        /*
        var uuid: String? = null
        if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci != null) {
            uuid = ogrenciVM.selectedOgrenci!!.uuid
        }*/
        val newSaat = saat.ifEmpty { it.saat }
        scope.launch {
            val updatedEtutItem = it.copy(
                saat = newSaat,
                tarih = tarih,
                done = false
            )
            etutVM.editEtutWithDrag(updatedEtutItem)
        }
    }


    LaunchedEffect(etutVM.weekStartDate, ogrenciVM.selectedOgrenci) {
        showReplanButton = false
        var uuid: String? = null
        if (AppData.appUser.role > 0) {
            ogrenciVM.getOgrenciList()
            if (ogrenciVM.selectedOgrenci != null) {
                uuid = ogrenciVM.selectedOgrenci!!.uuid
            }
        }
        if (dersVM.dersList.isEmpty()) {
            dersVM.getDersList(uuid)
        }
        etutVM.getEtutList(uuid)
        if (etutVM.allEtutList.isEmpty()) {
            noContent = true
        } else {
            noContent = false
            val notDoneCount = etutVM.allEtutList.filter { !it.done }.size
            if (notDoneCount > 0) {
                val today = Date()
                if (etutVM.weekDays[6] == today.dateToDbStr() && today.getDay() == 0 && today.getHours() > 19) {
                    showReplanButton = true
                }
            }
        }
        document.addEventListener(type = "keydown", escListener)

        //console.log("hourofday:", Date().getHours())
    }

    DisposableEffect(true) {
        onDispose {
            document.removeEventListener("keydown", escListener)
        }
    }

    PageBox {
        //SetupDragAndDrop()
        Column(
            modifier = Modifier
                .fillMaxSize()
                .maxWidth(1920.px)
                .backgroundColor(AppColors.ViewWhite)
                .borderRadius(r = 12.px)
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MyNavBar(page = Screens.Etut, onSelect = {

            }, addOgrenci = {
                showAddOgrenci = true
            }, onSnf = {
                showSnfKatil = true
            })
            //Row(modifier = Modifier.fillMaxWidth()) {

            Row(
                modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden),
            ) {
                if (showKaynakMenu) {
                    EtutKaynakList(etutVM, null, onKaynak = {
                        showAddKaynak = true

                    }, onAddDers = {
                        showAddNewDers = true
                    }, onDrag = {
                        draggedEtut = null
                        draggedKaynak = it
                    }, onDragType = {
                        draggedEtut = null
                        dragKaynakType = it
                    })
                }
                Column(
                    modifier = Modifier.fillMaxSize()
                        //.weight(1f)
                        .thenIf(
                            etutVM.copiedEtut != null
                                    || etutVM.etutSelectForCopy != null
                        ) {
                            //eğer kopyalama için seçilen item varsa boş yere tıklayınca iptal edilsin
                            Modifier.onClick {
                                etutVM.copiedEtut = null
                                etutVM.etutSelectForCopy = null
                            }
                        }
                        .overflow(Overflow.Hidden),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {

                    Row(
                        modifier = Modifier.fillMaxWidth()
                            .margin(top = 1.px)
                            .backgroundColor(Colors.White.opacity(0.9f))
                            .borderRadius(r = 4.px)
                            .padding(2.px)
                        ,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        BSButton(
                            text = if (breakpoint > Breakpoint.SM) "Önceki" else "",
                            size = ButtonSize.Small,
                            icon = BSIcons.CHEVRON_LEFT,
                            variant = ButtonVariant.PrimaryOutline
                        ){
                            scope.launch {
                                etutVM.gotoPreviousWeek()
                            }
                        }

                        Spacer()
                        EtutSearch()
                        Spacer()

                        if (showReplanButton) {
                            Box(modifier = Modifier.padding(leftRight = 6.px)) {
                                BSButton(
                                    modifier = Modifier
                                        .fontSize(12.px)
                                        .color(Colors.White)
                                        .maxWidth(170.px),
                                    text = "Kalanları Haftaya Planla",
                                    size = ButtonSize.Small,
                                    variant = ButtonVariant.Danger
                                ) {
                                    scope.launch {
                                        val res = etutVM.replanUnfinished()
                                        res?.let {
                                            alertModel = AlertModel(it, AlertIcon.Warning, AlertStyle.Warning)
                                            delay(3000)
                                            alertModel = null
                                        }
                                    }
                                }
                            }

                        }

                        BSButton(
                            text = if (breakpoint > Breakpoint.SM) "Sonraki" else "",
                            size = ButtonSize.Small,
                            icon = BSIcons.CHEVRON_RIGHT,
                            variant = ButtonVariant.PrimaryOutline
                        ){
                            scope.launch {
                                etutVM.gotoNextWeek()
                            }
                        }

                    }

                    var scrollTimeoutJob: Job? by remember { mutableStateOf(null) }

                    // Reference to the scrollable column element
                    val columnElementRef = remember { mutableStateOf<Element?>(null) }


                    Column(modifier = Modifier.fillMaxSize()
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                        .scrollBehavior(ScrollBehavior.Smooth)
                        .styleModifier {
                            property("scrollbar-width", "thin")
                        }
                        .attrsModifier {
                                ref {
                                    columnElementRef.value = it
                                    onDispose { columnElementRef.value = null }
                                }

                        }
                    ) {
                        SimpleGrid(
                            modifier = Modifier.fillMaxWidth(),

                            numColumns = numColumns(base = 1, sm = 2, md = 3, lg = 4, xl = 5)
                        ) {
                            etutVM.weekDays.forEachIndexed { index, tarih ->
                                EtutDayBox(etutVM, scope, index, tarih,
                                    onAdd = { typ ->
                                        etutType = typ
                                        secilenEtut = null
                                        draggedKaynak = null
                                        selectedEtutDate = tarih
                                        if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci == null) {
                                            scope.launch {
                                                alertModel =
                                                    AlertModel("Öğreci Seçiniz!", AlertIcon.Warning, AlertStyle.Warning)
                                                delay(3000)
                                                alertModel = null
                                            }

                                        } else {
                                            if (typ == EtutType.Video) {
                                                showAddVideoEtut = true
                                            }else if(typ == EtutType.Deneme){
                                                showAddDenemeSnv = true
                                            } else {
                                                showAddEtut = true
                                            }

                                        }

                                    }, onEdit = { etut ->
                                        etutType = null
                                        selectedEtutDate = etut.tarih
                                        secilenEtut = etut
                                        draggedKaynak = null
                                        if (etut.type == EtutType.Video.type) {
                                            showAddVideoEtut = true
                                        }else if (etut.type == EtutType.Deneme.type) {
                                                showAddDenemeSnv = true
                                        } else {
                                            showAddEtut = true
                                        }

                                    }, onDrag = {
                                        draggedKaynak = null
                                        draggedEtut = it
                                    }, onDrop = {
                                        if (draggedEtut != null) {
                                            //newEtutSaat = newSaat
                                            saveDrag(draggedEtut!!, tarih, etutVM.etutDragSaat)
                                            draggedEtut = null
                                        } else if (draggedKaynak != null) {
                                            etutType = null
                                            secilenEtut = null
                                            selectedEtutDate = tarih
                                            //newEtutSaat = newSaat
                                            showAddEtut = true
                                        } else if (dragKaynakType != null) {
                                            secilenEtut = null
                                            selectedEtutDate = tarih
                                            showAddVideoEtut = true
                                        }
                                    })
                            }
                        }


                        //Detect if isscrolling
                        DisposableEffect(columnElementRef.value) {
                            val columnElement = columnElementRef.value
                            if (columnElement != null) {
                                // Scroll event listener to detect when scrolling starts
                                val scrollListener = EventListener {
                                    // Cancel any existing timeout job
                                    scrollTimeoutJob?.cancel()

                                    // Set isScrolling to true when scrolling starts
                                    if (!etutVM.etutScrolling) {
                                        etutVM.etutScrolling = true
                                    }
                                    // Create a new job to reset isScrolling to false after scrolling stops
                                    scrollTimeoutJob = CoroutineScope(Dispatchers.Default).launch {
                                        delay(150) // Adjust debounce time as needed
                                        etutVM.etutScrolling = false
                                    }
                                }

                                // Attach the scroll listener to the column
                                columnElement.addEventListener("scroll", scrollListener)

                                // Cleanup the scroll listener when the composable is disposed
                                onDispose {
                                    columnElement.removeEventListener("scroll", scrollListener)
                                    scrollTimeoutJob?.cancel() // Cancel any pending jobs
                                }
                            }
                            onDispose { }
                        }



                    }

                }
            }

        }

        if (noContent) {
            if (AppData.appUser.role == EtutRole.Ogrenci.ordinal) {
                NoContentYet("Henüz etüt planı eklemedin, Etüt ve Ödevler  eklemek Gün ismindeki + butonuna tıklayabilirsin.")
            } else {
                if (ogrenciVM.ogrenciListem.isEmpty()) {
                    Column(modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
                        NoContentYet("Ders ve Etüt takibi için öğrencinizi ekleyiniz!")
                        BSButton(
                            text = "Öğrenci Ekle",
                            variant = ButtonVariant.Success
                        ) {
                            showAddOgrenci = true
                        }
                    }
                } else if (ogrenciVM.selectedOgrenci != null) {
                    NoContentYet("Seçilen hafta için henüz etüt planı mevcut değil, Etüt ve Ödevler  eklemek Gün ismindeki + butonuna tıklayınız.")
                } else {
                    NoContentYet("Öğrenci Seçiniz!")
                }

            }
        }

        if (showAddEtut) {
            DialogView {
                AddNewEtut(
                    typ = etutType,
                    etut = secilenEtut,
                    kaynak = draggedKaynak,
                    date = selectedEtutDate,
                    saat = etutVM.etutDragSaat,
                    onKaynak = {
                        showAddKaynak = true
                    },
                    onClose = {
                        showAddEtut = false
                    },
                    onEdit = { etutItem, docs ->
                        scope.launch {
                            var docList = mutableListOf<String>()
                            docs.sortedBy { it.order }.forEach {
                                docList.add(it.name)
                            }
                            val res = etutVM.editExistingEtut(etutItem, docList)
                            if (res.result) {
                                showAddEtut = false
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }, onAdd = { newEtutItem, docs, uuids ->
                        var uuid: String? = null
                        if (AppData.appUser.role > 0) {
                            ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                        }
                        val docList = mutableListOf<String>()
                        docs.sortedBy { it.order }.forEach {
                            docList.add(it.name)
                        }
                        scope.launch {
                            val res = etutVM.addNewEtut(newEtutItem, uuid, docList, uuids)
                            if (res.result) {
                                showAddEtut = false
                                alertModel = AlertModel("Yeni etüt eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    })
            }
        }

        if (showAddVideoEtut) {
            DialogView {
                AddVideoEtut(
                    etut = secilenEtut,
                    date = selectedEtutDate,
                    saat = etutVM.etutDragSaat,
                    onClose = {
                        showAddVideoEtut = false
                    }, onEdit = { etut ->
                        scope.launch {
                            val res = etutVM.editExistingEtut(etut, listOf())
                            if (res.result) {
                                showAddVideoEtut = false
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }, onAdd = {netut, uids ->
                        var uuid: String? = null
                        if (AppData.appUser.role > 0) {
                            ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                        }
                        scope.launch {
                            val res = etutVM.addNewEtut(netut, uuid, listOf(), uids)
                            if (res.result) {
                                showAddVideoEtut = false
                                alertModel = AlertModel("Yeni Video eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }
                )


            }
        }

        if (showAddDenemeSnv) {
            DialogView {
                AddDenemeSnv(
                    etut = secilenEtut,
                    date = selectedEtutDate,
                    saat = etutVM.etutDragSaat,
                    onClose = {
                        showAddDenemeSnv = false
                    }, onEdit = { etut ->
                        scope.launch {
                            val res = etutVM.editExistingEtut(etut, listOf())
                            if (res.result) {
                                showAddDenemeSnv = false
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }, onAdd = {netut, uids ->
                        var uuid: String? = null
                        if (AppData.appUser.role > 0) {
                            ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                        }
                        scope.launch {
                            val res = etutVM.addNewEtut(netut, uuid, listOf(), uids)
                            if (res.result) {
                                showAddDenemeSnv = false
                                alertModel = AlertModel("Yeni Deneme eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }
                )


            }
        }


        if (showAddNewDers) {
            DialogView {
                AddNewDers(null,
                    onClose = {
                        showAddNewDers = false
                    }, onSave = { dersItem ->
                        scope.launch {
                            val res = dersVM.addNewDers(dersItem, ogrenciVM.selectedOgrenci?.uuid)
                            if (res.result) {
                                showAddNewDers = false
                                val kaynakVm = AppData.ViewModel.kaynakVM
                                kaynakVm.allKaynakList = listOf()
                                kaynakVm.getKaynakList()
                            }
                        }
                    }, onDelete = { dersItem ->

                    })
            }
        }

        if (showAddKaynak) {
            DialogView {
                AddNewKaynak(
                    onClose = {
                        showAddKaynak = false
                    }, onAdded = {
                        showAddKaynak = false
                    })
            }
        }
        if (showAddOgrenci) {
            DialogView {
                AddOgrenci(onClose = {
                    showAddOgrenci = false
                }, onAdded = {
                    scope.launch {
                        showAddOgrenci = false
                        alertModel = AlertModel(
                            "Yeni Öğrenci Eklendi",
                            AlertIcon.Checkmark, AlertStyle.Success
                        )
                        delay(3000)
                        alertModel = null
                    }
                })
            }
        }

        if (showSnfKatil) {
            SinifaKatilDialog(onClose = {
                showSnfKatil = false
            }, onDone = {
                showSnfKatil = false
            })
        }

        if (alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style
            )
        }
    }

}










