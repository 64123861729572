package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.DersKaynak
import com.kelimesoft.etutpro.models.EtutItem
import com.kelimesoft.etutpro.models.EtutType
import com.kelimesoft.etutpro.models.FuncResult
import com.kelimesoft.etutpro.network.BoolResponse
import com.kelimesoft.etutpro.network.ImageUploadResponse
import com.kelimesoft.etutpro.network.KaynakListResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.pages.etut.DocEtut
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.utils.dateToDbStr
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.Image
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.js.Date

class KaynakVM: BaseVM() {

    //var kaynakList by mutableStateOf(listOf<DersKaynak>())
    var allKaynakList: List<DersKaynak> = listOf()
    var kaynakMap by mutableStateOf(mapOf<String, List<DersKaynak>>())

    var secilenKaynakDersAdi: String? = null
    var secilenDersKaynak: DersKaynak? = null

    suspend fun getKaynakList(uuid: String? = null) {
        if (allKaynakList.isEmpty()){
            val json = Json {
                ignoreUnknownKeys = true
            }
            RestApi.getKaynakList(uuid)?.let { res ->
                //console.log("kaynak-list:", res)
                json.decodeFromString<KaynakListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        allKaynakList = eres.data
                        prepareKaynakMap()
                    }
                }
            }
        }
    }

    fun dersKaynak(ders: String): List<DersKaynak>{
        return allKaynakList.filter { it.ders == ders  }
    }

    suspend fun addNewKaynak(kaynak: DersKaynak, uuid: String? = null, uuids: List<String>): FuncResult {
        if (!Funcs.checkForm("kaynak-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        RestApi.addNewKaynak(kaynak, uuid, uuids)?.let { res ->
            Json.decodeFromString<KaynakListResponse>(res).let { kres ->
                if (kres.data != null) {
                    allKaynakList = kres.data
                    prepareKaynakMap()
                    return FuncResult(true)
                } else if (kres.error != null) {
                    return FuncResult(false, kres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    private fun prepareKaynakMap(){
        kaynakMap = allKaynakList.groupBy { it.ders }
    }


    suspend fun deleteSelectKaynak() {
        if (secilenDersKaynak != null){
            RestApi.deleteSelectedKaynak(secilenDersKaynak!!)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { eres ->
                    if (eres.data == true) {
                        allKaynakList = listOf()
                        kaynakMap = mapOf()
                    }
                }
            }
        }

    }


    fun selectDersAdi(ders: String){
        secilenKaynakDersAdi = ders
        secilenDersKaynak = null
    }

    fun selectKaynak(kaynak: DersKaynak){
        secilenDersKaynak = kaynak
        secilenKaynakDersAdi = null
    }





}