package com.kelimesoft.etutpro.models

import com.kelimesoft.etutpro.utils.dateToDbStr
import kotlinx.serialization.Serializable
import kotlin.js.Date

@Serializable
data class AppUser(
    val id: Long = 0,
    val uuid: String = "",
    val uname: String = "",
    val adsoyad: String = "",
    val role: Int = EtutRole.Ogrenci.ordinal,
    val snf: Int = 0,
    val expire: String = Date().dateToDbStr(),
    val vcode: String = "",
    val scode: String = "",
    var snfid: Long = 0L
)
