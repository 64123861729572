package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.register.RegisterNewUSer
import com.kelimesoft.etutpro.viewmodels.LoginVM
import com.stevdza.san.kotlinbs.components.*
import com.stevdza.san.kotlinbs.forms.BSCheckbox
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.TooltipDirection
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Page("/giris")
@Composable
fun Giris() {
    val context = rememberPageContext()
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var showPass by remember { mutableStateOf(false) }
    var forgotPassDialog by remember { mutableStateOf(false) }
    var showRegister by remember { mutableStateOf(false) }
    var alertModel: AlertModel? by remember { mutableStateOf(null) }
    LaunchedEffect(Unit) {
        initializeTooltips()
    }
    val loginVM = LoginVM()

    PageBox(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(AppColors.Background)
            .minWidth(240.px)

    ) {
        AppBackImage()

        Column(modifier = Modifier
            .fontSize(FontSize.Small)
            .color(AppColors.ViewWhite)
            .fillMaxSize(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
            ) {
            Column(modifier = Modifier
                .padding(8.px)
                .backgroundColor(Colors.Black.opacity(0.6f))
                .borderRadius(r = 8.px)
                .margin(topBottom = 8.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Span {
                    Text("Ders planlama ve takip yazılımı")
                }
                Span {
                    Text("Başta Öğrenciler, Veliler, Öğretmenler ve Eğitim Koçları için Ders Planlama ve Takip yazılımı: EtutPro")
                }
            }

        }


        Form(
            action = "",
            attrs = Modifier
                .id("login-form")
                .fillMaxWidth(
                    if (breakpoint >= Breakpoint.MD)
                        84.percent else 96.percent
                )
                .padding(topBottom = 20.px, leftRight = 30.px)
                .maxWidth(600.px)
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .borderRadius(r = 8.px)
                .backgroundColor(Colors.White)
                .toAttrs()
        ) {
            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                Image(
                    modifier = Modifier
                        .borderRadius(r = 8.px)
                        .size(76.px),
                    src = Images.AppIcon,
                    alt = "ögrenci ve veli etut takip sistemi ile lgs de başarıyı yakalayın, planlanmayan konu ve soru kalmasın"

                )
            }

            P(
                attrs = Modifier
                    .margin(top = 1.px, bottom = 20.px)
                    .textAlign(TextAlign.Center)
                    .fontSize(18.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("EtutPro Giriş")
            }

            Box(modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.CenterEnd){
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 10.px),
                    placeholder = "Kullanıcı adı veya E-posta adresi",
                    type = InputType.Text,
                    value = loginVM.userName,
                    onValueChange = {
                        loginVM.userName = it
                    },
                    required = true
                )

                BSTooltip("Adınızı boşluk bırakmadan giriniz", direction = TooltipDirection.Top){
                    FaInfoCircle(size = IconSize.LG,
                        modifier = Modifier
                            .color(AppColors.TextColor.opacity(0.7f))
                            .margin(6.px)
                            .padding(right = 4.px, bottom = 4.px))
                }

            }




            BSInput(
                modifier = Modifier.margin(bottom = 10.px),
                placeholder = "Şifre",
                type = if (showPass) InputType.Text else InputType.Password,
                value = loginVM.userPass,
                onValueChange = {
                    loginVM.userPass = it
                },
                required = true
            )

            BSCheckbox(
                modifier = Modifier
                    .color(AppColors.TextColor),
                defaultChecked = showPass,
                label = "Şifreyi göster",
                onClick = {
                    showPass = it
                }
            )

            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(0.px),
                verticalAlignment = Alignment.CenterVertically
            ) {

                Spacer()

                BSButton(
                    text = "Giriş",
                    variant = ButtonVariant.Success,
                    onClick = {
                        scope.launch {
                            val  res = loginVM.loginUser()
                            if (res.result) {
                                AppData.appRoutes.navigateTo(context,Screens.Home)
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }
                )

            }

            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                BSButton(
                    modifier = Modifier.color(AppColors.Red.opacity(0.9f)),
                    text = "Şifremi Unuttum",
                    variant = ButtonVariant.Light,
                    onClick = {
                        scope.launch {
                            forgotPassDialog = true
                        }
                    }
                )
                BSButton(
                    text = "Yeni Kayıt",
                    variant = ButtonVariant.PrimaryOutline,
                    onClick = {
                        showRegister = true
                    }
                )
            }
        }

        if (showRegister) {
            DialogView {
                RegisterNewUSer(onClose = {
                    showRegister = false
                }, onRegister = { user, pass2 ->
                    scope.launch {
                        val res = loginVM.registerNewUser(user, pass2)
                        if (res.result) {
                            showRegister = false
                            alertModel = AlertModel("Başarıyla kayıt oldunuz", AlertIcon.Checkmark, AlertStyle.Success)
                            delay(2000)
                            alertModel = null
                        } else {
                            alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                            delay(2000)
                            alertModel = null
                        }
                    }

                })
            }

        }

        if (alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style
            )
        }


        /*
        if (forgotPassDialog) {
            DialogView {
                ForgetPass(loginVM, scope, onClose =  {
                    forgotPassDialog = false
                }, onSuccess = {
                    forgotPassDialog = false
                    boxAlert = AlertInfo("login_passsent".tr(), "login_senttext".tr(), "",
                        "shared_tmm".tr(), onClose = {
                            boxAlert = null
                        }){
                        boxAlert = null
                    }
                })
            }

        }

        if (loginVM.errorText.isNotEmpty()) {
            ToastView(loginVM.errorText, pos = ToastPos.Bottom, color = Colors.Red) {
                loginVM.errorText = ""
            }
        }
        if (boxAlert != null ){
            DialogView {
                AlertBox(boxAlert!!)
            }

        }

         */

    }

}

/*

@Composable
fun ForgetPass(loginVM: LoginVM, scope: CoroutineScope,
               onClose: () -> Unit,
               onSuccess: () -> Unit) {

    LaunchedEffect(true) {
        loginVM.getForgotCaptcha()
    }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("login_forgot".tr(), onClose = onClose)


        Form(
            attrs = Modifier
                .id("forgot-form")
                .fillMaxWidth()
                .padding(leftRight = 20.px, topBottom = 40.px)
                .toAttrs()
        ) {
            Input(
                type = InputType.Email,
                attrs = InputStyles.toModifier()
                    .attrsModifier {
                        attr("name", "user")
                        attr("placeholder", "login_eposta".tr())
                        attr("minlength", "6")
                        attr("required", "true")
                    }
                    .toAttrs()
                    {
                        onInput {
                            loginVM.userNameForgot = it.value
                        }
                    }
            )

            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (loginVM.forgetCaptcha.isNotEmpty()) {
                    Span(
                        attrs = CaptchaStyle.toModifier()
                            .margin(left = 8.px, top = 6.px, right = 4.px)
                            .toAttrs()
                    ) {
                        Text(loginVM.forgetCaptcha)
                    }
                    FaArrowRotateRight(
                        modifier = com.varabyte.kobweb.compose.ui.Modifier.color(AppColors.NumBlue)
                            .onClick {
                                loginVM.getForgotCaptcha()
                            },
                        size = IconSize.SM
                    )

                    Input(
                        type = InputType.Text,
                        attrs = InputCaptchaStyle.toModifier()
                            .attrsModifier {
                                attr("name", "captcha")
                                attr("placeholder", "login_captext".tr())
                                attr("required", "true")
                            }
                            .toAttrs()
                            {
                                onInput {
                                    loginVM.userForgotCaptcha = it.value
                                }
                            }
                    )
                }
                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        .onClick {
                            it.preventDefault()
                            scope.launch {
                                val result = loginVM.requestPass()
                                if(result){
                                    onSuccess()
                                }

                            }
                        }
                        .toAttrs()

                ) {
                    Text("login_iste".tr())
                }
            }
        }


    }

}

 */


