package com.kelimesoft.etutpro.pages.ders

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.DersItem
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form


@Composable
fun AddNewDers(
    ders: DersItem? = null,
    onClose: () -> Unit,
    onSave: (DersItem) -> Unit,
    onDelete: (DersItem) -> Unit
) {
    var dersAd by remember { mutableStateOf("") }
    var renk by remember { mutableStateOf(0) }

    LaunchedEffect(true){
        if (ders != null){
            dersAd = ders.dersad
            renk = ders.renk
        }
    }

    fun saveDers(){
        val dersItem = DersItem(ders?.id ?: 0, ders?.sid ?: AppData.appUser.id,
            ders?.oid ?: 0, dersad = dersAd, renk = renk)
        onSave(dersItem)
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("Yeni Ders", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("ders-form")
                .toAttrs()
        ) {
            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = dersAd,
                placeholder = "Ders Adı",
                required = true,
                disabled = ders != null && ders.sid != AppData.appUser.id,
                onValueChange = {
                    dersAd = it
                }
            )

            Row {
                Spacer()
                SpanText("Günlük Ders Sayısı",
                    modifier = Modifier.fontSize(12.px).color(AppColors.TextColor)
                        .textAlign(TextAlign.Center)
                        .fillMaxWidth(50.percent))
            }

            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center) {

                SpanText("Renk Seçiniz:",
                    modifier = Modifier.padding(2.px)
                        .color(AppColors.TextColor)
                        .fontSize(12.px)
                )

                Spacer()

                AppColors.colorDersList.forEachIndexed { index, clr ->
                    CircleBox(
                        modifier = Modifier
                            .onClick {
                                renk = index
                            }
                            .margin(4.px)
                            .size(36.px)
                            .backgroundColor(clr)
                    ) {
                        if (renk == index) {
                            FaCheck(
                                modifier = Modifier.color(Colors.White),
                                size = IconSize.XL
                            )
                        }
                    }

                }
            }
            VDivider()

            Row(modifier = Modifier.margin(top = 6.px),) {
                if (ders != null && ders.sid == AppData.appUser.id){
                    BSButton(
                        text = "Dersi Sil",
                        variant = ButtonVariant.DangerOutline,
                        onClick = {
                            onDelete(ders)
                        }, modifier = Modifier.margin(2.px)
                    )
                }
                Spacer()
                BSButton(
                    text = "Kapat",
                    variant = ButtonVariant.WarningOutline,
                    onClick = {
                        onClose()
                    }, modifier = Modifier.margin(2.px)
                )
                BSButton(
                    text = "Kaydet",
                    variant = ButtonVariant.Success,
                    onClick = {
                        saveDers()
                    }, modifier = Modifier.margin(2.px)
                )
            }
        }
    }
}


@Composable
fun DersGunuSaati(gunAd: String, day: Int,
                  disabled: Boolean = false,
                  onChange: (Int) ->Unit) {
    Row(modifier = Modifier.fillMaxWidth()
        .padding(leftRight = 5.px),
        verticalAlignment = Alignment.CenterVertically){
        SpanText(gunAd, modifier = Modifier.fillMaxWidth().color(AppColors.TextColor))

        BSNumberInput(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 4.px),
            type = InputType.Number,
            value = day.toString(),
            disabled = disabled,
            placeholder = "Ders Saati",
            onValueChange = {
                onChange(it)
            }
        )
    }
}

/*
@Composable
private fun DersRow(ders: DersItem, onEdit: () -> Unit) {
    Column(modifier = Modifier.fillMaxWidth()
        , verticalArrangement = Arrangement.Top
        , horizontalAlignment = Alignment.CenterHorizontally) {
        Row(modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically) {
            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically){
                SpanText(
                    ders.dersad,
                    modifier = Modifier.fillMaxWidth()
                        .weight(1.8f)
                        .color(AppColors.TextColor)
                        .fontSize(17.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(4.px)
                )

                RowButton {
                    FaEdit(
                        modifier = Modifier.color(AppColors.Gray.opacity(0.9f))
                            .onClick {
                                onEdit()
                            },
                        size = IconSize.SM
                    )
                }
            }

            SpanText(
                if (ders.day1 == 0) " " else ders.day1.toString(),
                modifier = Modifier.fillMaxWidth()
                    .weight(1f)
                    .textAlign(TextAlign.Center)
                    .fontSize(16.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(4.px)
                    .backgroundColor(AppColors.Gray.opacity(0.5f))
            )
            SpanText(
                if (ders.day2 == 0) " " else ders.day2.toString(),
                modifier = Modifier.fillMaxWidth()
                    .weight(1f)
                    .textAlign(TextAlign.Center)
                    .fontSize(16.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(4.px)
                    .backgroundColor(AppColors.Gray.opacity(0.3f))
            )
            SpanText(
                if (ders.day3 == 0) " " else ders.day3.toString(),
                modifier = Modifier.fillMaxWidth()
                    .weight(1f)
                    .textAlign(TextAlign.Center)
                    .fontSize(16.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(4.px)
                    .backgroundColor(AppColors.Gray.opacity(0.5f))
            )
            SpanText(
                if (ders.day4 == 0) " " else ders.day4.toString(),
                modifier = Modifier.fillMaxWidth()
                    .weight(1f)
                    .textAlign(TextAlign.Center)
                    .fontSize(16.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(4.px)
                    .backgroundColor(AppColors.Gray.opacity(0.3f))
            )
            SpanText(
                if (ders.day5 == 0) " " else ders.day5.toString(),
                modifier = Modifier.fillMaxWidth()
                    .weight(1f)
                    .textAlign(TextAlign.Center)
                    .fontSize(16.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(4.px)
                    .backgroundColor(AppColors.Gray.opacity(0.5f))
            )

        }
        Box(modifier = Modifier.fillMaxWidth().height(1.px).backgroundColor(Colors.Gray.opacity(0.4f)))
    }

}

 */