package com.kelimesoft.etutpro.network

import com.kelimesoft.etutpro.models.*

import kotlinx.serialization.Serializable

@Serializable
data class ApiResponse(
    val data: String? = null,
    val error: String? = null
)

@Serializable
data class BoolResponse(
    val data: Boolean?,
    val error: String? = null
)

@Serializable
data class DoubleResponse(
    val data: Double?,
    val error: String? = null
)

@Serializable
data class LongResponse(
    val data: Long?,
    val error: String? = null
)

@Serializable
data class DersListResponse(
    val data: ArrayList<DersItem>?,
    val error: String? = null
)

@Serializable
data class DersProgResponse(
    val data: ArrayList<DersProgItem>?,
    val error: String? = null
)

@Serializable
data class EtutListResponse(
    val data: List<EtutItem>?,
    val error: String? = null
)

@Serializable
data class AddEtutResponse(
    val data: EtutItem?,
    val error: String? = null
)


@Serializable
data class KaynakListResponse(
    val data: List<DersKaynak>?,
    val error: String? = null
)

@Serializable
data class SnfKaynakListResponse(
    val data: List<SnfKaynak>?,
    val error: String? = null
)

@Serializable
data class SnfEtutListResponse(
    val data: List<SnfEtutItem>?,
    val error: String? = null
)
@Serializable
data class AddSnfEtutResponse(
    val data: SnfEtutItem?,
    val error: String? = null
)



@Serializable
data class OgrenciListResponse(
    val data: List<OgrenciInfo>?,
    val error: String? = null
)

@Serializable
data class LoginResponse(
    val data: AppUser? = null,
    val error: String? = null
)

@Serializable
data class HedefListResponse(
    val data: ArrayList<Hedef>?,
    val error: String? = null
)

@Serializable
data class FaaliyetOzetResponse(
    val data: FaaliyetOzet?,
    val error: String? = null
)

@Serializable
data class ImageUploadResponse(
    val data: String?,
    val error: String? = null
)


@Serializable
data class SnfListResponse(
    val data: List<SnfUserData>?,
    val error: String? = null
)

@Serializable
data class TekSnfResponse(
    val data: SnfUserData?,
    val error: String? = null
)

@Serializable
data class SnfAddResponse(
    val data: SnfInfo?,
    val error: String? = null
)

@Serializable
data class SnfReportResponse(
    val data: SnfReportsData?,
    val error: String? = null
)

@Serializable
data class EtutReportResponse(
    val data: List<SnfEtutReport>?,
    val error: String? = null
)

/*

@Serializable
data class UserResponse(
    val data: UserProfile?,
    val error: String? = null
)

@Serializable
data class SummaryResponse(
    val data: SummaryData?,
    val error: String? = null
)

@Serializable
data class CowListResponse(
    val data: CowListData?,
    val error: String? = null
)

@Serializable
data class ErrorListResponse(
    val data: ArrayList<ErrorCow>?,
    val error: String? = null
)

@Serializable
data class HerdListResponse(
    val data: HerdList?,
    val error: String? = null
)

@Serializable
data class HerdEditResponse(
    val data: String?,
    val error: String? = null
)

@Serializable
data class HerdList(
    var herdlist: ArrayList<HerdGrup>?
)

@Serializable
data class SingleCowResponse(
    val data: ListCow?,
    val error: String? = null
)

@Serializable
data class CowListData(
    var cowlist: ArrayList<ListCow>?,
    var count: Int = 0
)

@Serializable
data class CowDetailsResponse(
    val data: CowDetails?,
    val error: String? = null
)

@Serializable
data class ShowCowResponse(
    val data: ShorCowDetails?,
    val error: String? = null
)

@Serializable
data class CowNotifResponse(
    val data: List<CowNotif>?,
    val error: String? = null
)

@Serializable
data class CowMilkResponse(
    val data: List<CowMilk>?,
    val error: String? = null
)

@Serializable
data class CowCalvesResponse(
    val data: List<CowCalf>?,
    val error: String? = null
)

@Serializable
data class NotifListResponse(
    val data: ArrayList<Notif>?,
    val error: String? = null
)

@Serializable
data class FinListResponse(
    val data: List<FinItem>?,
    val error: String? = null
)

@Serializable
data class DairyMilkResponse(
    val data: DairyMilk?,
    val error: String? = null
)


@Serializable
data class CaptchaData(
    val id: String,
    val text: String,
)
 */