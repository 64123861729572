package com.kelimesoft.etutpro.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.Date

enum class EtutType(val type: Int, val text: String, val icon: String){
    KonuTest(0, "Konu Testi / Etkinlik", "file-signature"),
    Konu(1, "Konu Anlatımı", "file-contract"),
    Deneme(2, "Genel Test / Deneme", "clock"),
    Video(5, "Ders Videosu", "youtube"),
    Kitap(3, "Kitap Oku", "book"),
    Kuran(4, "Kur'an Oku", "book-kuran")
}


@Serializable
data class NewEtutItem(
    var type: Int,
    var did: Long = 0,
    var ders: String = "",
    var konu: String,
    var notlar: String = "",
    var kid: Long = 0,
    var kaynak: String = "",
    var sayfa: String = "",
    var soru: Int = 0,
    var cevap: String? = null,
    var puan: Int = 0,
    var link: String = "",
    var tarih: String,
    var saat: String
)


@Serializable
data class EtutItem(
    val id: Long = 0L,
    val sid: Long = 0L,
    val eid: Long = 0L,
    var type: Int,
    var did: Long = 0,
    var ders: String,
    var konu: String,
    var notlar: String = "",
    var kid: Long = 0,
    var kaynak: String,
    var sayfa: String = "",
    var soru: Int = 0,
    var cevap: String? = null,
    var dogru: Int = 0,
    var yanlis: Int = 0,
    var net: Double = 0.0,
    var puan: Int = 0,
    var link: String = "",
    var belge: String = "",
    var snfid: Long = 0,
    @SerialName("by")
    var addBy: Int,
    var tarih: String,
    var saat: String,
    var usercevap: String? = null,
    var done: Boolean = false
)

fun EtutItem.toNewEtutItem(): NewEtutItem {
    return NewEtutItem(
        type = this.type,
        did = this.did,
        ders = this.ders,
        konu = this.konu,
        notlar = this.notlar,
        kid = this.kid,
        kaynak = this.kaynak,
        sayfa = this.sayfa,
        soru = this.soru,
        cevap = this.cevap,
        puan = this.puan,
        link = this.link,
        tarih = this.tarih,
        saat = this.saat
    )
}





@Serializable
data class NewEtutBinding(
    var sid: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid,
    val etut: NewEtutItem,
    val docs: List<String> = listOf(),
    val uuids: List<String> = listOf()
)

@Serializable
data class EditEtutBinding(
    var sid: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid,
    val etut: EtutItem,
    val docs: List<String> = listOf(),
)


@Serializable
data class EditWithDragBinding(
    var id: Long,
    var tarih: String,
    val saat: String
)

@Serializable
data class EtutDeleteBinding(
    var id: Long,
    var eid: Long
)


