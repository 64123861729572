package com.kelimesoft.etutpro.network

import com.kelimesoft.etutpro.models.*
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.json


object RestApi {
    //const val WebRoot = "http://192.168.5.8:9020/" //local testlerde bu
    //const val WebRoot = "http://localhost:9020/" //local testlerde bu
    //const val WebRoot = "https://etutpro.com/" //local testlerde bu
    const val WebRoot = "/" //web sitesinde bu
    const val ApiRoot = WebRoot + "api1/"

    const val connectErr = "{\"data\":null, error=\"Connection Error\"}"


    val jsonX = Json { encodeDefaults = true }

    suspend fun userLogin(user: String, pass: String): String? {
        val request = LoginRequest(user, pass)
        return postJson("${ApiRoot}login", jsonX.encodeToString(request))
    }

    suspend fun getAppUser(): String? {
        return postJson("${ApiRoot}getuser", jsonX.encodeToString(BasicRequest()))
    }
    suspend fun registerUSer(user: NewUser): String? {
        console.log("reg-user:", user.toString())
        return postJson("${ApiRoot}register", jsonX.encodeToString(user))
    }

    suspend fun forgotPass(formData: FormData): String? {
        return postForm("${ApiRoot}forgotpass", formData)
    }

    suspend fun changeUserPass(old: String, new: String): String? {
        val request = PassChangeRequest(old = old, new = new)
        return postJson("${ApiRoot}changepass", jsonX.encodeToString(request))
    }

    suspend fun getDersList(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        //console.log("ders-res:", "start")
        return postForm("${ApiRoot}derslist", formData)
    }

    suspend fun getDersProgram(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}proglist", formData)
    }

    suspend fun addNewDers(dersItem: DersItem, uuid: String? = null): String? {
        val dersBinding =  DersItemBinding(ders = dersItem)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addders", jsonX.encodeToString(dersBinding))
    }

    suspend fun addNewDersProg(did: Long, gun: Int, sira: Int, uuid: String? = null): String? {
        val dersBinding =  NewDersProgBinding(did = did, gun = gun, sira = sira)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addprog", jsonX.encodeToString(dersBinding))
    }

    suspend fun updateDersSira(gun: Int, sira: Int, dersPro: DersProgItem, uuid: String? = null): String? {
        val dersBinding =  DersUpdateBinding(ders = dersPro, gun = gun, sira = sira)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}updateprog", jsonX.encodeToString(dersBinding))
    }

    suspend fun deleteDersItem(dersItem: DersItem, uuid: String? = null): String? {
        val dersBinding =  DersItemBinding(ders = dersItem)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}delders", jsonX.encodeToString(dersBinding))
    }

    suspend fun deleteDersPro(dersPro: DersProgItem, gun: Int, sira: Int, uuid: String? = null): String? {
        val dersBinding =  DersUpdateBinding(ders = dersPro, gun = gun, sira = sira)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}delprog", jsonX.encodeToString(dersBinding))
    }

    suspend fun getEtutList(start: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("start", start)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}etutlist", formData)
    }

    suspend fun getSnfScore(snfId: Long): String? {
        val request =  SnfScoreRequest(snfid = snfId)
        return postJson("${ApiRoot}snfscore", jsonX.encodeToString(request))

    }

    suspend fun sinifaKatil(uuid: String, snf: Int, snfad: String, code: String): String? {
        val request =  SnfKatilRequest(uuid, snf, snfad, code)
        return postJson("${ApiRoot}snfkatil", jsonX.encodeToString(request))
    }


    suspend fun getEtutSearch(text: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("text", text)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}etutsearch", formData)
    }

    suspend fun getGunEtutList(day: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("day", day)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}gunetut", formData)
    }

    suspend fun uploadBase64Image(image: String): String? {
        val formData = FormData()
        formData.append("image", image)
        return postForm("${ApiRoot}tempimage", formData)
    }

    suspend fun uploadTempPdf(pdf: String): String? {
        val formData = FormData()
        formData.append("pdf", pdf)
        return postForm("${ApiRoot}temppdf", formData)
    }


    suspend fun getKaynakList(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}kaynaklist", formData)
    }

    suspend fun addNewKaynak(kaynak: DersKaynak, uuid: String? = null, uuids: List<String> = listOf()): String? {
        val kayBinding =  NewKaynakBinding(kaynak= kaynak, uuids = uuids)
        if (uuid != null){
            kayBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addkaynak", jsonX.encodeToString(kayBinding))
    }

    suspend fun addNewSnfKaynak(snfid: Long, kaynak: SnfKaynak, snf: Int): String? {
        val kayBinding =  NewSnfKaynakBinding(snfid = snfid, kaynak= kaynak, snf = snf)
        return postJson("${ApiRoot}addsnfkaynak", jsonX.encodeToString(kayBinding))
    }


    suspend fun deleteSelectedKaynak(kaynak: DersKaynak): String? {
        val kayBinding =  NewKaynakBinding(kaynak= kaynak)
        return postJson("${ApiRoot}delkaynak", jsonX.encodeToString(kayBinding))
    }
    suspend fun deleteSnfSelectedKaynak(snfid: Long, kaynak: SnfKaynak, snf: Int): String? {
        val kayBinding =  NewSnfKaynakBinding(snfid = snfid, kaynak= kaynak, snf = snf)
        return postJson("${ApiRoot}delsnfkaynak", jsonX.encodeToString(kayBinding))
    }


    //Snf Kaynak Api
    suspend fun getSnfKaynakList(snfid: Long, snf: Int): String? {
        val request =  SnfKaynakRequest(snfid = snfid, snf = snf)
        return postJson("${ApiRoot}snfkaynak", jsonX.encodeToString(request))
    }


    suspend fun getOgrenciList(): String? {
        return postForm("${ApiRoot}ogrencilist", FormData())
    }

    suspend fun addNewOgrenci(form: FormData): String? {
        return postForm("${ApiRoot}addogrenci", form)
    }

    suspend fun addNewEtut(etutItem: NewEtutItem, uuid: String? = null,
                           docs: List<String> = listOf(), uuids: List<String> = listOf()): String? {
        val etutBinding =  NewEtutBinding(etut = etutItem, docs = docs, uuids = uuids)
        if (uuid != null){
            etutBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun editExistingEtut(etutItem: EtutItem, docs: List<String> = listOf()): String? {
        val etutBinding =  EditEtutBinding(etut = etutItem, docs = docs)
        return postJson("${ApiRoot}editetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun copyExistingEtut(etutItem: EtutItem, uuid: String?): String? {
        val etutBinding =  EditEtutBinding(etut = etutItem)
        if (uuid != null){
            etutBinding.uuid = uuid
        }
        return postJson("${ApiRoot}copyetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun editWithDrag(etut: EtutItem, docs: List<String> = listOf()): String? {
        val etutBinding =  EditWithDragBinding(etut.id, etut.tarih, etut.saat)
        return postJson("${ApiRoot}editdrag", jsonX.encodeToString(etutBinding))
    }

    suspend fun deleteEtut(id: Long, eid: Long): String? {
        val etutBinding =  EtutDeleteBinding(id, eid)
        return postJson("${ApiRoot}deletut", jsonX.encodeToString(etutBinding))
    }
    suspend fun replanUnfinished(start: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("start", start)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}replan", formData)
    }

    suspend fun etutDone(etut: EtutItem, dogru: Int = 0, yanlis: Int = 0, net: Double = 0.0): String? {
        val done = if(etut.done) 0 else 1
        val etutBinding =  EtutDoneRequest(etut.id, etut.type, done, dogru, yanlis, net)
        return postJson("${ApiRoot}etutdone", jsonX.encodeToString(etutBinding))
    }

    suspend fun etutDoneWithCevap(etut: EtutItem, snf: Int): String? {
        val etutBinding =  EtutDoneWithCevap(id = etut.id, snf = snf, usercevap = etut.usercevap!!)
        //console.log("etut-cevap:", etutBinding.toString())
        return postJson("${ApiRoot}etutcevap", jsonX.encodeToString(etutBinding))
    }


    suspend fun getHedefList(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}hedeflist", formData)
    }

    suspend fun getFaliyetOzet(start: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("start", start)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}faalozet", formData)
    }

    suspend fun addNewHedef(hedef: Hedef, uuid: String? = null): String? {
        val dersBinding =  NewHedefBinding(hedef = hedef)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addhedef", jsonX.encodeToString(dersBinding))
    }

    suspend fun getSnfData(): String? {
        return postJson("${ApiRoot}getsnf", jsonX.encodeToString(BasicRequest()))
    }

    //Sınıf Api
    suspend fun getSnfReport(snfId: Long, start: String, period: Int): String? {
        val request = SnfReportRequest(snfId, start, period)
        return postJson("${ApiRoot}snfreport", jsonX.encodeToString(request))
    }


    suspend fun addSnfRequest(ad: String, snf: Int): String? {
        val addSnfRequest = AddSnfRequest(snfad = ad, snf = snf)
        return postJson("${ApiRoot}addsnf", jsonX.encodeToString(addSnfRequest))
    }

    //Sınıf Api
    suspend fun getEtutReport(snfId: Long, eid: Long): String? {
        val request = EtutReportRequest(snfId, eid)
        return postJson("${ApiRoot}etutreport", jsonX.encodeToString(request))
    }

    suspend fun getSnfEtutList(snfId: Long, start: String): String? {
        val etutBinding =  SnfEtutListRequest(snfid = snfId, start = start)
        return postJson("${ApiRoot}snfetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun addNewSnfEtut(snfId: Long, etut: NewEtutItem, docs: List<String> = listOf()): String? {
        val etutBinding =  NewSnfEtutBinder(snfid = snfId, etut = etut, docs = docs)
        return postJson("${ApiRoot}addsnfetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun etutEditWithDrag(etut: SnfEtutItem, docs: List<String> = listOf()): String? {
        val etutBinding =  EditWithDragBinding(etut.eid, etut.tarih, etut.saat)
        return postJson("${ApiRoot}editdrag", jsonX.encodeToString(etutBinding))
    }

    suspend fun addSnfOgrenci(snf: SnfInfo, name: String, code: String): String? {
        val request =  AddSnfOgrBinder(snf, name, code)
        return postJson("${ApiRoot}addsnfogr", jsonX.encodeToString(request))

    }



    fun uploadFile(file: File, onComplete: (String?) -> Unit) {
        val formData = FormData()
        formData.append("file", file)
        val xhr = XMLHttpRequest()
        xhr.open("POST", "/upload", true)
        // Set the Content-Type header to "multipart/form-data"
        xhr.setRequestHeader("Content-Type", "multipart/form-data")
        xhr.onload = { _ ->
            if (xhr.status.toInt() == 200) {
                onComplete(xhr.responseText)
            } else {
                console.error("File upload failed.")
            }
        }
        xhr.send(formData)
    }

    private suspend fun postForm(url: String, body: FormData? = null): String? {
        val form = body ?: FormData() // Use the provided FormData or create a new one if null

        // Get or set the UUID in the form
        var uuid = form.get("uuid") as? String ?: ""
        if (uuid.isEmpty()) {
            uuid = AppData.appUser.uuid.ifEmpty {
                AppStorage.getString(AppStorage.userUUID)
            }
            form.set("uuid", uuid)
        }
        val response = window.fetch(url, object : RequestInit {
            override var method: String? = "POST"
            override var body: FormData? = form
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()

        return if (response.ok) JSON.stringify(response.json().await()) else null
    }


    private suspend fun postJson(url: String, json: String? = null): String? {
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "POST"
            override var body: String? = json
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }

    private suspend fun apiGet(url: String): String? {
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "GET"
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }
}








