package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun ErrorText(errorText: String, modifier: Modifier = Modifier){
    Span(attrs = Modifier
        .fillMaxWidth()
        .fontSize(14.px)
        .textAlign(TextAlign.Center)
        .padding(2.px)
        .color(AppColors.NumRed).then(modifier)
        .toAttrs()){
        Text(errorText)
    }
}

@Composable
fun InfoText(text: String, modifier: Modifier = Modifier){
    Span(attrs = Modifier
        .fillMaxWidth()
        .fontSize(14.px)
        .textAlign(TextAlign.Center)
        .padding(2.px)
        .color(AppColors.GrayDark).then(modifier)
        .toAttrs()){
        Text(text)
    }
}