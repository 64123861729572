package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.EtutRole
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.styles.RoleButtonStyle

import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.px

@Composable
fun RoleButton(role: EtutRole, selected: Boolean, onClick: () -> Unit) {
    Column(modifier = RoleButtonStyle.toModifier()
        .margin(leftRight = 6.px, bottom = 16.px)
        .minWidth(100.px)
        .backgroundColor(if(selected) AppColors.TextColor.opacity(0.3f)else Colors.Transparent)
        .onClick {
            onClick()
        },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier
                .size(32.px),
            src = role.image,
            alt = "${role.text} etut takibi"

        )
        SpanText(role.text,
            modifier = Modifier.fontSize(16.px)
            .color(AppColors.TextColor.opacity(0.6f)))

    }
}