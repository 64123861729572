package com.kelimesoft.etutpro.models

enum class OgrenciSnf(val snf: Int, val text: String) {
    SnfO(0, "Okul Öncesi"),
    Snf1(1, "İlkokul 1.Sınıf"),
    Snf2(2, "İlkokul 2.Sınıf"),
    Snf3(3, "İlkokul 3.Sınıf"),
    Snf4(4, "İlkokul 4.Sınıf"),
    Snf5(5, "Ortaokul 5.Sınıf"),
    Snf6(6, "Ortaokul 6.Sınıf"),
    Snf7(7, "Ortaokul 7.Sınıf"),
    Snf8(8, "Ortaokul 8.Sınıf"),
    Snf9(9, "Lise 9.Sınıf"),
    Snf10(10, "Lise 10.Sınıf"),
    Snf11(11, "Lise 11.Sınıf"),
    Snf12(12, "Lise 12.Sınıf"),
    Snf13(13, "Lise Mezun"),
    Snf20(20, "Üniversite ....");

    companion object {
        fun  snfNames(): List<String> {
            return entries.map {
                it.text
            }
        }


    }

}