package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.FaEdit
import com.kelimesoft.etutpro.components.MyFaIcon
import com.kelimesoft.etutpro.components.RowButton
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.models.AppData.ViewModel.ogrenciVM
import com.kelimesoft.etutpro.styles.EtutRowStyle
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.utils.isVideo
import com.kelimesoft.etutpro.utils.rountTo
import com.kelimesoft.etutpro.viewmodels.EtutVM
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Col
import org.jetbrains.compose.web.dom.Text


@Composable
fun EtutListRow(
    etutVM: EtutVM,
    etut: EtutItem, onEdit: () -> Unit,
    onDelete: () -> Unit,
    onComplete: () -> Unit,
    onCevap: () -> Unit,
    dragStart: (String) -> Unit
) {
    var isMobile = Funcs.isMobile()
    val context = rememberPageContext()
    val dersVM = AppData.ViewModel.dersVM

    val typeIcon = EtutType.entries.find { it.type == etut.type }?.icon ?: "file-contract"
    var dersText by remember { mutableStateOf(etut.ders) }
    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }
    val dragItemId = "etutDrag-${etut.id}"

    var showDelAlert by remember { mutableStateOf(false) }
    //var showTestGir by remember { mutableStateOf(false) }


    LaunchedEffect(etutVM.etutListMap, ogrenciVM.selectedOgrenci) {
        if (etut.type < 2) {
            dersText = etut.ders
            if (dersVM.dersList.isNotEmpty()) {
                dersVM.dersList.find { it.dersad == etut.ders }?.let {
                    dersColor = AppColors.colorDersList[it.renk]
                }
            }
        } else if (etut.type == EtutType.Kuran.type) {
            dersText = "Kur'an Okuma"
            dersColor = AppColors.Primary
        } else if (etut.type == EtutType.Kitap.type) {
            dersText = "Kitap Okuma"
            dersColor = AppColors.PrimaryDark
        } else {
            dersColor = AppColors.DarkRed
            dersText = "Sınav / Deneme"
        }
    }

    Box(
        modifier = EtutRowStyle.toModifier()
            .id(dragItemId)
            .thenIf(etutVM.kaynakDersOver.isNotEmpty() && etut.ders != etutVM.kaynakDersOver) {
                Modifier.opacity(0.12f)
                    .styleModifier {
                        property("backdrop-filter", "blur(1px)") // Apply CSS blur effect
                        property("filter", "blur(1px)") // Fallback for browsers that don't support backdrop-filter
                    }
                    .scale(0.95f)
            }
            .thenIf(etutVM.kaynakDersOver.isNotEmpty() && etut.ders == etutVM.kaynakDersOver) {
                Modifier.opacity(1.2f)
                    .scale(1.05f)
            }
            .onClick {
                it.stopPropagation()
            },
        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .draggable(!etut.done && !etutVM.etutScrolling)
                .onDragStart {
                    dragStart(dragItemId)

                }

                .minHeight(80.px)
                .onClick {
                    //onDelete(false)
                    showDelAlert = false
                    etutVM.selectForCopy(etut)
                    Funcs.closeDropDowns()

                }
                .boxShadow(
                    (0.5).px,
                    0.5.px,
                    1.px,
                    1.px,
                    color = if (etut.done) AppColors.GrayDark.opacity(0.2f) else Colors.DarkGray.opacity(0.4f)
                )
                .onCopy {
                    etutVM.copiedEtut = etut
                    if (AppData.appUser.role > 0) {
                        ogrenciVM.selectedOgrenci?.let {
                            etutVM.copiedUserUuid = it.uuid
                        }
                    }


                }
                .backgroundColor(
                    if (etut.done) AppColors.PrimaryDark.opacity(0.1f)
                    else if (etut.id == etutVM.etutSelectForCopy?.id) Colors.White.opacity(0.6f) else Colors.White
                )
                .border(
                    if (etut.id == etutVM.etutSelectForCopy?.id) 0.5.px else 0.px,
                    color = Colors.Gray.opacity(0.5f),
                    style = LineStyle.Ridge
                )
                .padding(1.px)
                .borderRadius(r = 2.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (etut.type == EtutType.Kuran.type) {
                    Image(
                        modifier = Modifier
                            .size(15.px),
                        src = Images.Kuran, alt = "Kutsal kitab Kuranı okumak"
                    )
                } else {
                    MyFaIcon(Modifier.color(dersColor), icon = typeIcon)
                }

                SpanText(
                    dersText,
                    modifier = Modifier.fillMaxWidth()
                        .margin(left = 2.px)
                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
                Spacer()
                Row(
                    modifier = Modifier.backgroundColor(Colors.White)
                        .padding(1.px)
                        .borderRadius(r = 6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FaClock(modifier = Modifier.color(AppColors.TextColor), size = IconSize.SM)
                    SpanText(
                        etut.saat,
                        modifier = Modifier.fillMaxWidth()
                            .margin(left = 3.px)
                            .color(dersColor)
                            .fontSize(12.px)
                            .fontWeight(FontWeight.Normal)
                            .padding(1.px)
                    )
                }

            }


            SpanText(
                etut.konu,
                modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor.opacity(0.9f))
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(1.px)
            )

            if (etut.soru > 0) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.Bottom
                ) {
                    if (etut.type == EtutType.Kitap.type || etut.type == EtutType.Kuran.type) {
                        SpanText(
                            "Sayfa Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    } else {
                        SpanText(
                            if (etut.done) "Soru: ${etut.soru}" else "Soru Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.type || etut.type == EtutType.Kuran.type) 15.px else 13.px)
                                .padding(1.px)
                        )
                    }

                    if (etut.done && etut.net > 0
                        && (etut.type == EtutType.KonuTest.type || etut.type == EtutType.Deneme.type)
                    ) {
                        SpanText(
                            "D: ${etut.dogru},",
                            modifier = Modifier
                                .color(AppColors.NumGreen)
                                .fontSize(12.px)
                                .padding(1.px)
                        )
                        SpanText(
                            "Y: ${etut.yanlis}",
                            modifier = Modifier
                                .margin(right = 6.px)
                                .color(AppColors.DarkRed)
                                .fontSize(12.px)
                                .padding(1.px)
                        )
                        SpanText(
                            "Net : ${etut.net.rountTo(1)}",
                            modifier = Modifier
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }
                }
            }

            if (etut.kaynak.isNotEmpty()) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SpanText(
                        etut.kaynak,
                        modifier = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .padding(1.px)
                    )
                    if (etut.sayfa.isNotEmpty()) {
                        SpanText(
                            " (syf: ${etut.sayfa})",
                            modifier = Modifier
                                .color(AppColors.TextColor.opacity(0.6f))
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }
                }

            }

            if (etut.belge.isNotEmpty()) {
                Row(modifier = Modifier
                    .backgroundColor(AppColors.GrayDark)
                    .margin(3.px)
                    .padding(leftRight = 4.px, topBottom = 2.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        val belgeLink = "https://etutpro.com/etutdocs/${etut.belge}"
                        context.router.navigateTo(belgeLink)
                    }
                    .borderRadius(r = 4.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    var linkTitle = "Dökümanı Çalış"
                    if (etut.type == EtutType.KonuTest.ordinal) {
                        linkTitle = "Teste Başla"
                    }
                    FaFilePdf(modifier = Modifier.color(AppColors.Red), size = IconSize.LG)
                    SpanText(
                        linkTitle,
                        modifier = Modifier.fillMaxWidth()
                            .margin(leftRight = 4.px)
                            .color(AppColors.ViewWhite)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }
            }

            if (etut.notlar.isNotEmpty()){
                SpanText(text = etut.notlar, modifier = Modifier
                    .fillMaxWidth()
                    .fontSize(0.7.cssRem)
                    .color(AppColors.DetailBlue.opacity(0.7f))
                    .textAlign(TextAlign.Start)
                )
            }

            //Box(modifier = Modifier.fillMaxWidth().height(1.px).backgroundColor(Colors.Gray.opacity(0.4f)))
        }

        EtutButtons(etut, etut.id == etutVM.etutSelectForCopy?.id,
            onEdit = {
            onEdit()
        }, onDelete = {
            showDelAlert = true
            //onDelete()
        }, onComplete = {
            onComplete()
        }, onCevap = {
            onCevap()
        }, onCopy = {
            etutVM.copiedEtut = etut
            if (AppData.appUser.role > 0) {
                ogrenciVM.selectedOgrenci?.let {
                    etutVM.copiedUserUuid = it.uuid
                }
            }
        })

        if (showDelAlert && etutVM.etutSelectForCopy == etut) {
            EtutDeleteOnay(onCancel = {
                showDelAlert = false
            }, onDelete = {
                onDelete()
            })
        }

    }

}

@Composable
fun EtutButtons(
    etut: EtutItem, selected: Boolean,
    onEdit: () -> Unit, onCopy: () -> Unit,
    onDelete: () -> Unit,
    onComplete: () -> Unit,
    onCevap: () -> Unit
) {

    val userRole = AppData.appUser.role
    Column(
        modifier = Modifier.fillMaxHeight(),
        horizontalAlignment = Alignment.End,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        if (selected) {
            RowButton {
                FaCopy(
                    modifier = Modifier.color(AppColors.Gray.opacity(0.9f))
                        .onClick {
                            onCopy()
                        },
                    size = IconSize.SM
                )
            }
        }
        Row {
            if (selected) {
                if (!etut.done) {
                    RowButton {
                        FaEdit(
                            modifier = Modifier
                                .color(AppColors.Gray.opacity(0.9f))
                                .onClick {
                                    onEdit()
                                },
                            size = IconSize.SM
                        )
                    }
                }

            }
        }

        Row(
            modifier = Modifier.margin(bottom = 6.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (selected && (userRole == etut.addBy || etut.addBy == 0)) {
                RowButton(modifier = Modifier.margin(left = 10.px)) {
                    FaTrash(
                        modifier = Modifier
                            .color(AppColors.Red.opacity(0.8f))
                            .onClick {
                                onDelete()
                            },
                        size = IconSize.SM
                    )
                }
            }
            EtutDoneButon(etut, onComplete = {
                onComplete()
            }, onCevap = {
                onCevap()
            })

            /*

            RowButton(modifier = Modifier.margin(left = 10.px)) {
                FaCheck(
                    modifier = Modifier
                        .color(if (etut.done) AppColors.Primary.opacity(0.9f) else AppColors.Gray.opacity(0.9f))
                        .onClick {
                            onComplete()
                        },
                    size = IconSize.XL,

                    )
            }

             */
        }


    }
}


@Composable
fun EtutListVideo(
    etutVM: EtutVM,
    etut: EtutItem, onEdit: () -> Unit,
    onDelete: () -> Unit,
    onComplete: () -> Unit,
    dragStart: (String) -> Unit
) {
    val dersVM = AppData.ViewModel.dersVM
    var dersText by remember { mutableStateOf(etut.ders) }
    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }
    val dragItemId = "etutDrag-${etut.id}"

    var showDelAlert by remember { mutableStateOf(false) }

    LaunchedEffect(etutVM.etutListMap, ogrenciVM.selectedOgrenci) {
        dersText = etut.ders
        if (dersVM.dersList.isNotEmpty()) {
            dersVM.dersList.find { it.dersad == etut.ders }?.let {
                dersColor = AppColors.colorDersList[it.renk]
            }

        }


    }

    Box(
        modifier = EtutRowStyle.toModifier()
            .id(dragItemId)
            .onClick {
                it.stopPropagation()
            }

            .thenIf(etutVM.kaynakDersOver.isNotEmpty() && etut.ders != etutVM.kaynakDersOver) {
                Modifier.opacity(0.12f)
                    .styleModifier {
                        property("backdrop-filter", "blur(1px)") // Apply CSS blur effect
                        property("filter", "blur(1px)") // Fallback for browsers that don't support backdrop-filter
                    }
                    .scale(0.95f)


            }
            .thenIf(etutVM.kaynakDersOver.isNotEmpty() && etut.ders == etutVM.kaynakDersOver) {
                Modifier.opacity(1.5f)
                    .scale(1.05f)
            },

        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .draggable(if (etut.done) Draggable.False else Draggable.True)
                .onDragStart {
                    dragStart(dragItemId)

                }
                .minHeight(80.px)
                .onClick {
                    //onDelete(false)
                    showDelAlert = false
                    etutVM.selectForCopy(etut)
                    Funcs.closeDropDowns()

                }
                .boxShadow(
                    (0.5).px,
                    0.5.px,
                    1.px,
                    1.px,
                    color = if (etut.done) AppColors.GrayDark.opacity(0.2f) else Colors.DarkGray.opacity(0.4f)
                )
                .onCopy {
                    etutVM.copiedEtut = etut
                    if (AppData.appUser.role > 0) {
                        ogrenciVM.selectedOgrenci?.let {
                            etutVM.copiedUserUuid = it.uuid
                        }
                    }
                }
                .backgroundColor(
                    if (etut.done) AppColors.PrimaryDark.opacity(0.1f)
                    else if (etut.id == etutVM.etutSelectForCopy?.id) Colors.White.opacity(0.6f) else Colors.White
                )
                .border(
                    if (etut.id == etutVM.etutSelectForCopy?.id) 0.5.px else 0.px,
                    color = Colors.Gray.opacity(0.5f),
                    style = LineStyle.Ridge
                )
                .padding(1.px)
                .borderRadius(r = 2.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaYoutube(Modifier.color(dersColor), size = IconSize.SM)
                SpanText(
                    dersText,
                    modifier = Modifier.fillMaxWidth()
                        .margin(left = 2.px)
                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
                Spacer()
                Row(
                    modifier = Modifier.backgroundColor(Colors.White)
                        .padding(1.px)
                        .borderRadius(r = 6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FaClock(modifier = Modifier.color(AppColors.TextColor), size = IconSize.SM)
                    SpanText(
                        etut.saat,
                        modifier = Modifier.fillMaxWidth()
                            .margin(left = 3.px)
                            .color(dersColor)
                            .fontSize(12.px)
                            .fontWeight(FontWeight.Normal)
                            .padding(1.px)
                    )
                }

            }

            Row(modifier = Modifier.fillMaxWidth()) {
                VideoThumbnail(etut.link)
                Column(modifier = Modifier.fillMaxWidth()) {
                    SpanText(
                        etut.konu,
                        modifier = Modifier.fillMaxWidth()
                            .color(AppColors.TextColor.opacity(0.9f))
                            .fontSize(14.px)
                            .fontWeight(FontWeight.SemiBold)
                            .padding(1.px)
                    )

                    SpanText(
                        etut.kaynak,
                        modifier = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .padding(1.px)
                    )
                }
            }


        }

        EtutButtons(etut, etut.id == etutVM.etutSelectForCopy?.id, onEdit = {
            onEdit()
        }, onDelete = {
            showDelAlert = true
            //onDelete()
        }, onComplete = {
            onComplete()
        }, onCevap = {
        }, onCopy = {
            etutVM.copiedEtut = etut
            if (AppData.appUser.role > 0) {
                ogrenciVM.selectedOgrenci?.let {
                    etutVM.copiedUserUuid = it.uuid
                }
            }
        })

        if (showDelAlert && etutVM.etutSelectForCopy == etut) {
            EtutDeleteOnay(onCancel = {
                showDelAlert = false
            }, onDelete = {
                onDelete()
            })
        }

    }

}

@Composable
fun VideoThumbnail(videoUrl: String) {
    fun extractVideoId(videoUrl: String): String? {
        val regex = "(?<=watch\\?v=|youtu.be/|embed/)([^#&?]*)".toRegex()
        return regex.find(videoUrl)?.value
    }

    val videoId = extractVideoId(videoUrl)
    val thumbnailUrl = "https://img.youtube.com/vi/$videoId/hqdefault.jpg"

    // Display the thumbnail as an image and link it to the video
    Link(path = videoUrl, modifier = Modifier.padding(2.px)) {
        Image(
            src = thumbnailUrl,
            modifier = Modifier
                .fillMaxWidth()
                .objectFit(ObjectFit.Contain)
                .borderRadius(4.px)
                .margin(2.px)

        )
    }
}