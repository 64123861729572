package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.styles.ContexMenuItemStyle
import com.kelimesoft.etutpro.styles.SnfDataItemButton
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable

fun SnfDataView(snfVM: SinifVM,
                onSnfAdd: () -> Unit,
                showInfo: () -> Unit,
                addOgrenci: (Long) -> Unit
) {

    val breakpoint = rememberBreakpoint()

    var showKatilimData by remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .fillMaxHeight()
            .width(if (breakpoint < Breakpoint.MD) 170.px else 210.px)
            .padding(leftRight = 2.px)
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(AppColors.Gray.opacity(0.2f))
                .boxShadow(
                    1.px,
                    1.px,
                    1.px,
                    1.px,
                    color = AppColors.GrayDark.opacity(0.1f)
                )
                .borderRadius(4.px),
            horizontalAlignment = Alignment.CenterHorizontally

        ) {
            snfVM.snfListMap.forEach { snfMap ->
                SnfItemContent(snfVM, snfMap, showInfo = {
                    showInfo()
                }, addOgrenci = {
                    addOgrenci(snfMap.key.id)
                })

            }
            if (snfVM.snfListMap.isEmpty()) {
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    SpanText(
                        "Öğrenci etüt takibi için lütfen Sınıf oluşturunuz!",
                        modifier = Modifier
                            .fontSize(14.px)
                            .color(AppColors.DarkRed)
                            .padding(10.px)
                    )
                    BSButton(
                        modifier = Modifier.height(24.px)

                            .fontSize(13.px)
                            .margin(leftRight = 6.px, topBottom = 2.px),
                        text = "+Sınıf Oluştur",
                        size = ButtonSize.Small,
                        variant = ButtonVariant.SuccessOutline,
                        onClick = {
                            onSnfAdd()
                        }
                    )
                }

            }

        }

        if (showKatilimData) {
            DialogView {

            }
        }
    }
}


@Composable
fun SnfKatilData(onClose: () -> Unit) {
    Column(modifier = Modifier.padding(4.px)) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .padding(4.px)
                .borderRadius(r = 6.px)
                .backgroundColor(Colors.White),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            DialogTitle("Sınıf Giriş Bilgisi", onClose = onClose)

            SpanText(
                text = "Sınıf ", modifier = Modifier
                    .color(AppColors.DarkRed)
                    .fontSize(FontSize.Small)
            )

            BSButton(
                modifier = Modifier
                    .margin(topBottom = 6.px),
                text = "Yeni Şifre",
                size = ButtonSize.Small,
                variant = ButtonVariant.Danger,
                onClick = {

                }
            )

        }

        Box(modifier = Modifier.minHeight(70.px)) { }

    }

}

@Composable
fun SnfItemContent(
    snfVM: SinifVM,
    snfMap: Map.Entry<SnfInfo,
        List<SnfUserData>>,
    showInfo: () ->Unit,
    addOgrenci: () -> Unit
) {
    var ogrenciList = snfMap.value.filter { it.ogr != null }

    Box(
        modifier = Modifier.fillMaxWidth()
            .minHeight(60.px)
            .padding(leftRight = 2.px)
            .overflow(Overflow.Hidden)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(Colors.White)
                .padding(2.px)
                .margin(2.px)
                .overflow(overflowX = Overflow.Auto, overflowY = Overflow.Unset)
                .borderRadius(8.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    //.fontSize(14.px)
                    .backgroundColor(AppColors.Skyblue.opacity(0.7f))
                    .margin(bottom = 6.px)
                    .padding(3.px)
                    .borderRadius(4.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaBookmark(
                    size = IconSize.SM, modifier = Modifier
                        .margin(right = 3.px)
                        .cursor(Cursor.Pointer)
                )

                SpanText(snfMap.key.name, modifier = Modifier.fontSize(15.px))
                Spacer()

                Button(attrs = SnfDataItemButton.toModifier()
                    .borderRadius(r = 6.px)
                    .onClick {
                        snfVM.selectedSnf = snfMap.key
                        showInfo()
                    }
                    .toAttrs()) {
                    FaPen(size = IconSize.SM, modifier = Modifier
                        .color(AppColors.TextColor))
                }


            }
            if (ogrenciList.isEmpty()) {
                SpanText(
                    "Sınıfınızda henüz öğrenci kayıtlı değil, Öğrenciniz kendi profilinden " +
                            "Sınıfa Katıl butonuyla sınıfınıza katılabilirler. ",
                    modifier = Modifier.fontSize(11.px)
                        .color(AppColors.DarkRed)
                )
            }else{
                OgrenciTumuSelect(selected = snfVM.selectedOgr == null,
                    onSelect = {
                        if (snfVM.selectedOgr != null){
                            snfVM.selectedOgr = null
                        }
                    })
            }
            ogrenciList.forEach {
                OgrenciInfoRow(it.ogr!!,
                    selected = snfVM.selectedOgr == it.ogr,
                    onSelect = {
                        if (snfVM.selectedOgr == it.ogr){
                            snfVM.selectedOgr = null
                        }else{
                            AppData.ViewModel.dersVM.dersList = setOf()
                            AppData.ViewModel.etutVM.allEtutList = listOf()
                            snfVM.selectedOgr = it.ogr
                        }
                    },
                    onDelete = {

                    })
            }


            BSButton(
                modifier = Modifier.height(24.px)
                    .fontSize(13.px)
                    .margin(leftRight = 6.px, topBottom = 2.px),
                text = "+Öğrenci Ekle",
                size = ButtonSize.Small,
                variant = ButtonVariant.SuccessOutline,
                onClick = {
                    addOgrenci()
                }
            )
        }
    }
}


@Composable
fun OgrenciInfoRow(
    ogrenciInfo: OgrenciInfo,
    selected: Boolean,
    onSelect: () -> Unit,
    onDelete: () -> Unit,
) {
    Div(
        attrs = Modifier.fillMaxWidth()
            .position(Position.Relative)
            .display(DisplayStyle.InlineBlock)
            .toAttrs()
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .cursor(Cursor.Pointer)
                .onClick {
                    onSelect()
                }
                .padding(2.px)
                .backgroundColor(
                    if (selected) AppColors.NarenciOrange.opacity(0.6f)
                    else AppColors.NarenciOrange.opacity(0.3f)
                )
                .borderRadius(r = 4.px)
                .margin(bottom = 3.px)
                .thenIf(selected){
                    Modifier.border(width = (0.3).px, style = LineStyle.Solid, color = AppColors.Gray)
                }
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor)
                    .onContextMenu {
                        //it.stopPropagation()
                        it.preventDefault()
                        val menu = document.getElementById("drop-ogr${ogrenciInfo.uuid}") as HTMLDivElement
                        if (menu.style.display == "block") {
                            menu.style.display = "none"
                        } else {
                            Funcs.closeDropDowns()
                            menu.style.display = "block"
                        }
                    },
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                FaUser(
                    size = IconSize.SM, modifier = Modifier
                        .padding(2.px)
                        .margin(right = 4.px)
                )
                Span(
                    attrs = Modifier
                        .fillMaxWidth()
                        .fontSize(13.px)
                        .padding(2.px)
                        .toAttrs()
                ) {
                    Text(ogrenciInfo.name)
                }

            }

            Div(
                attrs = Modifier
                    .id("drop-ogr${ogrenciInfo.uuid}")
                    .classNames("app-menu")
                    .display(DisplayStyle.None)
                    .position(Position.Absolute)
                    .styleModifier {
                        property("right", "0")
                    }
                    .backgroundColor(AppColors.DropMenu)
                    .borderRadius(r = 6.px)
                    .boxShadow(0.px, 8.px, 16.px, 0.px, Color.rgba(0, 0, 0, 0.2f))
                    .minWidth(120.px)
                    //.maxWidth(200.px)
                    .padding(10.px)
                    .zIndex(4)
                    .toAttrs()
            ) {
                Column {
                    SpanText("Sil", modifier = ContexMenuItemStyle
                        .toModifier()
                        .onClick {
                            onDelete()
                        })


                }

            }

        }
    }
}

@Composable
fun OgrenciTumuSelect(selected: Boolean, onSelect: () -> Unit){
    Box(
        modifier = Modifier.fillMaxWidth()
            .cursor(Cursor.Pointer)
            .onClick {
                onSelect()
            }
            .padding(2.px)
            .backgroundColor(
                if (selected) AppColors.NarenciOrange.opacity(0.6f)
                else AppColors.NarenciOrange.opacity(0.3f)
            )
            .borderRadius(r = 4.px)
            .margin(bottom = 3.px)
            .thenIf(selected){
                Modifier.border(width = (0.3).px, style = LineStyle.Solid, color = AppColors.Gray)
            }
    ) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .color(AppColors.TextColor),

            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start
        ) {
            FaUsers(
                size = IconSize.SM, modifier = Modifier
                    .padding(2.px)
                    .margin(right = 4.px)
            )
            Span(
                attrs = Modifier
                    .fillMaxWidth()
                    .fontSize(13.px)
                    .padding(2.px)
                    .toAttrs()
            ) {
                Text("Tümü Sınıf")
            }

        }
    }
}