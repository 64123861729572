package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ders.DersGunuSaati
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSCheckbox
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.forms.BSTextArea
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSelectElement


@Composable
fun AddDenemeSnv(
    etut: EtutItem? = null,
    date: String,
    saat: String,
    onClose: () -> Unit,
    onEdit: (EtutItem) -> Unit,
    onAdd: (NewEtutItem, List<String>) -> Unit
) {
    var denemeAd by remember { mutableStateOf("") }
    var soruSay by remember { mutableStateOf(0) }
    var saati by remember { mutableStateOf(saat) }
    var notlar by remember { mutableStateOf("") }
    val uuidList: MutableSet<String> by remember { mutableStateOf(mutableSetOf()) }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    LaunchedEffect(alertModel){
        if (alertModel != null){
            delay(3000)
            alertModel = null
        }
    }

    fun findEtutStartTime(){
        saati = AppData.ViewModel.etutVM.findEtutStartTime(date)
    }


    LaunchedEffect(Unit) {

        if (etut != null) {
            denemeAd = etut.konu
            notlar = etut.notlar
            saati = etut.saat
            soruSay = etut.soru
            delay(200)
        }else if (saati == "") {
            findEtutStartTime()
        }
    }



    fun saveEtut() {
        var snvDate = etut!!.tarih


        val etutItem = etut.copy(
            saat = saati,
            konu = denemeAd,
            soru = soruSay,
            notlar = notlar,
            tarih = snvDate,
            done = false
        )
        onEdit(etutItem)
    }

    fun addEtut() {
        val etutItem = NewEtutItem(
            type = EtutType.Deneme.type,
            konu = denemeAd,
            soru = soruSay,
            tarih = date,
            saat = saati,
        )
        onAdd(etutItem, uuidList.toList())
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(520.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .overflow(Overflow.Hidden)
    ) {

        DialogTitle("Deneme Sınavı", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("etutadd-form")
                .toAttrs()
        ) {

            Row(
                modifier = Modifier.margin(left = 2.px)
                    .margin(bottom = 4.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(left = 2.px, right = 1.px),
                    type = InputType.Text,
                    value = denemeAd,
                    placeholder = "Sınav Adı",
                    required = true,
                    onValueChange = {
                        denemeAd = it
                    }
                )
                BSTimeInput(
                    modifier = Modifier
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    placeholder = "Snv Saati:",
                    value = saati,
                    onValueChange = {
                        saati = it
                    }
                )
            }
        }

        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(bottom = 8.px)
                .padding(leftRight = 4.px)
            ,
            verticalAlignment = Alignment.Bottom
            ) {


            BSTextArea(
                modifier = Modifier
                    .fillMaxWidth()
                    .minHeight(56.px)
                    .fontSize(FontSize.Smaller)
                    .padding(leftRight = 2.px),
                //type = InputType.Text,
                value = notlar,
                required = true,
                placeholder = "Notlar",
                onValueChange = {
                    notlar = it
                }
            )
            Column{
                SpanText("Soru Sayısı", modifier = Modifier
                    .fontSize(FontSize.Smaller)
                    .color(AppColors.Gray)
                    .padding(leftRight = 2.px),
                )
                BSNumberInput(
                    modifier = Modifier
                        .minWidth(100.px)
                        .padding(leftRight = 2.px),
                    type = InputType.Number,
                    value = soruSay.toString(),
                    placeholder = "Soru Sayısı",
                    onValueChange = {
                        soruSay = it
                    }
                )
            }



        }

        AddEtutBottomButtons(onClose = {
            onClose()
        }, onSave = {
            if (etut != null){
                saveEtut()
            }else{
                addEtut()
            }

        })
        if (etut == null && AppData.appUser.role > 0
            && AppData.ViewModel.ogrenciVM.ogrenciListem.isNotEmpty()
        ) {
            fun getSnf(ogr: OgrenciInfo): String {
                if (ogr.snf == 13) return " (Mezun)"
                else if (ogr.snf == 20) return " (Ünv.)"
                else return " (${ogr.snf}.Snf)"
            }
            VDivider(modifier = Modifier.margin(topBottom = 1.px))
            Span(
                attrs = Modifier.color(AppColors.DarkRed)
                    .margin(bottom = 2.px)
                    .fontSize(11.px).toAttrs()
            ) {
                Text("Diğer öğrencilerinize de aynı videoyu ekleyebilirsiniz.")
            }

            SimpleGrid(numColumns(base = 4), modifier = Modifier.fillMaxWidth()) {
                val ogrenciVM = AppData.ViewModel.ogrenciVM
                val secOgr = ogrenciVM.selectedOgrenci
                ogrenciVM.ogrenciListem
                    .filter { it.uuid != secOgr?.uuid && it.snf == secOgr?.snf }
                    .sortedBy { it.name }
                    .forEach { ogr ->
                        Box(modifier = Modifier.margin(right = 2.px)) {
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .backgroundColor(AppColors.Gray.opacity(0.4f))
                                    .borderRadius(r = 4.px)
                                    .margin(right = 2.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                BSCheckbox(
                                    modifier = Modifier
                                        .margin(0.px)
                                        .color(AppColors.TextColor),
                                    defaultChecked = false,
                                    label = "",
                                    onClick = {
                                        if (it) {
                                            uuidList.add(ogr.uuid)
                                        } else {
                                            uuidList.remove(ogr.uuid)
                                        }
                                    }
                                )
                                Span(attrs = Modifier.fontSize(13.px).toAttrs()) {
                                    Text(ogr.name)
                                    Span(attrs = Modifier.fontSize(10.px).toAttrs()) { Text(getSnf(ogr)) }
                                }
                            }
                        }
                    }

            }
        }
    }

    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style
        )
    }

}






