package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.styles.EtutDropButonStyle
import com.kelimesoft.etutpro.styles.SmallAddButonStyle
import com.kelimesoft.etutpro.utils.Funcs.closeDropDowns
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaYoutube
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLDivElement


@Composable
fun EtutAddMenu(id: String, onSelect: (EtutType) -> Unit) {
    DropDownMenu(
        butonModifier = SmallAddButonStyle.toModifier()
            .margin(right = 4.px)
            .onClick {
                it.stopPropagation()
                val menu = document.getElementById("drop$id") as HTMLDivElement
                if (menu.style.display == "block") {
                    menu.style.display = "none"
                } else {
                    closeDropDowns()
                    menu.style.display = "block"
                }
            },
        rightAlign = true,
        id = "drop$id",
        content = {
            SpanText(
                " + ",
                modifier = Modifier
                    .fontSize(16.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(Colors.White)
                    .textAlign(TextAlign.Center)
            )
        }
    ) {
        EtutType.entries.forEachIndexed { idx, type ->
            if (idx > 0){
                VDivider(modifier = Modifier.margin(bottom = 1.px))
            }

            Row(modifier = EtutDropButonStyle.toModifier()
                .onClick {
                    onSelect(type)
                }
                .thenIf(type == EtutType.Deneme){
                    Modifier.color(AppColors.DarkRed.opacity(0.8f))
                }
                .thenIf(type == EtutType.Kitap){
                    Modifier.color(AppColors.PrimaryDark.opacity(0.8f))
                }
                .thenIf(type == EtutType.Kuran){
                    Modifier.color(AppColors.Primary.opacity(0.9f))
                }
                .fillMaxWidth(),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.CenterVertically) {
                Box(modifier = Modifier.width(20.px),
                    contentAlignment = Alignment.Center) {
                    if (type == EtutType.Kuran) {
                        Image(modifier = Modifier
                            .margin(1.px)
                            .size(16.px),
                            src = Images.Kuran, alt = "Kutsal kitab Kuranı okumak")
                    }else if (type == EtutType.Video) {
                        FaYoutube(modifier = Modifier.width(16.px).color(AppColors.Red))
                    }else{
                        MyFaIcon(modifier = Modifier.width(16.px), icon = type.icon)
                    }
                }

                SpanText(
                    type.text,

                )
            }


        }

    }
}


@Composable
fun EtutDoneButon(
    etut: EtutItem,
    onComplete: () -> Unit,
    onCevap: () -> Unit
) {
    var dogruSay by remember { mutableStateOf(0) }
    var yanlisSay by remember { mutableStateOf(0) }
    var errText by remember { mutableStateOf("") }
    LaunchedEffect(true){
        dogruSay = etut.dogru
        yanlisSay = etut.yanlis
    }
    LaunchedEffect(errText){
        if (errText.isNotEmpty()){
            delay(1500L)
            errText = ""
        }
    }
    DropDownMenu(
        butonModifier = SmallAddButonStyle.toModifier()
            .margin(right = 4.px)
            .onClick {
                it.stopPropagation()
                if (!etut.cevap.isNullOrEmpty()){
                    onCevap()
                }else if (etut.done
                    || etut.type == EtutType.Kuran.type
                    || etut.type == EtutType.Konu.type
                    || etut.type == EtutType.Video.type
                    || etut.type == EtutType.Kitap.type){
                    onComplete()
                }else{
                    val menu = document.getElementById("scoreText${etut.id}") as HTMLDivElement
                    if (menu.style.display == "block") {
                        menu.style.display = "none"
                    } else {
                        closeDropDowns()
                        menu.style.display = "block"
                    }
                }

            },
        rightAlign = true,
        id = "scoreText${etut.id}",
        content = {
            RowButton(modifier = Modifier
                .margin(left = 10.px)
                .zIndex(2)
            ) {
                FaCheck(
                    modifier = Modifier
                        .color(if (etut.done) AppColors.Primary.opacity(0.9f)
                        else AppColors.Gray.opacity(0.9f)),
                    size = IconSize.XL,

                    )
            }
        }
    ) {
        Column(modifier = Modifier.fillMaxWidth()
            .zIndex(99),
            horizontalAlignment = Alignment.CenterHorizontally
            ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                SpanText("Doğru: ",
                    modifier = Modifier
                        .fontSize(14.px)
                        .color(AppColors.TextColor))

                BSNumberInput(
                    modifier = Modifier
                        .width(80.px)
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    type = InputType.Number,
                    value = dogruSay.toString(),
                    onValueChange = {
                        dogruSay = it
                    }
                )
            }
            Row(
                modifier = Modifier.margin(bottom = 4.px),
                verticalAlignment = Alignment.CenterVertically) {
                SpanText("Yanlış: ",
                    modifier = Modifier
                        .fontSize(14.px)
                        .color(AppColors.TextColor))

                BSNumberInput(
                    modifier = Modifier
                        .width(80.px)
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    type = InputType.Number,
                    value = yanlisSay.toString(),
                    onValueChange = {
                        yanlisSay = it
                    }
                )
            }

            BSButton(
                modifier = Modifier
                    .fillMaxWidth(),
                text = "Tamamla",
                size = ButtonSize.Small,
                variant = ButtonVariant.Success
            ){
                val net = (dogruSay.toDouble() - (yanlisSay.toDouble() / 3))
                if (etut.soru < net || etut.soru < (dogruSay + yanlisSay)){
                    errText = "Soru sayısından fazla cevap var!"
                    return@BSButton
                }else if ((dogruSay + yanlisSay) <= 0){
                    errText = "Doğru ve yanlış giriniz!"
                    return@BSButton
                }
                etut.net = net
                etut.dogru = dogruSay
                etut.yanlis = yanlisSay
                onComplete()
                closeDropDowns()
            }

            if (errText.isNotEmpty()){
                SpanText(errText,
                    modifier = Modifier
                        .margin(2.px)
                        .fontSize(11.px)
                        .color(AppColors.Red))
            }
        }

    }
}