package com.kelimesoft.etutpro.pages.ders

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.styles.ContexMenuItemStyle
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.viewmodels.DersVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement


@Composable
fun DersColumn(
    dersVM: DersVM,
    dersMap: Map.Entry<WeekDays,
            List<DersProgItem>>,
    onDelete: (DersProgItem) -> Unit,
    onDrop: (Int) -> Unit
) {

    var dragOverBox by remember { mutableStateOf(false) }


    var howered by remember { mutableStateOf(false) }

    Box(
        modifier = Modifier.fillMaxWidth()
            .onMouseOver {
                howered = true
            }
            .onMouseLeave {
                howered = false
            }
            .padding(6.px),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .margin(leftRight = 6.px, bottom = 4.px)
                .padding(6.px)
                .maxWidth(420.px)
        ) {

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(AppColors.AppBarButonColor.opacity(0.8f))
                    .margin(bottom = 6.px)
                    .padding(2.px)
                    .borderRadius(10.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                SpanText(
                    dersMap.key.ad,
                    modifier = Modifier
                        .fontSize(16.px)
                        .textAlign(TextAlign.Center)
                        .color(AppColors.ViewWhite)
                        .padding(3.px)
                        .fontWeight(FontWeight.SemiBold)
                )
                /*
                SmallAddButton {
                    onAdd(dersMap.key.day)
                }*/
            }

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(2.px)
            ) {
                if (dersMap.value.isEmpty()) {
                    Box(modifier = Modifier.fillMaxWidth()
                        .backgroundColor(if (dragOverBox) AppColors.DarkRed.opacity(0.4f) else Colors.Transparent)
                        .height(150.px)
                        .onDrop { dropevt ->
                            dropevt.preventDefault()
                            onDrop(0)
                        }
                        .onDragOver {
                            it.preventDefault()
                            dragOverBox = true
                            //fileExist = true
                        }
                        .onDragLeave {
                            it.preventDefault()
                            dragOverBox = false
                        }
                    ) {

                    }
                } else {
                    dersMap.value.forEachIndexed { index, ders ->
                        DersColRow(ders, last = index == dersMap.value.size - 1, onDrag = {
                            dersVM.draggedDersItem = null
                            dersVM.draggedDersPro = ders

                        }, onDrop = { pos ->
                            if (pos == DropPos.Top) {
                                onDrop(index)
                            } else {
                                onDrop(index + 1)
                            }
                            //onEdit(ders)
                        }, onDelete = {
                            onDelete(ders)
                        })
                        if (index == dersMap.value.size - 1) {
                            Box(modifier = Modifier.height(60.px)) { }
                        }

                    }
                }

            }


        }
        /*
        if (dersVM.copiedDers != null && howered) {
            Box(
                modifier = Modifier.fillMaxSize()
                    .borderRadius(r = 8.px)
                    .zIndex(3)
                    .backgroundColor(AppColors.Gray.opacity(0.2f)),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Top
                ) {
                    BSButton(
                        modifier = Modifier
                            .margin(top = 60.px)
                            .opacity(60.percent),
                        text = "Yapıştır",
                        variant = ButtonVariant.PrimaryOutline
                    ) {
                        onPaste()
                    }
                    BSButton(
                        modifier = Modifier
                            .margin(top = 30.px)
                            .opacity(60.percent),
                        text = "İptal",
                        variant = ButtonVariant.SecondaryOutline
                    ) {

                    }
                }

            }
        }
        */
    }
}

@Composable
fun DersColRow(
    ders: DersProgItem, last: Boolean,
    onDrag: () -> Unit,
    onDrop: (DropPos) -> Unit,
    onDelete: () -> Unit
) {

    var dragOverUp by remember { mutableStateOf(false) }
    var dragOverDown by remember { mutableStateOf(false) }

    Box(modifier = Modifier.fillMaxWidth()
        .onContextMenu {
            //it.stopPropagation()
            it.preventDefault()
            val menu = document.getElementById("drop${ders.id}") as HTMLDivElement
            if (menu.style.display == "block") {
                menu.style.display = "none"
            } else {
                Funcs.closeDropDowns()
                menu.style.display = "block"
            }
        }
        .draggable(Draggable.True)
        .onDragStart {
            onDrag()
        }
        .minHeight(36.px)
        .maxHeight(60.px),
        contentAlignment = Alignment.Center
    ) {
        DersProgMenu(ders, onDelete = {
            onDelete()
        })

        Column(
            modifier = Modifier.fillMaxSize()
                .zIndex(1)
        ) {
            Box(
                modifier = Modifier.fillMaxWidth()
                    .height(16.px)
                    .backgroundColor(if (dragOverUp) AppColors.DarkRed.opacity(0.4f) else Colors.Transparent)
                    .onDrop { dropevt ->
                        dropevt.preventDefault()
                        onDrop(DropPos.Top)
                        dragOverUp = false
                    }
                    .onDragOver {
                        it.preventDefault()
                        dragOverUp = true
                        //fileExist = true
                    }
                    .onDragLeave {
                        it.preventDefault()
                        dragOverUp = false
                    }
            ) {}

            Spacer()

            if (last) {
                Box(
                    modifier = Modifier.fillMaxWidth()
                        .height(16.px)
                        .backgroundColor(if (dragOverDown) AppColors.DarkRed.opacity(0.4f) else Colors.Transparent)
                        .onDrop { dropevt ->
                            dropevt.preventDefault()
                            onDrop(DropPos.Bottom)
                            dragOverDown = false
                        }
                        .onDragOver {
                            it.preventDefault()
                            dragOverDown = true
                            //fileExist = true
                        }
                        .onDragLeave {
                            it.preventDefault()
                            dragOverDown = false
                        }
                ) { }
            }
        }

        /*
        if (showEdit) {
            RowButton {
                FaEdit(
                    modifier = Modifier.color(AppColors.Gray.opacity(0.9f))
                        .onClick {
                            onEdit()
                        },
                    size = IconSize.SM
                )
            }
        }*/


    }


}

enum class DropPos {
    Top, Bottom
}


@Composable
fun DersProgMenu(
    ders: DersProgItem,
    onDelete: () -> Unit

) {
    var isDragging by remember { mutableStateOf(false) }
    Div(
        attrs = Modifier.fillMaxWidth()
            .position(Position.Relative)
            .display(DisplayStyle.InlineBlock)
            .toAttrs()
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            SpanText(
                ders.dersad,
                modifier = Modifier
                    .fillMaxWidth()
                    .fontFamily(Values.FONT_TOHOMA)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(16.px)
                    .backgroundColor(Colors.White.opacity(if (isDragging) 0.4f else 1.0f))
                    .padding(3.px)
                    .borderRadius(r = 3.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.colorDersList[ders.renk])
                /*
                .onDrag {
                    if (!isDragging) isDragging = true
                }
                .onDragEnd {
                    if (isDragging) isDragging = false
                }*/
                /*
                .onContextMenu {
                    //it.stopPropagation()
                    it.preventDefault()
                    val menu = document.getElementById("drop${ders.id}") as HTMLDivElement
                    if (menu.style.display == "block") {
                        menu.style.display = "none"
                    } else {
                        Funcs.closeDropDowns()
                        menu.style.display = "block"
                    }
                },*/

            )
        }

        Div(
            attrs = Modifier
                .id("drop${ders.id}")
                .classNames("app-menu")
                .display(DisplayStyle.None)
                .position(Position.Absolute)
                .styleModifier {
                    property("right", "0")
                }
                .backgroundColor(AppColors.DropMenu)
                .borderRadius(r = 6.px)
                .boxShadow(0.px, 8.px, 16.px, 0.px, Color.rgba(0, 0, 0, 0.2f))
                .minWidth(120.px)
                //.maxWidth(200.px)
                .padding(10.px)
                .zIndex(2)
                .toAttrs()
        ) {
            Column {
                SpanText("Sil", modifier = ContexMenuItemStyle
                    .toModifier()
                    .onClick {
                        onDelete()
                    })

            }

        }
    }
}

@Composable
fun DersItemMenu(
    ders: DersItem,
    onEdit: () -> Unit,
) {
    var isDragging by remember { mutableStateOf(false) }

    Div(
        attrs = Modifier.fillMaxWidth()
            .position(Position.Relative)
            .display(DisplayStyle.InlineBlock)
            .toAttrs()
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            SpanText(
                ders.dersad,
                modifier = Modifier
                    .fillMaxWidth()
                    .fontFamily(Values.FONT_TOHOMA)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(18.px)
                    .backgroundColor(Colors.White.opacity(if (isDragging) 0.4f else 1.0f))
                    .padding(4.px)
                    .borderRadius(r = 3.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.colorDersList[ders.renk])
                    .onDrag {
                        if (!isDragging) isDragging = true
                    }
                    .onDragEnd {
                        if (isDragging) isDragging = false
                    }
                /*
                .onContextMenu {
                    //it.stopPropagation()
                    it.preventDefault()
                    val menu = document.getElementById("drop-ders${ders.id}") as HTMLDivElement
                    if (menu.style.display == "block") {
                        menu.style.display = "none"
                    } else {
                        Funcs.closeDropDowns()
                        menu.style.display = "block"
                    }
                },*/

            )
        }

        Div(
            attrs = Modifier
                .id("drop-ders${ders.id}")
                .classNames("app-menu")
                .display(DisplayStyle.None)
                .position(Position.Absolute)
                .styleModifier {
                    property("right", "0")
                }
                .backgroundColor(AppColors.DropMenu)
                .borderRadius(r = 6.px)
                .boxShadow(0.px, 8.px, 16.px, 0.px, Color.rgba(0, 0, 0, 0.2f))
                .minWidth(120.px)
                //.maxWidth(200.px)
                .padding(10.px)
                .zIndex(4)
                .toAttrs()
        ) {
            Column {
                SpanText("Edit", modifier = ContexMenuItemStyle
                    .toModifier()
                    .onClick {
                        onEdit()
                    })
                /*

                if (ders.sid < 1){
                    VDivider(modifier = Modifier.margin(bottom = 1.px))
                    if (ders.sid == 0L) {
                        SpanText("Sil", modifier = ContexMenuItemStyle
                            .toModifier()
                            .onClick {
                                onDelete()
                            })
                    }
                }*/
            }

        }
    }
}


@Composable
fun DersItemView(
    ders: DersItem,
    onDrag: () -> Unit,
    onEdit: () -> Unit,
) {

    Box(modifier = Modifier.fillMaxWidth()
        .onContextMenu {
            //it.stopPropagation()
            it.preventDefault()
            val menu = document.getElementById("drop-ders${ders.id}") as HTMLDivElement
            if (menu.style.display == "block") {
                menu.style.display = "none"
            } else {
                Funcs.closeDropDowns()
                menu.style.display = "block"
            }
        }
        .draggable(Draggable.True)
        .onDragStart {
            onDrag()
        }
        .padding(leftRight = 2.px)
        .height(36.px),
        contentAlignment = Alignment.Center
    ) {
        DersItemMenu(ders, onEdit = {
            onEdit()
        })

    }


}


