package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.Values
import com.kelimesoft.etutpro.utils.Funcs
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px

@Composable
fun PageBox(modifier: Modifier = Modifier,
            content: @Composable BoxScope.() -> Unit = {}
) {
    val breakpoint = rememberBreakpoint()
    Box(modifier = Modifier
        .id("page-box")
        .position(Position.Fixed)
        .top(0.px)
        .left(0.px)
        .fillMaxSize()
        .fontFamily(Values.FONT_FAMILY)
        .backgroundColor(AppColors.Background)
        .onClick {
            Funcs.closeDropDowns()
        }
        .padding(
            topBottom = if(breakpoint<= Breakpoint.MD) 2.px else 10.px,
            leftRight = if(breakpoint<=Breakpoint.MD) 2.px else 10.px)
        .then(modifier),
        contentAlignment = Alignment.Center
    ) {

        content()
    }
}