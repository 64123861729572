package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.AlertModel
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.AppData
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun SinifaKatilDialog(
    onClose: () -> Unit,
    onDone: () -> Unit
) {
    val etutVM = AppData.ViewModel.etutVM
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    var snfAd by remember { mutableStateOf("") }
    var girisCode by remember { mutableStateOf("") }
    var alertModel: AlertModel? by remember { mutableStateOf(null) }
    val scope = rememberCoroutineScope()
    val user = AppData.appUser

    val ogrPair: Pair<String, Int> = if (user.role == 0) Pair(user.uuid, user.snf)
    else Pair(ogrenciVM.selectedOgrenci!!.uuid, ogrenciVM.selectedOgrenci!!.snf)


    DialogView {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .maxWidth(500.px)
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .padding(4.px)
                .borderRadius(r = 10.px)
                .backgroundColor(Colors.White)
        ) {
            DialogTitle("Sınıfa katıl", fontSize = 15, onClose = onClose)

            Form(
                attrs = Modifier
                    .fillMaxWidth()
                    .padding(topBottom = 8.px, leftRight = 4.px)
                    .id("sinif-form")
                    .toAttrs()
            ) {


                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = snfAd,
                    placeholder = "Sınıfın adını giriniz.",
                    required = true,
                    onValueChange = {
                        snfAd = it
                    }
                )



                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = girisCode,
                    placeholder = "Sınıf Giriş Kodu",
                    required = true,
                    onValueChange = {
                        girisCode = it
                    }
                )

                Row(horizontalArrangement = Arrangement.SpaceBetween) {
                    BSButton(
                        text = "Kapat",
                        variant = ButtonVariant.DangerOutline,
                        onClick = {
                            onClose()
                        }
                    )
                    BSButton(
                        text = "Katıl",
                        variant = ButtonVariant.Success,
                        onClick = {
                            scope.launch {
                                val res = etutVM.snfKatil(ogrPair.first, ogrPair.second, snfAd, girisCode)
                                if (res.result) {
                                    //ogrenciVM.getOgrenciList(true)
                                    onDone()
                                } else {
                                    alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                    delay(3000)
                                    alertModel = null
                                }
                            }

                        }
                    )
                }
                VDivider(modifier = Modifier.margin(topBottom = 8.px))

                Column(modifier = Modifier.fillMaxWidth()) {
                    Span(
                        attrs = Modifier
                            .color(AppColors.Red)
                            .fontSize(14.px).toAttrs()
                    ) {
                        Text("Sınıfa katılabilmeniz için, Öğretmeninizin gönderdiği ")
                        Span(
                            attrs = Modifier
                                .color(AppColors.DarkRed)
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) {
                            Text("SINIF ADINI")
                        }
                        Text(" ve ")
                        Span(
                            attrs = Modifier
                                .color(AppColors.DarkRed)
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) {
                            Text("SINIF GİRİŞ KODUNU")
                        }
                        Text(" giriniz.")

                    }
                }

            }
        }


        if (alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style

            )
        }


    }
}

