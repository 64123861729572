package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.etut.AddNewEtut
import com.kelimesoft.etutpro.pages.etut.AddVideoEtut
import com.kelimesoft.etutpro.pages.etut.EtutDayBox
import com.kelimesoft.etutpro.pages.etut.EtutSearch
import com.kelimesoft.etutpro.pages.kaynak.AddNewKaynak
import com.kelimesoft.etutpro.pages.kaynak.EtutKaynakList
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.icons.BSIcons
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent

@Composable
fun OgrenciEtut(uuid: String) {

    val etutVM = AppData.ViewModel.etutVM
    val dersVM = AppData.ViewModel.dersVM
    val scope = rememberCoroutineScope()
    var selectedEtutDate: String by remember { mutableStateOf("") }

    var showAddEtut by remember { mutableStateOf(false) }
    var showAddVideoEtut by remember { mutableStateOf(false) }
    var showAddKaynak by remember { mutableStateOf(false) }

    var etutType: EtutType? = null
    var secilenEtut: EtutItem? = null

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    var draggedEtut: EtutItem? by remember { mutableStateOf(null) }
    var draggedKaynak: DersKaynak? by remember { mutableStateOf(null) }
    var dragKaynakType: KaynakType? by remember { mutableStateOf(null) }



    val escListener = remember {
        EventListener {
            it as KeyboardEvent
            if (it.keyCode == 27) {
                if (etutVM.copiedEtut != null
                    || etutVM.etutSelectForCopy != null
                ) {
                    etutVM.copiedEtut = null
                    etutVM.etutSelectForCopy = null
                }

            }
        }
    }

    fun saveDrag(it: EtutItem, tarih: String, saat: String) {
        if (it.tarih == tarih && it.saat == saat) {
            draggedEtut = null
            return
        }
        val newSaat = saat.ifEmpty { it.saat }
        scope.launch {
            val updatedEtutItem = it.copy(
                saat = newSaat,
                tarih = tarih,
                done = false
            )
            etutVM.editEtutWithDrag(updatedEtutItem)
        }
    }
    LaunchedEffect(true){
        document.addEventListener(type = "keydown", escListener)
    }
    LaunchedEffect(uuid, etutVM.weekStartDate) {
        dersVM.getDersList(uuid)
        etutVM.getEtutList(uuid)
    }

    DisposableEffect(true) {
        onDispose {
            document.removeEventListener("keydown", escListener)
        }
    }

    Box(modifier = Modifier.fillMaxSize()) {
        Row(
            modifier = Modifier.fillMaxSize()
                .overflow(Overflow.Hidden),
        ) {
            EtutKaynakList(etutVM, uuid, onKaynak = {
                showAddKaynak = true

            }, onAddDers = {

            }, onDrag = {
                draggedEtut = null
                draggedKaynak = it
            }, onDragType = {
                draggedEtut = null
                dragKaynakType = it
            })

            Column(
                modifier = Modifier.fillMaxSize()
                    //.weight(1f)
                    .thenIf(
                        etutVM.copiedEtut != null
                                || etutVM.etutSelectForCopy != null
                    ) {
                        //eğer kopyalama için seçilen item varsa boş yere tıklayınca iptal edilsin
                        Modifier.onClick {
                            etutVM.copiedEtut = null
                            etutVM.etutSelectForCopy = null
                        }
                    }
                    .overflow(Overflow.Hidden),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(top = 1.px)
                        .backgroundColor(Colors.White.opacity(0.9f))
                        .borderRadius(r = 4.px)
                        .padding(2.px)
                    ,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    BSButton(
                        text = "Önceki",
                        size = ButtonSize.Small,
                        icon = BSIcons.CHEVRON_LEFT,
                        variant = ButtonVariant.PrimaryOutline
                    ){
                        scope.launch {
                            etutVM.gotoPreviousWeek()
                        }
                    }


                    Spacer()
                    EtutSearch()
                    Spacer()

                    BSButton(
                        text = "Sonraki",
                        size = ButtonSize.Small,
                        icon = BSIcons.CHEVRON_RIGHT,
                        variant = ButtonVariant.PrimaryOutline
                    ){
                        scope.launch {
                            etutVM.gotoNextWeek()
                        }
                    }

                }
                Column(modifier = Modifier.fillMaxSize()
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                    .scrollBehavior(ScrollBehavior.Smooth)
                    .styleModifier {
                        property("scrollbar-width", "thin")
                    }
                ) {
                    SimpleGrid(
                        modifier = Modifier.fillMaxWidth(),

                        numColumns = numColumns(base = 1, md = 2, lg = 3, xl = 5)
                    ) {
                        etutVM.weekDays.forEachIndexed { index, tarih ->
                            EtutDayBox(etutVM, scope, index, tarih,
                                onAdd = { typ ->
                                    etutType = typ
                                    secilenEtut = null
                                    draggedKaynak = null
                                    selectedEtutDate = tarih

                                    if (typ == EtutType.Video) {
                                        showAddVideoEtut = true
                                    } else {
                                        showAddEtut = true
                                    }


                                }, onEdit = { etut ->
                                    etutType = null
                                    selectedEtutDate = etut.tarih
                                    secilenEtut = etut
                                    draggedKaynak = null
                                    if (etut.type == 5) {
                                        showAddVideoEtut = true
                                    } else {
                                        showAddEtut = true
                                    }


                                }, onDrag = {
                                    draggedKaynak = null
                                    draggedEtut = it
                                }, onDrop = {
                                    if (draggedEtut != null) {
                                        //newEtutSaat = newSaat
                                        saveDrag(draggedEtut!!, tarih, etutVM.etutDragSaat)
                                        draggedEtut = null
                                    } else if (draggedKaynak != null) {
                                        etutType = null
                                        secilenEtut = null
                                        selectedEtutDate = tarih
                                        //newEtutSaat = newSaat
                                        showAddEtut = true
                                    } else if (dragKaynakType != null) {
                                        selectedEtutDate = tarih
                                        showAddVideoEtut = true
                                    }
                                })
                        }
                    }
                }
            }
        }



    }

    if (showAddEtut) {
        DialogView {
            AddNewEtut(
                typ = etutType,
                etut = secilenEtut,
                kaynak = draggedKaynak,
                date = selectedEtutDate,
                saat = etutVM.etutDragSaat,
                onKaynak = {
                    showAddKaynak = true
                },
                onClose = {
                    showAddEtut = false
                },
                onEdit = { etutItem, docs ->
                    scope.launch {
                        var docList = mutableListOf<String>()
                        docs.sortedBy { it.order }.forEach {
                            docList.add(it.name)
                        }
                        etutVM.editExistingEtut(etutItem, docList)
                    }
                }, onAdd = { newEtutItem, docs, uuids ->
                    var docList = mutableListOf<String>()
                    docs.sortedBy { it.order }.forEach {
                        docList.add(it.name)
                    }
                    scope.launch {
                        val res = etutVM.addNewEtut(newEtutItem, uuid, docList, uuids)
                        if (res.result) {
                            showAddEtut = false
                            alertModel = AlertModel("Yeni etüt eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                            delay(3000)
                            alertModel = null
                        } else {
                            alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                            delay(3000)
                            alertModel = null
                        }
                    }
                })
        }
    }

    if (showAddVideoEtut) {
        DialogView {
            AddVideoEtut(
                etut = secilenEtut,
                date = selectedEtutDate,
                saat = etutVM.etutDragSaat,
                onClose = {
                    showAddVideoEtut = false
                }, onEdit = { etut ->

                }, onAdd = {netut, list ->

                }
            )


        }
    }


    if (showAddKaynak) {
        DialogView {
            AddNewKaynak(
                onClose = {
                    showAddKaynak = false
                }, onAdded = {
                    showAddKaynak = false
                })
        }
    }

    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style
        )
    }

}