package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.etutpro.components.FaEdit
import com.kelimesoft.etutpro.components.MyFaIcon
import com.kelimesoft.etutpro.components.RowButton
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.models.AppData.ViewModel.ogrenciVM
import com.kelimesoft.etutpro.pages.EtutList
import com.kelimesoft.etutpro.utils.*
import com.kelimesoft.etutpro.viewmodels.EtutVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLElement
import kotlin.js.Date


private val todayDate = Date()

@Composable
fun EtutDayBox(
    etutVM: EtutVM,
    scope: CoroutineScope,
    index: Int, tarih: String,
    onAdd: (EtutType) -> Unit,
    onEdit: (EtutItem) -> Unit,
    onDrag: (EtutItem) -> Unit,
    onDrop: () -> Unit
) {

    val etutDate = Date(tarih)
    val prevDate = todayDate.addDays(-6)
    val canAcceptDrop = etutDate.isAfter(prevDate)
    val showAddButton = etutDate.isAfter(todayDate.addDays(-4))
    var dragOver by remember { mutableStateOf(false) }
    val days = listOf("Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar")
    //var showDeleteEtut: EtutItem? by remember { mutableStateOf(null) }

    var howered by remember { mutableStateOf(false) }

    var lastDragId by remember { mutableStateOf("") }

    var dayTotalPuan by remember { mutableStateOf(0) }
    var dayDonePuan by remember { mutableStateOf(0) }

    var showTestGir by remember { mutableStateOf(false) }

    val columnId = "day-box-$tarih"

    LaunchedEffect(etutVM.etutListMap[tarih]){
        delay(100)
        /*
        val element = document.getElementById(columnId) as? HTMLElement
        if (element != null) {
            val rect = element.getBoundingClientRect()
            //saatXpos = rect.left
            //saatYpos = rect.top
        }*/

        var dayP = 0
        var doneP = 0.0
        etutVM.etutListMap[tarih]?.forEach { et ->
            if (et.type == EtutType.Deneme.type || et.type == EtutType.KonuTest.type){
                if (et.soru > 0) dayP += et.soru
                if (et.done && et.net > 0) doneP += et.net
            }else{
                if (et.puan > 0) dayP += et.puan
                if (et.done && et.puan > 0) doneP += et.puan
            }
        }
        dayTotalPuan = dayP
        dayDonePuan = doneP.toInt()

    }



    fun getHourAndMinute(minutes: Int): String {
        var newHours = 4 + (minutes / 60)
        var newMinutes = minutes % 60
        //en yakın 5 dk ayarı
        newMinutes = ((newMinutes + 2) / 5) * 5
        if (newMinutes == 60) {
            newMinutes = 0
            newHours += 1
        }
        newHours %= 24
        return "${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}"
    }


    fun setNewSaatDataoOnDrag(dragEvt: SyntheticDragEvent){
        val element = document.getElementById(columnId) as? HTMLElement
        if (element != null) {
            val rect = element.getBoundingClientRect()
            val columnHeight = rect.height
            val yPosition = dragEvt.clientY - rect.top // + dragoffset
            val clampedYPosition = yPosition.coerceIn(0.0, columnHeight)
            // Calculate the position ratio
            val positionRatio = clampedYPosition / columnHeight
            //saatYpos = clampedYPosition + 50
            //gündeki dk
            val minutesSinceStart = (positionRatio * 1200).toInt()
            etutVM.etutDragSaat = getHourAndMinute(minutesSinceStart)
        }

    }


    Box(modifier = Modifier
        .onMouseOver {
            howered = true
        }
        .onMouseLeave {
            howered = false
        }
        .fillMaxWidth()
        .padding(topBottom = 2.px, leftRight = 5.px),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(modifier = Modifier
            .position(Position.Relative)
            .fillMaxWidth()
            //.boxShadow(1.px,1.px,1.px,1.px,
            //  color = Colors.DarkBlue.opacity(0.4f))
            //.backgroundColor(Colors.White.opacity(0.9f))
            .id(columnId)
            .borderRadius(r = 4.px)
            .minHeight(120.px)
            .maxWidth(460.px)
            .thenIf(dragOver) {
                Modifier.backgroundColor(Colors.Blue.opacity(0.2f))
            }
            .thenIf(canAcceptDrop) {
                Modifier
                    .onDrop { dropevt ->
                        dropevt.preventDefault()
                        onDrop()
                        dragOver = false
                        //draggedYOffset = 0.0
                    }
                    .onDragOver {
                        it.preventDefault()
                        dragOver = true
                        setNewSaatDataoOnDrag(dragEvt = it)

                        //fileExist = true
                    }
                    .onDragLeave {
                        it.preventDefault()
                        dragOver = false
                        etutVM.etutDragSaat = ""
                        //draggedYOffset = 0.0
                    }




            }
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(
                        if (index > 4) AppColors.WeekEndColor.opacity(0.7f) else
                            AppColors.AppBarButonColor.opacity(0.8f)
                    )
                    .margin(bottom = 2.px, top = 4.px)
                    .padding(2.px)
                    .borderRadius(6.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.ViewWhite),

                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                //günlük test sayısı
                Span(attrs = Modifier
                    .fontSize(13.px)
                    .textAlign(TextAlign.Center)
                    .color(Colors.White)
                    .padding(left = 4.px)
                    .toAttrs()
                ) {
                    Text(dayTotalPuan.toString())
                    Text("/")

                        Span(attrs = Modifier
                            .color(Colors.LawnGreen)
                            .toAttrs()
                        ) {
                            Text(dayDonePuan.toString())
                        }

                }

                Column(
                    modifier = Modifier.fillMaxWidth()
                        .thenIf(canAcceptDrop) {
                            Modifier
                                .onPaste {
                                    scope.launch {
                                        var uuid: String? = null
                                        if (AppData.appUser.role > 0) {
                                            ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                                        }
                                        etutVM.copyExistingEtut(tarih, uuid)
                                    }
                                }
                        },
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    SpanText(
                        days[index],
                        modifier = Modifier
                            .textAlign(TextAlign.Center)
                            .color(Colors.White)
                            .padding(leftRight = 4.px)
                    )

                    SpanText(
                        Date(tarih).toLocaleDateString(),
                        modifier = Modifier
                            .fontSize(12.px)
                            .textAlign(TextAlign.Center)
                            .color(Colors.White.opacity(0.7f))
                    )
                }
                if (showAddButton) {
                    EtutAddMenu(tarih, onSelect = {
                        onAdd(it)
                    })
                }else {
                    Box(modifier = Modifier.width(24.px)) {  }
                }
                /*
                SmallAddButton {
                    onAdd()
                }*/
            }

            etutVM.etutListMap[tarih]?.sortedBy { it.saat }?.forEach { etut ->
                if (etut.type == EtutType.Video.type
                    || etut.link.contains("youtube")
                    || etut.link.contains("https://youtu")
                    ){
                    EtutListVideo(etutVM, etut,
                        onEdit = {
                            onEdit(etut)
                        }, onDelete = {
                            scope.launch {
                                etutVM.etutDelete(etut)
                            }
                        }, onComplete = {
                            scope.launch {
                                etutVM.etutDone(etut)
                            }
                        }, dragStart = { dragId ->
                            lastDragId = dragId
                            onDrag(etut)
                        })
                }else{
                    EtutListRow(etutVM, etut,
                        onEdit = {
                            onEdit(etut)
                        }, onDelete = {
                            scope.launch {
                                etutVM.etutDelete(etut)
                            }
                        }, onComplete = {
                            scope.launch {
                                etutVM.etutDone(etut)
                            }
                        }, dragStart = { dragId ->
                            lastDragId = dragId
                            onDrag(etut)
                        }, onCevap = {
                            etutVM.textGirEtutItem = etut
                            showTestGir = true
                        })
                }
            }
        }

        if (canAcceptDrop && etutVM.copiedEtut != null && howered) {
            Box(
                modifier = Modifier.fillMaxSize()
                    .borderRadius(r = 8.px)
                    .backgroundColor(AppColors.Gray.opacity(0.2f)),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Top
                ) {
                    BSButton(
                        modifier = Modifier
                            .margin(top = 60.px)
                            .opacity(60.percent),
                        text = "Yapıştır",
                        variant = ButtonVariant.PrimaryOutline
                    ) {
                        scope.launch {
                            var uuid: String? = null
                            if (AppData.appUser.role > 0) {
                                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                            }
                            etutVM.copyExistingEtut(tarih, uuid)
                        }
                    }
                    BSButton(
                        modifier = Modifier
                            .margin(top = 30.px)
                            .opacity(60.percent),
                        text = "İptal",
                        variant = ButtonVariant.SecondaryOutline
                    ) {
                        etutVM.etutSelectForCopy = null
                        etutVM.copiedEtut = null
                    }
                }

            }
            /*
            RowButton {
                FaPaste(
                    modifier = Modifier.color(AppColors.Gray.opacity(0.9f))
                        .onClick {


                        },
                    size = IconSize.SM
                )
            }*/
        }


        if(dragOver){
            Box(
                modifier = Modifier
                    .margin(top = (-30).px)
                    .position(Position.Absolute)
                    .padding(leftRight = 16.px, topBottom = 4.px)
                    .backgroundColor(AppColors.DarkRed)
                    .color(Colors.White)
                    .fontSize(16.px)
                    .borderRadius(4.px)
                    .padding(6.px)
                    .zIndex(999)
            ) {
                Text(etutVM.etutDragSaat)
            }
        }

        if (showTestGir) {
            TestSonucuGir(etutVM, onClose = {
                showTestGir = false
                console.log(etutVM.textGirEtutItem!!.usercevap)
            }, onComplete = {
                scope.launch {
                    val  res = etutVM.etutDoneWithCevap()
                    if (res.result){
                        showTestGir = false
                    }else{

                    }

                }
            })
        }



    }
}


@Composable
fun EtutDeleteOnay(onCancel: () -> Unit, onDelete: () -> Unit) {
    Box(modifier = Modifier.fillMaxWidth()
        .color(AppColors.ViewWhite)
        .padding(8.px)) {
        Column(
            modifier = Modifier
                .zIndex(91)
                .fillMaxWidth()
                .boxShadow(1.px, 1.px, 1.px, 1.px, color = Colors.DarkBlue.opacity(0.4f))
                .backgroundColor(AppColors.GrayDark)
                .padding(4.px)
                .borderRadius(r = 4.px)
        ) {
            Span(
                attrs = Modifier
                    .fontSize(15.px)
                    .margin(topBottom = 4.px)
                    .toAttrs()
            ) {
                Text("Etüt görevi silinecek?")
            }

            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                BSButton(
                    text = "İptal",
                    variant = ButtonVariant.SecondaryOutline,
                    size = ButtonSize.Small
                ) {
                    onCancel()
                }

                BSButton(
                    text = "Sil",
                    variant = ButtonVariant.Danger,
                    size = ButtonSize.Small
                ) {
                    onDelete()

                }
            }

        }
    }

}

