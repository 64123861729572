package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.*
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.utils.dateToDbStr
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData


class DersVM: BaseVM() {
    var dersList by mutableStateOf(setOf<DersItem>())

    var dersProgramList = listOf<DersProgItem>()
    var daysMapList by mutableStateOf(mapOf<WeekDays,List<DersProgItem>>())

    var draggedDersPro: DersProgItem? = null

    var draggedDersItem: DersItem? = null

    suspend fun getDersList(uuid: String? = null) {
        if (dersList.isEmpty()){
            RestApi.getDersList(uuid)?.let { res ->
                Json.decodeFromString<DersListResponse>(res).let { dres ->
                    if (dres.data != null) {
                        dersList = dres.data.toSet()
                    }
                }
            }
        }
    }


    suspend fun deleteDersItem(ders: DersItem, uuid: String? = null): FuncResult {
        RestApi.deleteDersItem(ders, uuid)?.let { res ->
            Json.decodeFromString<DersListResponse>(res).let { dres ->
                if (dres.data != null) {
                    dersList = dres.data.toSet()
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }


    suspend fun getDersProgram(uuid: String? = null) {
        if (dersProgramList.isEmpty()){
            RestApi.getDersProgram(uuid)?.let { res ->
                Json.decodeFromString<DersProgResponse>(res).let { dres ->
                    if (dres.data != null) {
                        dersProgramList = dres.data
                        prepareDaysList()
                    }
                }
            }
        }
    }

    /*
    suspend fun pasteCopiedDers(gun: Int, uuid: String? = null){
        copiedDers?.let {
            try {
                val newDers = DersItem(id = 0, did = 0, dersad = it.dersad, renk = it.renk)
                RestApi.addNewDers(newDers, uuid)?.let { res ->
                    Json.decodeFromString<DersListResponse>(res).let { dres ->
                        if (dres.data != null) {
                            dersList = dres.data.toSet()
                            prepareDaysList()
                        }
                    }
                }
            }finally {
                copiedDers = null
            }

        }
    }*/


    suspend fun addNewDers(dersItem: DersItem, uuid: String? = null): FuncResult {
        if (!Funcs.checkForm("ders-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        RestApi.addNewDers(dersItem, uuid)?.let { res ->
            Json.decodeFromString<DersListResponse>(res).let { dres ->
                if (dres.data != null) {
                    dersList = dres.data.toSet()
                    prepareDaysList()
                    return FuncResult(true)
                } else if (dres.error != null) {
                    return FuncResult(false, dres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun deleteDersPro(dersPro: DersProgItem, uuid: String? = null): FuncResult {
        RestApi.deleteDersPro(dersPro, dersPro.gun, dersPro.sira, uuid)?.let { res ->
            Json.decodeFromString<DersProgResponse>(res).let { dres ->
                //qconsole.log(dres)
                if (dres.data != null) {
                    dersProgramList = dres.data
                    prepareDaysList()
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun updateDraggedDers(sira: Int, gun: Int, uuid: String? = null): FuncResult {
        draggedDersPro?.let { dders ->
            if (dders.sira == sira && dders.gun == gun){
                return FuncResult(true)
            }
            RestApi.updateDersSira(gun, sira, dders, uuid)?.let { res ->
                Json.decodeFromString<DersProgResponse>(res).let { dres ->
                    if (dres.data != null) {
                        dersProgramList = dres.data
                        prepareDaysList()
                        return FuncResult(true)
                    } else if (dres.error != null) {
                        return FuncResult(false, dres.error)
                    }
                }
                draggedDersPro = null
            }
            return FuncResult(false, "Connection Error")
        }
        return FuncResult(true)
    }


    suspend fun addDersFromDrag(sira: Int, gun: Int, uuid: String? = null): FuncResult {
        draggedDersItem?.let { ders ->
            RestApi.addNewDersProg(ders.id, gun, sira, uuid)?.let { res ->
                Json.decodeFromString<DersProgResponse>(res).let { dres ->
                    if (dres.data != null) {
                        dersProgramList = dres.data
                        prepareDaysList()
                        return FuncResult(true)
                    } else if (dres.error != null) {
                        return FuncResult(false, dres.error)
                    }
                }
                draggedDersItem = null
            }
            return FuncResult(false, "Connection Error")
        }
        return FuncResult(true)
    }


    private fun prepareDaysList(){
        val day1 = arrayListOf<DersProgItem>()
        val day2 = arrayListOf<DersProgItem>()
        val day3 = arrayListOf<DersProgItem>()
        val day4 = arrayListOf<DersProgItem>()
        val day5 = arrayListOf<DersProgItem>()

        dersProgramList.forEach { item ->
            if (item.gun == 1){
                day1.add(item)
            }else if (item.gun == 2){
                day2.add(item)
            }else if (item.gun == 3){
                day3.add(item)
            }else if (item.gun == 4){
                day4.add(item)
            }else if (item.gun == 5){
                day5.add(item)
            }
        }

        daysMapList = mapOf(
            WeekDays.Pazartesi to day1.sortedBy { it.sira },
            WeekDays.Sali to day2.sortedBy { it.sira },
            WeekDays.Carsamba to day3.sortedBy { it.sira },
            WeekDays.Persembe to day4.sortedBy { it.sira },
            WeekDays.Cuma to day5.sortedBy { it.sira },
        )

    }

}