package com.kelimesoft.etutpro.viewmodels


import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.*
import com.kelimesoft.etutpro.pages.sinif.SnfPage
import com.kelimesoft.etutpro.utils.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import kotlin.js.Date

class SinifVM: BaseVM(){
    var today = Date()
    var etutStartDate = Funcs.getWeekStartDay(today)

    var raporStartDate = Funcs.getWeekStartDay(today)

    var weekDays: List<String> by mutableStateOf(makeWeekDayList(etutStartDate))

    var selectedPeriod = SnfPeriod.Weekly

    var aktifPage: SnfPage by mutableStateOf(SnfPage.Ozet)
    var snfId: Long by mutableStateOf(0L)
    var snfList: List<SnfUserData>? = null

    var selectedSnf: SnfInfo? = null
    var snfReportData: SnfReportsData? = null
    var snfListMap by mutableStateOf(mapOf<SnfInfo, List<SnfUserData>>())
    var selectedOgr: OgrenciInfo? by mutableStateOf(null)

    //Snf Etüt
    var snfEtutList: List<SnfEtutItem> = listOf()
    var etutListMap by mutableStateOf(mapOf<String, List<SnfEtutItem>>())
    var etutListDersMap by mutableStateOf(mapOf<String, List<SnfEtutItem>>())

    //Snf Kaynak
    var allKaynakList: List<SnfKaynak> = listOf()
    var kaynakMap by mutableStateOf(mapOf<String, List<SnfKaynak>>())
    var secilenKaynakDersAdi: String? = null
    var secilenDersKaynak: SnfKaynak? = null

    var snfDersList by mutableStateOf(setOf<DersItem>())


    var snfEtutSelected: SnfEtutItem? by mutableStateOf(null)
    var etutDragSaat by mutableStateOf("")
    var kaynakDersOver by mutableStateOf("")

    var scoresByUuidAndName: Map<Pair<String, String>, List<AccumulatedReport>> by  mutableStateOf(mapOf())
    var uniqueDersNames: Set<String> by  mutableStateOf(setOf())
    var scoresMapByDersKonu:  Map<String, Map<String, Map<String, Any>>> by mutableStateOf(mapOf())

    var loading by mutableStateOf(false)

    var cevapCharSet = setOf('a', 'b', 'c', 'd', 'e')


    suspend fun getSnfData() {
        if (snfList == null) {
            RestApi.getSnfData()?.let { res ->
                Json.decodeFromString<SnfListResponse>(res).let { sres ->
                    if (sres.data != null) {
                        snfList = sres.data
                        snfListMap = snfList!!.groupBy { it.snf }
                        snfList?.firstOrNull()?.let {
                            snfId = it.snf.id
                            if (it.snf.snf <= 8){
                                cevapCharSet = setOf('a', 'b', 'c', 'd')
                            }else{
                                cevapCharSet = setOf('a', 'b', 'c', 'd', 'e')
                            }

                        }

                    }
                }
            }
        }
    }


    suspend fun addNewSnf(ad: String, snf: Int): SnfInfo? {
        RestApi.addSnfRequest(ad, snf)?.let { res ->
            Json.decodeFromString<SnfAddResponse>(res).let { sres ->
                if (sres.data != null) {
                    return sres.data
                }
            }
        }
        return null

    }

    suspend fun periodChange(period: SnfPeriod){
        if (period.ordinal != selectedPeriod.ordinal){
            selectedPeriod = period
            when(selectedPeriod){
                SnfPeriod.Daily -> raporStartDate = today
                SnfPeriod.Weekly -> raporStartDate = Funcs.getWeekStartDay(today)
                SnfPeriod.Monthly -> raporStartDate = today.firstDayOfMonth()
            }
            snfReportData = null
            getSnfReport()
        }

    }

    suspend fun previousPeriod(){
        when(selectedPeriod){
            SnfPeriod.Daily -> raporStartDate = raporStartDate.addDays(-1)
            SnfPeriod.Weekly -> raporStartDate = raporStartDate.addDays(-7)
            SnfPeriod.Monthly -> raporStartDate = raporStartDate.previousMonth()
        }
        snfReportData = null
        getSnfReport()
    }

    suspend fun nextPeriod(){
        when(selectedPeriod){
            SnfPeriod.Daily -> raporStartDate = raporStartDate.addDays(1)
            SnfPeriod.Weekly -> raporStartDate = raporStartDate.addDays(7)
            SnfPeriod.Monthly -> raporStartDate = raporStartDate.nextMonth()
        }
        snfReportData = null
        getSnfReport()
    }


    suspend fun getSnfReport() {
        if (snfReportData == null && snfId > 0) {
            RestApi.getSnfReport(snfId, raporStartDate.dateToDbStr(), selectedPeriod.ordinal)?.let { res ->
                Json.decodeFromString<SnfReportResponse>(res).let { sres ->
                    if (sres.data != null) {
                        snfReportData = sres.data
                        calculateScoreTable()
                    }
                }
            }
        }
    }


    suspend fun getEtutSonucReport(eid: Long): List<SnfEtutReport> {
        RestApi.getEtutReport(snfId, eid)?.let { res ->
            Json.decodeFromString<EtutReportResponse>(res).let { sres ->
                if (sres.data != null) {
                    return sres.data
                }
            }
        }
        return listOf()

    }


    suspend fun getSnfEtutList() {
        if (snfEtutList.isEmpty()) {
            val json = Json {
                ignoreUnknownKeys = true
            }
            RestApi.getSnfEtutList(snfId, etutStartDate.dateToDbStr())?.let { res ->
                console.log("etutlist:", res)
                json.decodeFromString<SnfEtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        snfEtutList = eres.data
                        handleSnfEtutList()
                    }
                }
            }
        }
    }


    private fun handleSnfEtutList() {
        etutListMap = snfEtutList.groupBy { it.tarih }
        etutListDersMap = snfEtutList.groupBy { it.ders }

        /*
        etutListMap[Date().dateToDbStr()]?.let { gunlist ->
            gunEtutList = gunlist
        }*/
    }

    suspend fun addNewSnfEtut(etut: NewEtutItem, docs: List<String> = listOf()): FuncResult {
        RestApi.addNewSnfEtut(snfId, etut, docs)?.let { res ->
            Json.decodeFromString<AddSnfEtutResponse>(res).let { eres ->
                if (eres.data != null) {
                    val nlist = snfEtutList.toMutableList()
                    nlist.add(eres.data)
                    snfEtutList = nlist.toList()
                    handleSnfEtutList()
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun editEtutWithDrag(etutItem: SnfEtutItem) {
        RestApi.etutEditWithDrag(etutItem)?.let { res ->
            Json.decodeFromString<BoolResponse>(res).let { eres ->
                if (eres.data != null) {
                    val nlist = snfEtutList.filter { it.eid != etutItem.eid }.toMutableList()
                    nlist.add(etutItem)
                    snfEtutList = nlist.toList()

                }
            }
        }
    }


    suspend fun addSnfOgrenci(name: String, code: String): FuncResult {
        if (!Funcs.checkForm("ogrenci-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        snfList?.find { it.snf.id == snfId }?.let {snfData ->
            RestApi.addSnfOgrenci(snfData.snf, name, code)?.let { res ->
                Json.decodeFromString<TekSnfResponse>(res).let { ares ->
                    if (ares.data != null){
                        val nlist = snfList!!.toMutableList()
                        nlist.add(ares.data)
                        snfList = nlist.toList()
                        snfListMap = snfList!!.groupBy { it.snf }
                        return FuncResult(true)
                    }else if (ares.error != null){
                        return FuncResult(false, ares.error)
                    }
                }
            }
        }?:run {
            return FuncResult(false, "Sınıf bulunamadı")
        }


        return FuncResult(false, "Bağlantı hatası")
    }

    suspend fun getKaynakList() {
        if (allKaynakList.isEmpty()){
            val json = Json {
                ignoreUnknownKeys = true
            }
            snfList?.find { it.snf.id == snfId  }?.let { snfData ->
                RestApi.getSnfKaynakList(snfId, snfData.snf.snf)?.let { res ->
                    //console.log("kaynak-list:", res)
                    json.decodeFromString<SnfKaynakListResponse>(res).let { eres ->
                        if (eres.data != null) {
                            allKaynakList = eres.data
                            prepareKaynakMap()
                        }
                    }
                }
            }

        }
    }

    fun selectDersAdi(ders: String){
        secilenKaynakDersAdi = ders
        secilenDersKaynak = null
    }

    fun selectKaynak(kaynak: SnfKaynak){
        secilenDersKaynak = kaynak
        secilenKaynakDersAdi = null
    }

    suspend fun deleteSelectKaynak() {
        if (secilenDersKaynak != null){
            snfList?.find { it.snf.id == snfId }?.let { snfData ->
                RestApi.deleteSnfSelectedKaynak(snfId, secilenDersKaynak!!, snfData.snf.snf)?.let { res ->
                    Json.decodeFromString<BoolResponse>(res).let { eres ->
                        if (eres.data == true) {
                            allKaynakList = listOf()
                            kaynakMap = mapOf()
                        }
                    }
                }
            }
        }
    }

    suspend fun snfEtutDelete(etut: SnfEtutItem){


    }


    suspend fun etutNextWeek() {
        snfEtutList = listOf()
        etutStartDate = Funcs.getWeekStartDay(etutStartDate.addDays(7))
        weekDays = makeWeekDayList(etutStartDate)
        etutListMap = mapOf()
        etutListDersMap = mapOf()
        getSnfEtutList()

    }

    suspend fun etutPreviousWeek() {
        snfEtutList = listOf()
        etutStartDate = Funcs.getWeekStartDay(etutStartDate.addDays(-7))
        weekDays = makeWeekDayList(etutStartDate)
        etutListMap = mapOf()
        etutListDersMap = mapOf()
        getSnfEtutList()

    }

    suspend fun addNewSnfKaynak(kaynak: SnfKaynak): FuncResult {
        if (!Funcs.checkForm("kaynak-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        snfList?.find { it.snf.id == snfId }?.let { snfData ->
            RestApi.addNewSnfKaynak(snfId, kaynak, snfData.snf.snf)?.let { res ->
                Json.decodeFromString<SnfKaynakListResponse>(res).let { kres ->
                    if (kres.data != null) {
                        allKaynakList = kres.data
                        prepareKaynakMap()
                        return FuncResult(true)
                    } else if (kres.error != null) {
                        return FuncResult(false, kres.error)
                    }
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }





    fun selectEtutItem(etut: SnfEtutItem) {
        if (etut.eid == snfEtutSelected?.eid) {
            snfEtutSelected = null
        } else {
            snfEtutSelected = etut
        }
    }


    private fun makeWeekDayList(start: Date): List<String> {
        val daysList: MutableList<String> = mutableListOf()
        daysList.add(start.dateToDbStr())
        (1..6).forEach {
            val ndate = start.addDays(it)
            daysList.add(ndate.dateToDbStr())
        }
        return daysList
    }

    /*
    data class DersItem(
        val id: Long = 0,
        var sid: Long,
        var oid: Long,
        var dersad: String,
        var renk: Int
    )*/

    private fun prepareKaynakMap(){
        val nders = mutableSetOf<DersItem>()
        kaynakMap = allKaynakList.groupBy { it.ders }
        allKaynakList.forEach {
            val ders = DersItem(sid = 0, oid = it.did, dersad = it.ders, renk = it.renk)
            nders.add(ders)
        }
        snfDersList = nders
    }



    private suspend fun calculateScoreTable(){
        if (snfReportData != null){
            withContext(Dispatchers.Default) {
                val userGroup = snfReportData!!.reports.groupBy { it.uuid to it.name }
                val dersAccumulatedReport = userGroup.flatMap { (uuidName, items) ->
                    items.groupBy { it.ders }.map { (ders, dersItems) ->
                        var soru = 0
                        var dogru = 0
                        var yanlis = 0
                        var net = 0.0

                        dersItems.forEach { di ->
                            soru += di.soru
                            dogru += di.dogru
                            yanlis += di.yanlis
                            net += di.net
                        }

                        AccumulatedReport(
                            uuid = uuidName.first,
                            name = uuidName.second,
                            ders = ders,
                            totalSoru = soru,
                            totalDogru = dogru,
                            totalYanlis = yanlis,
                            totalNet = net
                        )
                    }
                }
                uniqueDersNames = dersAccumulatedReport.map { it.ders }.toSet()
                scoresByUuidAndName = dersAccumulatedReport.groupBy { it.uuid to it.name }
                groupByDersKonu()
            }
        }else{
            uniqueDersNames = setOf()
            scoresByUuidAndName = mapOf()
        }
    }


    suspend fun  groupByUserDersKonu(uuid: String): Map<String, Map<String, Map<String, Any>>> {

        if (snfReportData != null) {
            val userGroupDers = snfReportData!!.reports.filter { it.uuid == uuid }.toList()
                .groupBy { it.ders } // Group by `ders`
                .mapValues { (_, itemsByDers) ->
                    // Calculate total values for the `ders`
                    var dersTotalSoru = 0
                    var dersTotalDogru = 0
                    var dersTotalYanlis = 0
                    var dersTotalNet = 0.0

                    // Group by `konu` within each `ders` and accumulate values
                    val groupedByKonu = itemsByDers.groupBy { it.konu }
                        .mapValues { (_, itemsByKonu) ->
                            val totalSoru = itemsByKonu.sumOf { it.soru }
                            val totalDogru = itemsByKonu.sumOf { it.dogru }
                            val totalYanlis = itemsByKonu.sumOf { it.yanlis }
                            val totalNet = itemsByKonu.sumOf { it.net }

                            // Add to ders totals
                            dersTotalSoru += totalSoru
                            dersTotalDogru += totalDogru
                            dersTotalYanlis += totalYanlis
                            dersTotalNet += totalNet

                            // Return a map for each `konu` with accumulated values
                            mapOf(
                                "s" to totalSoru,
                                "d" to totalDogru,
                                "y" to totalYanlis,
                                "n" to totalNet
                            )
                        }

                    // Include the totals at `ders` level as well in the final output
                    mapOf(
                        "konu" to groupedByKonu, // Grouped data by `konu`
                        "ders" to mapOf( // Totals for the entire `ders`
                            "s" to dersTotalSoru,
                            "d" to dersTotalDogru,
                            "y" to dersTotalYanlis,
                            "n" to dersTotalNet
                        )
                    )
                }
            return userGroupDers
        }
        return mapOf()
    }
    private fun groupByDersKonu() {
        if (snfReportData != null) {
            scoresMapByDersKonu = snfReportData!!.reports
                .groupBy { it.ders } // Group by `ders`
                .mapValues { (_, itemsByDers) ->
                    // Calculate total values for the `ders`
                    var dersTotalSoru = 0
                    var dersTotalDogru = 0
                    var dersTotalYanlis = 0
                    var dersTotalNet = 0.0

                    // Group by `konu` within each `ders` and accumulate values
                    val groupedByKonu = itemsByDers.groupBy { it.konu }
                        .mapValues { (_, itemsByKonu) ->
                            val totalSoru = itemsByKonu.sumOf { it.soru }
                            val totalDogru = itemsByKonu.sumOf { it.dogru }
                            val totalYanlis = itemsByKonu.sumOf { it.yanlis }
                            val totalNet = itemsByKonu.sumOf { it.net }

                            // Add to ders totals
                            dersTotalSoru += totalSoru
                            dersTotalDogru += totalDogru
                            dersTotalYanlis += totalYanlis
                            dersTotalNet += totalNet

                            // Return a map for each `konu` with accumulated values
                            mapOf(
                                "s" to totalSoru,
                                "d" to totalDogru,
                                "y" to totalYanlis,
                                "n" to totalNet
                            )
                        }

                    // Include the totals at `ders` level as well in the final output
                    mapOf(
                        "konu" to groupedByKonu, // Grouped data by `konu`
                        "ders" to mapOf( // Totals for the entire `ders`
                            "s" to dersTotalSoru,
                            "d" to dersTotalDogru,
                            "y" to dersTotalYanlis,
                            "n" to dersTotalNet
                        )
                    )
                }

        }
    }



}


data class AccumulatedReport(
    val uuid: String,
    val name: String,
    val ders: String,
    var totalSoru: Int,
    var totalDogru: Int,
    var totalYanlis: Int,
    var totalNet: Double
)