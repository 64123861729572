package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ders.AddNewDers
import com.kelimesoft.etutpro.pages.ders.DersColumn
import com.kelimesoft.etutpro.pages.ders.DersItemView
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.components.BSIconButton
import com.stevdza.san.kotlinbs.icons.BSIcons
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint

import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent

@Page("/ders")
@Composable
fun DersPage() {
    //val context = rememberPageContext()
    IfUserExist(Screens.Ders) {
        DersList()
    }
    /*
    if (AppData.appUser.role == EtutRole.Ogrenci.ordinal){
        DersList()
    }else{
        Column(modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            NoContentYet("Öğretmen ve Veli modülleri üzerinde çalışıyoruz, Çok yakında kullanıma sunulacaktır")
            BSButton(text = "Çıkış", variant = ButtonVariant.DangerOutline){
                AppData.logOut(context)
            }

        }

    }*/

}

@Composable
fun DersList() {
    val dersVM = AppData.ViewModel.dersVM
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val scope = rememberCoroutineScope()
    var showAddOgrenci by remember { mutableStateOf(false) }

    var showAddDers by remember { mutableStateOf(false) }

    var ogrencUuid: String? by remember { mutableStateOf(null) }

    var secilenDers: DersItem? by remember { mutableStateOf(null) }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    var noContent by remember { mutableStateOf(false) }

    /*
    //ders kopyala yapıştır için listener
    val escListener = remember {
        EventListener {
            it as KeyboardEvent
            if (it.keyCode == 27) {
                if (dersVM.copiedDers != null) {
                    dersVM.copiedDers = null
                }
            }
        }
    }*/
    LaunchedEffect(dersVM.daysMapList){
        noContent = dersVM.dersProgramList.isEmpty()
    }

    LaunchedEffect(ogrenciVM.selectedOgrenci) {
        if (AppData.appUser.role > 0) {
            AppData.ViewModel.ogrenciVM.getOgrenciList()
            AppData.ViewModel.ogrenciVM.selectedOgrenci?.let {
                ogrencUuid = it.uuid
            }
        }
        dersVM.getDersList(ogrencUuid)
        dersVM.getDersProgram(ogrencUuid)
        noContent = dersVM.dersProgramList.isEmpty()
        //document.addEventListener("keydown", escListener)
    }
    /*
    DisposableEffect(true) {
        onDispose {
            document.removeEventListener("keydown", escListener)
        }
    }*/

    PageBox {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .maxWidth(1920.px)
                .backgroundColor(AppColors.ViewWhite)
                .borderRadius(r = 12.px)

                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MyNavBar(page = Screens.Ders, onSelect = {
                /*
                scope.launch {
                    noContent = false
                    //dersVM.getDersList(ogrenciVM.selectedOgrenci!!.uuid)
                    if (dersVM.dersList.isEmpty()) {
                        noContent = true
                    }
                }*/
            }, addOgrenci = {
                showAddOgrenci = true
            }, onSnf = {})

            //Tüm Dersler Listesi Set olarak sayfa üstünde listeleniyor
            Column(modifier = Modifier.fillMaxWidth()
                .backgroundColor(Colors.White.opacity(0.5f))
                .padding(6.px)
                .borderRadius(r = 8.px)
                .margin(bottom = 4.px),
                horizontalAlignment = Alignment.CenterHorizontally
            ){
                Row(modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
                    SpanText("Ders Listesi", modifier = Modifier.color(AppColors.TextColor))
                    Spacer()
                    BSButton(
                        modifier = Modifier.height(24.px)
                            .margin(leftRight = 10.px, topBottom = 2.px),
                        text = "+Yeni Ders Ekle",
                        size = ButtonSize.Small,
                        variant = ButtonVariant.Success,
                        onClick = {
                            secilenDers = null
                            showAddDers = true
                        }
                    )

                }

                VDivider()

                SimpleGrid(numColumns = numColumns(base = 3, md = 5, lg = 7),
                    modifier = Modifier.fillMaxWidth()){
                    dersVM.dersList.forEach { ders ->
                        DersItemView(
                            ders,
                            onEdit = {
                                secilenDers = ders
                                showAddDers = true
                            }, onDrag = {
                                dersVM.draggedDersItem = ders
                            }
                        )
                    }
                }
                Span(attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.DarkRed)
                    .padding(top = 2.px, bottom = 10.px)
                    .toAttrs()

                ){
                    Text("Ders listesinden sürükleyerek ")
                    Span(attrs = Modifier
                        .fontWeight(FontWeight.SemiBold).toAttrs()
                    ) {
                        Text("Haftalık Ders Programınızı")
                    }

                    Text(" oluşturabilirsiniz.")
                }

            }


            //Haftalık Ders Programı

            Column(
                Modifier.fillMaxWidth()
                    .backgroundColor(Colors.White.opacity(0.5f))
                    .padding(6.px)
                    .borderRadius(r = 8.px)
                    .margin(bottom = 4.px)
                    /*
                    .thenIf(
                        dersVM.copiedDers != null
                    ) {
                        //eğer kopyalama için seçilen item varsa boş yere tıklayınca iptal edilsin
                        Modifier.onClick {
                            dersVM.copiedDers = null
                        }
                    }*/
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
            ) {
                SpanText("Haftalık Ders Programı", modifier = Modifier.color(AppColors.TextColor))
                VDivider()
                SimpleGrid(
                    numColumns = numColumns(base = 1, sm = 3, md = 5),
                    modifier = Modifier.fillMaxWidth()
                ) {
                    dersVM.daysMapList.forEach { dersMap ->
                        DersColumn(
                            dersVM,
                            dersMap,
                            onDelete = {
                                scope.launch {
                                    dersVM.deleteDersPro(it, ogrencUuid)
                                }
                            }, onDrop = { newIndex ->
                                var nidx = newIndex
                                if (dersVM.draggedDersItem != null){
                                    scope.launch {
                                        dersVM.addDersFromDrag(nidx, dersMap.key.day, ogrencUuid)
                                    }
                                }else if (dersVM.draggedDersPro != null) {
                                    val drag = dersVM.draggedDersPro!!

                                    if (drag.gun == dersMap.key.day && nidx > drag.sira + 1) {
                                        nidx -= 1
                                    }
                                    //console.log("ondrop- newindex:", nidx, "dragged:", dersVM.draggedDers.toString())
                                    scope.launch {
                                        dersVM.updateDraggedDers(nidx, dersMap.key.day, ogrencUuid)
                                    }
                                }

                            })
                    }
                }
            }

        }

        if (noContent) {
            if (AppData.appUser.role == EtutRole.Ogrenci.ordinal) {
                NoContentYet("Henüz ders programını eklemedin, Ders programını eklemek için yukardaki Ders listesinde kaydırabilirsin")
            } else {
                if (ogrenciVM.ogrenciListem.isEmpty()){
                    Column(modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
                        NoContentYet("Ders ve Etüt takibi için öğrencinizi ekleyiniz!")
                        BSButton(
                            text = "Öğrenci Ekle",
                            variant = ButtonVariant.Success
                        ) {
                            showAddOgrenci = true
                        }
                    }
                }else if (ogrenciVM.selectedOgrenci != null) {
                    NoContentYet("Öğrenci haftalık ders programı henüz mevcut değil, Ders eklemek için yukarıdaki Ders Listesinde kaydırabilirsiniz.")
                } else {
                    NoContentYet("Öğrenci Seçiniz!")
                }
            }
        }

        /*
        DersAddButton {
            secilenDers = null
            showAddDers = true
        }
         */

        if (showAddDers) {
            DialogView {
                AddNewDers(secilenDers,
                    onClose = {
                        showAddDers = false
                    }, onSave = { dersItem ->
                        scope.launch {
                            val res = dersVM.addNewDers(dersItem, ogrencUuid)
                            if (res.result) {
                                showAddDers = false
                                alertModel = AlertModel(
                                    if (secilenDers != null) "Ders güncellendi" else "Yeni ders eklendi",
                                    AlertIcon.Checkmark, AlertStyle.Success
                                )
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    }, onDelete = { dersItem ->
                        scope.launch {
                            dersVM.deleteDersItem(dersItem, ogrencUuid)
                            showAddDers = false
                        }
                    })
            }
        }


        if (showAddOgrenci) {
            DialogView {
                AddOgrenci(onClose = {
                    showAddOgrenci = false
                }, onAdded = {
                    scope.launch {
                        showAddOgrenci = false
                        alertModel = AlertModel(
                            "Yeni Öğrenci Eklendi",
                            AlertIcon.Checkmark, AlertStyle.Success
                        )
                        delay(3000)
                        alertModel = null
                    }
                })
            }
        }




        if (alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style
            )
        }


    }
}


@Composable
fun DersAddButton(onAdd: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    Box(
        modifier = Modifier.fillMaxSize()
            .pointerEvents(PointerEvents.None),
        contentAlignment = Alignment.BottomEnd
    ) {
        BSIconButton(
            variant = ButtonVariant.Success,
            size = if (breakpoint >= Breakpoint.MD) ButtonSize.Large else ButtonSize.Small,
            modifier = Modifier.margin(16.px)
                .fontSize(28.px)
                .fontWeight(FontWeight.SemiBold)
                .pointerEvents(PointerEvents.Auto),
            icon = BSIcons.PLUS,
            onClick = {
                onAdd()
            }
        )
    }
}



