package com.kelimesoft.etutpro.pages.ozet

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.MyFaIcon
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.etut.EtutDoneButon
import com.kelimesoft.etutpro.styles.OzetEtutRowStyle
import com.kelimesoft.etutpro.utils.isVideo
import com.kelimesoft.etutpro.utils.rountTo

import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaYoutube
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.launch

import org.jetbrains.compose.web.css.px


@Composable
fun BugunEtut() {
    val scope = rememberCoroutineScope()
    val etutVM = AppData.ViewModel.etutVM

    HedefColumn(
        title = "Bügün Etüt Programım"
    ) {
        etutVM.gunEtutList.forEach { etut ->
            OzetEtutRow(etut, onComplete = {
                scope.launch {
                    etutVM.etutDone(etut, true)
                }
            })
        }
    }

}


@Composable
fun OzetEtutRow(
    etut: EtutItem,
    onComplete: () -> Unit,
) {
    val context = rememberPageContext()
    val dersVM = AppData.ViewModel.dersVM

    val typeIcon = EtutType.entries.find { it.type == etut.type }?.icon ?: "file-contract"
    var dersText by remember { mutableStateOf(etut.ders) }

    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }

    LaunchedEffect(true, AppData.ViewModel.ogrenciVM.selectedOgrenci) {
        if (etut.type < 2 || etut.type == EtutType.Video.type) {
            if (dersVM.dersList.isNotEmpty()) {
                dersVM.dersList.find { it.dersad == etut.ders }?.let {
                    dersColor = AppColors.colorDersList[it.renk]
                }

            }
        } else if (etut.type == EtutType.Kuran.type){
            dersText = "Kur'an Okuma"
            dersColor = AppColors.Primary
        }else if (etut.type == EtutType.Kitap.type){
            dersText = "Kitap Okuma"
            dersColor = AppColors.PrimaryDark
        }else if (etut.type == EtutType.Deneme.type){
            dersColor = AppColors.DarkRed
            dersText = "Sınav / Deneme"
        }
    }

    Box(
        modifier = OzetEtutRowStyle.toModifier()
            .fillMaxWidth()
            .padding(2.px),
        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .minHeight(80.px)
                .boxShadow(
                    1.px,
                    1.px,
                    1.px,
                    1.px,
                    color = if (etut.done) AppColors.PrimaryDark.opacity(0.2f) else Colors.DarkBlue.opacity(0.2f)
                )
                .backgroundColor(
                    if (etut.done) AppColors.PrimaryDark.opacity(0.1f) else Colors.White
                )

                .padding(1.px)
                .borderRadius(r = 6.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.CenterVertically
            ) {
                MyFaIcon(Modifier.color(dersColor), icon = typeIcon)
                SpanText(
                    dersText,
                    modifier = Modifier.fillMaxWidth()
                        .margin(left = 2.px)
                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
            }


            SpanText(
                etut.konu,
                modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor.opacity(0.9f))
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(1.px)
            )

            if (etut.soru > 0) {
                Row(modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically) {
                    if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) {
                        SpanText(
                            "Sayfa Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    }else {
                        SpanText(
                            if (etut.done) "Soru: ${etut.soru}" else "Soru Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    }

                    if (etut.done && etut.puan > 0
                        && (etut.type == EtutType.KonuTest.ordinal || etut.type == EtutType.Deneme.ordinal)){
                        SpanText(
                            "Doğru: ${etut.puan}",
                            modifier = Modifier
                                .margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                        SpanText(
                            "Not %: ${((100 * etut.net) / etut.soru).rountTo(1)}",
                            modifier = Modifier
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }
                }

            }

            if (etut.kaynak.isNotEmpty()) {
                SpanText(
                    etut.kaynak,
                    modifier = Modifier.fillMaxWidth()
                        .color(AppColors.TextColor)
                        .fontSize(14.px)
                        .padding(1.px)
                )
                if (etut.sayfa.isNotEmpty()){
                    SpanText(
                        etut.sayfa,
                        modifier = Modifier.fillMaxWidth()
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }
            }

            if (etut.link.isNotEmpty()) {
                Row(modifier = Modifier
                    .backgroundColor(AppColors.AppBarButonColor)
                    .margin(4.px)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        context.router.navigateTo(etut.link)
                    }
                    .borderRadius(r = 10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    var linkTitle = "Dökümanı Çalış"
                    if (etut.type == EtutType.KonuTest.ordinal) {
                        linkTitle = "Teste Başla"
                    }
                    if (etut.link.isVideo()){
                        linkTitle = "Video İzle"
                        FaYoutube(modifier = Modifier.color(AppColors.Red), size = IconSize.XL)
                    }else{
                        MyFaIcon(modifier = Modifier.color(AppColors.ViewWhite), icon ="book-reader",
                            IconCategory.SOLID, IconSize.XL)
                    }
                    SpanText(
                        linkTitle,
                        modifier = Modifier.fillMaxWidth()
                            .margin(leftRight = 4.px)
                            .color(AppColors.ViewWhite)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }
            }
        }

        EtutOzetButtons(etut, onComplete = {
            onComplete()
        }, onCevap = {

        })
    }

}


@Composable
fun EtutOzetButtons(
    etut: EtutItem,
    onComplete: () -> Unit,
    onCevap: () -> Unit
) {
    Column(
        modifier = Modifier.fillMaxHeight()
            .onClick {
                it.stopPropagation()
            }
            .margin(right = 6.px, bottom = 10.px),
        horizontalAlignment = Alignment.End,
        verticalArrangement = Arrangement.Bottom
    ) {
        EtutDoneButon(etut, onComplete = {
            onComplete()
        }, onCevap = {
            onCevap()
        })

    }
}



