package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.etut.*
import com.kelimesoft.etutpro.pages.kaynak.SnfKaynakList
import com.kelimesoft.etutpro.styles.*
import com.kelimesoft.etutpro.utils.*
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.icons.BSIcons
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

import kotlin.js.Date

private val todayDate = Date()

@Composable
fun SnfEtutMain(snfVM: SinifVM) {
    var ogrcUuid: String? by remember { mutableStateOf(null) }

    LaunchedEffect(snfVM.selectedOgr){
        delay(1)
        ogrcUuid = snfVM.selectedOgr?.uuid
    }
    if (ogrcUuid == null) {
        SnfTotalEtut(snfVM)
    }else {
        OgrenciEtut(ogrcUuid!!)
    }
}

@Composable
fun SnfTotalEtut(snfVM: SinifVM) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var showAddEtut by remember { mutableStateOf(false) }
    var showAddVideoEtut by remember { mutableStateOf(false) }
    var showAddKaynak by remember { mutableStateOf(false) }

    var draggedEtut: SnfEtutItem? by remember { mutableStateOf(null) }
    var draggedKaynak: SnfKaynak? by remember { mutableStateOf(null) }
    var dragKaynakType: KaynakType? by remember { mutableStateOf(null) }
    var selectedEtutDate: String by remember { mutableStateOf("") }

    var secilenEtut: SnfEtutItem? = null

    var etutType: EtutType? = null

    var alertModel: AlertModel? by remember { mutableStateOf(null) }


    fun saveDrag(it: SnfEtutItem, tarih: String, saat: String) {
        if (it.tarih == tarih && it.saat == saat) {
            draggedEtut = null
            return
        }
        val newSaat = saat.ifEmpty { it.saat }
        scope.launch {
            val updatedEtutItem = it.copy(
                saat = newSaat,
                tarih = tarih,
            )
            snfVM.editEtutWithDrag(updatedEtutItem)
        }
    }

    LaunchedEffect(snfVM.etutStartDate, snfVM.snfId){
        snfVM.getSnfEtutList()
    }

    Box(modifier = Modifier.fillMaxSize()) {
        Row(
            modifier = Modifier.fillMaxSize()
                .overflow(Overflow.Hidden),
        ) {
            SnfKaynakList(snfVM, onKaynak = {
                showAddKaynak = true

            }, onDrag = {
                draggedEtut = null
                draggedKaynak = it
            }, onDragType = {
                draggedEtut = null
                dragKaynakType = it
            })

            Column(
                modifier = Modifier.fillMaxSize()
                    .weight(1f)
                    .overflow(overflow = Overflow.Hidden),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(top = 1.px)
                        .backgroundColor(Colors.White.opacity(0.9f))
                        .borderRadius(r = 4.px)
                        .padding(2.px)
                    ,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    BSButton(
                        text = if (breakpoint > Breakpoint.SM) "Önceki" else "",
                        size = ButtonSize.Small,
                        icon = BSIcons.CHEVRON_LEFT,
                        variant = ButtonVariant.PrimaryOutline
                    ){
                        scope.launch {
                            snfVM.etutPreviousWeek()
                        }
                    }


                    Spacer()

                    BSButton(
                        text = if (breakpoint > Breakpoint.SM) "Sonraki" else "",
                        size = ButtonSize.Small,
                        icon = BSIcons.CHEVRON_RIGHT,
                        variant = ButtonVariant.PrimaryOutline
                    ){
                        scope.launch {
                            snfVM.etutNextWeek()
                        }
                    }

                }
                Column(modifier = Modifier.fillMaxSize()
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                    .scrollBehavior(ScrollBehavior.Smooth)
                    .styleModifier {
                        property("scrollbar-width", "thin")
                    }
                ) {

                    SimpleGrid(
                        modifier = Modifier.fillMaxWidth(),

                        numColumns = numColumns(base = 1, md = 2, lg = 3, xl = 5)
                    ) {
                        snfVM.weekDays.forEachIndexed { index, tarih ->
                            SnfEtutDayBox(snfVM, index, tarih,
                                onAdd = { typ ->
                                    etutType = typ
                                    secilenEtut = null
                                    draggedKaynak = null
                                    selectedEtutDate = tarih

                                    if (typ == EtutType.Video) {
                                        showAddVideoEtut = true
                                    } else {
                                        showAddEtut = true
                                    }


                                }, onEdit = { etut ->
                                    etutType = null
                                    selectedEtutDate = etut.tarih
                                    secilenEtut = etut
                                    draggedKaynak = null
                                    if (etut.type == 5) {
                                        showAddVideoEtut = true
                                    } else {
                                        showAddEtut = true
                                    }


                                }, onDrag = {
                                    draggedKaynak = null
                                    draggedEtut = it
                                }, onDrop = {
                                    if (draggedEtut != null) {
                                        //newEtutSaat = newSaat
                                        saveDrag(draggedEtut!!, tarih, snfVM.etutDragSaat)
                                        draggedEtut = null
                                    } else if (draggedKaynak != null) {
                                        etutType = null
                                        secilenEtut = null
                                        selectedEtutDate = tarih
                                        //newEtutSaat = newSaat
                                        showAddEtut = true
                                    } else if (dragKaynakType != null) {
                                        selectedEtutDate = tarih
                                        showAddVideoEtut = true
                                    }
                                })
                        }
                    }
                }
            }
        }

        if (showAddEtut) {
            DialogView {
                AddSnfEtut(
                    snfVm = snfVM,
                    typ = etutType,
                    etut = secilenEtut,
                    kaynak = draggedKaynak,
                    date = selectedEtutDate,
                    saat = snfVM.etutDragSaat,
                    onKaynak = {
                        showAddKaynak = true
                    },
                    onClose = {
                        showAddEtut = false
                    },
                    onEdit = { etutItem, docs ->
                        scope.launch {
                            var docList = mutableListOf<String>()
                            docs.sortedBy { it.order }.forEach {
                                docList.add(it.name)
                            }
                            //snfVM.editExistingEtut(etutItem, docList)
                        }
                    }, onAdd = { newEtutItem, docs ->
                        var docList = mutableListOf<String>()
                        docs.sortedBy { it.order }.forEach {
                            docList.add(it.name)
                        }
                        scope.launch {
                            val res = snfVM.addNewSnfEtut(newEtutItem, docList)
                            if (res.result) {
                                showAddEtut = false
                                alertModel = AlertModel("Yeni etüt eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    })
            }
        }

        if (showAddVideoEtut) {
            DialogView {
                AddSnfVideo(
                    snfVm = snfVM,
                    etut = secilenEtut,
                    date = selectedEtutDate,
                    saat = snfVM.etutDragSaat,
                    onClose = {
                        showAddVideoEtut = false
                    },
                    onEdit = { etutItem ->
                        scope.launch {
                            //snfVM.editExistingEtut(etutItem)
                        }
                    }, onAdd = { newEtutItem ->
                        scope.launch {
                            val res = snfVM.addNewSnfEtut(newEtutItem)
                            if (res.result) {
                                showAddEtut = false
                                alertModel = AlertModel("Yeni etüt eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    })
            }
        }

        if (showAddKaynak) {
            DialogView {
                AddSnfKaynak(
                    snfVM,
                    onClose = {
                        showAddKaynak = false
                    }, onAdded = {
                        showAddKaynak = false
                    })
            }
        }

    }
}


@Composable
fun SnfEtutDayBox(
    snfVM: SinifVM,
    index: Int, tarih: String,
    onAdd: (EtutType) -> Unit, onEdit: (SnfEtutItem) -> Unit,
    onDrag: (SnfEtutItem) -> Unit, onDrop: () -> Unit
) {
    val scope = rememberCoroutineScope()

    val etutDate = Date(tarih)
    val prevDate = todayDate.addDays(-6)
    val canAcceptDrop = etutDate.isAfter(prevDate)
    val showAddButton = etutDate.isAfter(todayDate.addDays(-4))
    var dragOver by remember { mutableStateOf(false) }
    val days = listOf("Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar")
    //var showDeleteEtut: EtutItem? by remember { mutableStateOf(null) }

    var howered by remember { mutableStateOf(false) }

    //var saatXpos by remember { mutableStateOf(0.0) }
    //var saatYpos by remember { mutableStateOf(0.0) }
    var lastDragId by remember { mutableStateOf("") }

    var dayTotalPuan by remember { mutableStateOf(0) }


    val columnId = "day-box-$tarih"

    LaunchedEffect(snfVM.etutListMap[tarih]){
        delay(100)
        /*
        val element = document.getElementById(columnId) as? HTMLElement
        if (element != null) {
            val rect = element.getBoundingClientRect()
            //saatXpos = rect.left
            //saatYpos = rect.top
        }*/

        var dayP = 0
        snfVM.etutListMap[tarih]?.forEach { et ->
            if (et.type == EtutType.Deneme.type || et.type == EtutType.KonuTest.type){
                if (et.soru > 0) dayP += et.soru
            }else{
                if (et.konupuan > 0) dayP += et.konupuan
            }
        }
        dayTotalPuan = dayP
    }



    fun getHourAndMinute(minutes: Int): String {
        var newHours = 4 + (minutes / 60)
        var newMinutes = minutes % 60
        //en yakın 5 dk ayarı
        newMinutes = ((newMinutes + 2) / 5) * 5
        if (newMinutes == 60) {
            newMinutes = 0
            newHours += 1
        }
        newHours %= 24
        return "${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}"
    }


    fun setNewSaatDataoOnDrag(dragEvt: SyntheticDragEvent){
        val element = document.getElementById(columnId) as? HTMLElement
        if (element != null) {
            val rect = element.getBoundingClientRect()
            val columnHeight = rect.height
            val yPosition = dragEvt.clientY - rect.top // + dragoffset
            val clampedYPosition = yPosition.coerceIn(0.0, columnHeight)
            // Calculate the position ratio
            val positionRatio = clampedYPosition / columnHeight
            //saatYpos = clampedYPosition + 50
            //gündeki dk
            val minutesSinceStart = (positionRatio * 1200).toInt()
            snfVM.etutDragSaat = getHourAndMinute(minutesSinceStart)
        }

    }


    Box(modifier = Modifier
        .onMouseOver {
            howered = true
        }
        .onMouseLeave {
            howered = false
        }
        .fillMaxWidth()
        .padding(topBottom = 2.px, leftRight = 5.px),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(modifier = Modifier
            .position(Position.Relative)
            .fillMaxWidth()
            //.boxShadow(1.px,1.px,1.px,1.px,
            //  color = Colors.DarkBlue.opacity(0.4f))
            //.backgroundColor(Colors.White.opacity(0.9f))
            .id(columnId)
            .borderRadius(r = 4.px)
            .minHeight(120.px)
            .maxWidth(460.px)
            .thenIf(dragOver) {
                Modifier.backgroundColor(Colors.Blue.opacity(0.2f))
            }
            .thenIf(canAcceptDrop) {
                Modifier
                    .onDrop { dropevt ->
                        dropevt.preventDefault()
                        onDrop()
                        dragOver = false
                        //draggedYOffset = 0.0
                    }
                    .onDragOver {
                        it.preventDefault()
                        dragOver = true
                        setNewSaatDataoOnDrag(dragEvt = it)

                        //fileExist = true
                    }
                    .onDragLeave {
                        it.preventDefault()
                        dragOver = false
                        snfVM.etutDragSaat = ""
                        //draggedYOffset = 0.0
                    }



            }


        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(
                        if (index > 4) AppColors.WeekEndColor.opacity(0.7f) else
                            AppColors.AppBarButonColor.opacity(0.8f)
                    )
                    .margin(bottom = 2.px, top = 4.px)
                    .padding(2.px)
                    .borderRadius(6.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.ViewWhite),

                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                //günlük test sayısı
                Span(attrs = Modifier
                    .fontSize(13.px)
                    .textAlign(TextAlign.Center)
                    .color(Colors.White)
                    .padding(left = 4.px)
                    .toAttrs()
                ) {
                    Text(dayTotalPuan.toString())
                }

                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    SpanText(
                        days[index],
                        modifier = Modifier
                            .textAlign(TextAlign.Center)
                            .color(Colors.White)
                            .padding(leftRight = 4.px)
                    )

                    SpanText(
                        Date(tarih).toLocaleDateString(),
                        modifier = Modifier
                            .fontSize(12.px)
                            .textAlign(TextAlign.Center)
                            .color(Colors.White.opacity(0.7f))
                    )
                }
                if (showAddButton) {
                    SnfEtutAddMenu(tarih, onSelect = {
                        onAdd(it)
                    })
                }else {
                    Box(modifier = Modifier.width(24.px)) {  }
                }
                /*
                SmallAddButton {
                    onAdd()
                }*/
            }

            snfVM.etutListMap[tarih]?.sortedBy { it.saat }?.forEach { etut ->
                if (etut.link.contains("youtube")){
                    SnfEtutListVideo(snfVM, etut,
                        onEdit = {
                            onEdit(etut)
                        }, onDelete = {
                            scope.launch {
                                snfVM.snfEtutDelete(etut)
                            }
                        }, dragStart = { dragId ->
                            lastDragId = dragId
                            onDrag(etut)
                        })
                }else{
                    SnfEtutListRow(snfVM, etut,
                        onEdit = {
                            onEdit(etut)
                        }, onDelete = {
                            scope.launch {
                                snfVM.snfEtutDelete(etut)
                            }
                        }, dragStart = { dragId ->
                            lastDragId = dragId
                            onDrag(etut)
                        })
                }

            }
        }


        if(dragOver){
            Box(
                modifier = Modifier
                    .margin(top = (-30).px)
                    .position(Position.Absolute)
                    .padding(leftRight = 16.px, topBottom = 4.px)
                    .backgroundColor(AppColors.DarkRed)
                    .color(Colors.White)
                    .fontSize(16.px)
                    .borderRadius(4.px)
                    .padding(6.px)
                    .zIndex(999)
            ) {
                Text(snfVM.etutDragSaat)
            }
        }



    }
}


@Composable
fun SnfEtutListVideo(
    snfVM: SinifVM,
    etut: SnfEtutItem, onEdit: () -> Unit,
    onDelete: () -> Unit,
    dragStart: (String) -> Unit
) {

    var dersText by remember { mutableStateOf(etut.ders) }
    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }
    val dragItemId = "etutDrag-${etut.eid}"

    var showDelAlert by remember { mutableStateOf(false) }

    LaunchedEffect(snfVM.etutListMap) {
        dersText = etut.ders
        /*
        if (dersVM.dersList.isNotEmpty()) {
            dersVM.dersList.find { it.dersad == etut.ders }?.let {
                dersColor = AppColors.colorDersList[it.renk]
            }
        }*/


    }

    Box(
        modifier = EtutRowStyle.toModifier()
            .id(dragItemId)
            .onClick {
                it.stopPropagation()
            }

            .thenIf(snfVM.kaynakDersOver.isNotEmpty() && etut.ders != snfVM.kaynakDersOver) {
                Modifier.opacity(0.12f)
                    .styleModifier {
                        property("backdrop-filter", "blur(1px)") // Apply CSS blur effect
                        property("filter", "blur(1px)") // Fallback for browsers that don't support backdrop-filter
                    }
                    .scale(0.95f)


            }
            .thenIf(snfVM.kaynakDersOver.isNotEmpty() && etut.ders == snfVM.kaynakDersOver) {
                Modifier.opacity(1.5f)
                    .scale(1.05f)
            },

        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .draggable(Draggable.True)
                .onDragStart {
                    dragStart(dragItemId)

                }
                .minHeight(80.px)
                .onClick {
                    //onDelete(false)
                    showDelAlert = false
                    snfVM.selectEtutItem(etut)
                    Funcs.closeDropDowns()

                }
                .boxShadow(
                    (0.5).px,
                    0.5.px,
                    1.px,
                    1.px,
                    color = Colors.DarkGray.opacity(0.4f)
                )
                .backgroundColor(
                    Colors.White
                    /*
                    if (etut.done) AppColors.PrimaryDark.opacity(0.1f)
                    else if (etut.id == etutVM.etutSelectForCopy?.id) Colors.White.opacity(0.6f) else Colors.White

                     */
                )
                .border(
                    0.px,
                    color = Colors.Gray.opacity(0.5f),
                    style = LineStyle.Ridge
                )
                .padding(1.px)
                .borderRadius(r = 2.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaYoutube(Modifier.color(dersColor), size = IconSize.SM)
                SpanText(
                    dersText,
                    modifier = Modifier.fillMaxWidth()
                        .margin(left = 2.px)
                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
                Spacer()
                Row(
                    modifier = Modifier.backgroundColor(Colors.White)
                        .padding(1.px)
                        .borderRadius(r = 6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FaClock(modifier = Modifier.color(AppColors.TextColor), size = IconSize.SM)
                    SpanText(
                        etut.saat,
                        modifier = Modifier.fillMaxWidth()
                            .margin(left = 3.px)
                            .color(dersColor)
                            .fontSize(12.px)
                            .fontWeight(FontWeight.Normal)
                            .padding(1.px)
                    )
                }

            }

            Row(modifier = Modifier.fillMaxWidth()) {
                VideoThumbnail(etut.link)
                Column(modifier = Modifier.fillMaxWidth()) {
                    SpanText(
                        etut.konu,
                        modifier = Modifier.fillMaxWidth()
                            .color(AppColors.TextColor.opacity(0.9f))
                            .fontSize(14.px)
                            .fontWeight(FontWeight.SemiBold)
                            .padding(1.px)
                    )

                    SpanText(
                        etut.kaynak,
                        modifier = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .padding(1.px)
                    )
                }
            }


        }
        SnfEtutButtons(
            etut.eid == snfVM.snfEtutSelected?.eid,
            onEdit = {
                onEdit()
            }, onDelete = {
                showDelAlert = true
                //onDelete()
            })

        if (showDelAlert) {
            EtutDeleteOnay(onCancel = {
                showDelAlert = false
            }, onDelete = {
                onDelete()
            })
        }



    }

}


@Composable
fun SnfEtutListRow(
    snfVM: SinifVM,
    etut: SnfEtutItem, onEdit: () -> Unit,
    onDelete: () -> Unit,
    dragStart: (String) -> Unit
) {
    val context = rememberPageContext()

    val typeIcon = EtutType.entries.find { it.type == etut.type }?.icon ?: "file-contract"
    var dersText by remember { mutableStateOf(etut.ders) }
    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }
    val dragItemId = "etutDrag-${etut.eid}"

    var showDelAlert by remember { mutableStateOf(false) }
    var showEtutReport by remember { mutableStateOf(false) }

    LaunchedEffect(snfVM.etutListMap, AppData.ViewModel.ogrenciVM.selectedOgrenci) {
        if (etut.type < 2 || etut.type == EtutType.Video.type) {
            dersText = etut.ders
            if (snfVM.snfDersList.isNotEmpty()) {
                snfVM.snfDersList.find { it.dersad == etut.ders }?.let {
                    dersColor = AppColors.colorDersList[it.renk]
                }
            }
        } else if (etut.type == EtutType.Deneme.type) {
            dersColor = AppColors.DarkRed
            dersText = "Sınav / Deneme"
        }
    }

    Box(
        modifier = EtutRowStyle.toModifier()
            .id(dragItemId)
            .thenIf(snfVM.kaynakDersOver.isNotEmpty() && etut.ders != snfVM.kaynakDersOver) {
                Modifier.opacity(0.12f)
                    .styleModifier {
                        property("backdrop-filter", "blur(1px)") // Apply CSS blur effect
                        property("filter", "blur(1px)") // Fallback for browsers that don't support backdrop-filter
                    }
                    .scale(0.95f)
            }
            .thenIf(snfVM.kaynakDersOver.isNotEmpty() && etut.ders == snfVM.kaynakDersOver) {
                Modifier.opacity(1.2f)
                    .scale(1.05f)
            }
            .onClick {
                it.stopPropagation()
            }
        ,
        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .draggable(Draggable.True)
                .onDragStart {
                    dragStart(dragItemId)

                }
                .minHeight(80.px)
                .onClick {
                    //onDelete(false)
                    showDelAlert = false
                    snfVM.selectEtutItem(etut)
                    Funcs.closeDropDowns()

                }
                .boxShadow(
                    (0.5).px,
                    0.5.px,
                    1.px,
                    1.px,
                    color = if (etut.done > etut.notdone)
                        AppColors.GrayDark.opacity(0.2f)
                    else Colors.DarkGray.opacity(0.4f)
                )
                .onCopy {
                    snfVM.snfEtutSelected = etut



                }
                .backgroundColor(
                    if (etut.done > etut.notdone) AppColors.PrimaryDark.opacity(0.1f)
                    else if (etut.eid == snfVM.snfEtutSelected?.eid)
                        Colors.White.opacity(0.6f) else Colors.White
                )
                .border(
                    if (etut.eid == snfVM.snfEtutSelected?.eid) 0.5.px else 0.px,
                    color = Colors.Gray.opacity(0.5f),
                    style = LineStyle.Ridge
                )
                .padding(1.px)
                .borderRadius(r = 2.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (etut.type == EtutType.Kuran.ordinal) {
                    Image(
                        modifier = Modifier
                            .size(15.px),
                        src = Images.Kuran, alt = "Kutsal kitab Kuranı okumak"
                    )
                } else {
                    MyFaIcon(Modifier.color(dersColor), icon = typeIcon)
                }

                SpanText(
                    dersText,
                    modifier = Modifier.fillMaxWidth()
                        .margin(left = 2.px)
                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
                Spacer()
                Row(
                    modifier = Modifier.backgroundColor(Colors.White)
                        .padding(1.px)
                        .borderRadius(r = 6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FaClock(modifier = Modifier.color(AppColors.TextColor), size = IconSize.SM)
                    SpanText(
                        etut.saat,
                        modifier = Modifier.fillMaxWidth()
                            .margin(left = 3.px)
                            .color(dersColor)
                            .fontSize(12.px)
                            .fontWeight(FontWeight.Normal)
                            .padding(1.px)
                    )
                }

            }


            SpanText(
                etut.konu,
                modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor.opacity(0.9f))
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(1.px)
            )

            if (etut.kaynak.isNotEmpty()) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SpanText(
                        etut.kaynak,
                        modifier = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .padding(1.px)
                    )
                    if (etut.sayfa.isNotEmpty()) {
                        SpanText(
                            " (syf: ${etut.sayfa})",
                            modifier = Modifier
                                .color(AppColors.TextColor.opacity(0.6f))
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }
                }

            }

            if (etut.link.isNotEmpty()) {
                Row(modifier = Modifier
                    .backgroundColor(AppColors.GrayDark)
                    .margin(3.px)
                    .padding(leftRight = 4.px, topBottom = 2.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        context.router.navigateTo(etut.link)
                    }
                    .borderRadius(r = 4.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    var linkTitle = "Linke Git"
                    if (etut.link.isVideo()) {
                        linkTitle = "Video İzle"
                        FaYoutube(modifier = Modifier.color(AppColors.Red), size = IconSize.LG)
                    } else {
                        MyFaIcon(
                            modifier = Modifier.color(AppColors.ViewWhite), icon = "book-reader",
                            IconCategory.SOLID, IconSize.LG
                        )
                    }
                    SpanText(
                        linkTitle,
                        modifier = Modifier.fillMaxWidth()
                            .margin(leftRight = 4.px)
                            .color(AppColors.ViewWhite)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }


            }
            else if (etut.belge.isNotEmpty()) {
                Row(modifier = Modifier
                    .backgroundColor(AppColors.GrayDark)
                    .margin(3.px)
                    .padding(leftRight = 4.px, topBottom = 2.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        val belgeLink = "https://etutpro.com/etutdocs/${etut.belge}"
                        context.router.navigateTo(belgeLink)
                    }
                    .borderRadius(r = 4.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    val linkTitle = "Dökümanı gör"

                    FaFilePdf(modifier = Modifier.color(AppColors.Red), size = IconSize.LG)
                    SpanText(
                        linkTitle,
                        modifier = Modifier.fillMaxWidth()
                            .margin(leftRight = 4.px)
                            .color(AppColors.ViewWhite)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }


            }


            if (etut.soru > 0) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.Bottom
                ) {
                    if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) {
                        SpanText(
                            "Sayfa Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    } else {
                        SpanText(
                           "Soru Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    }

                    Row(modifier =
                    RoleButtonStyle.toModifier()
                        .margin(left = 6.px)
                        .onClick {
                            showEtutReport = true
                        }
                    ) {
                        SpanText(
                            "✔ ${etut.done} ",
                            modifier = Modifier
                                .color(AppColors.NumGreen)
                                .fontSize(12.px)
                                .padding(1.px)
                        )
                        SpanText(
                            "❌ ${etut.notdone}",
                            modifier = Modifier
                                .margin(right = 6.px)
                                .color(AppColors.DarkRed)
                                .fontSize(12.px)
                                .padding(1.px)
                        )
                    }




                    }
                }

            }

        SnfEtutButtons(etut.eid == snfVM.snfEtutSelected?.eid, onEdit = {
            onEdit()
        }, onDelete = {
            showDelAlert = true
            //onDelete()
        })

        if (showDelAlert && snfVM.snfEtutSelected == etut) {
            EtutDeleteOnay(onCancel = {
                showDelAlert = false
            }, onDelete = {
                onDelete()
            })
        }

        if (showEtutReport){
            EtutSonucuGor(snfVM, etut){
                showEtutReport = false
            }
        }



            //Box(modifier = Modifier.fillMaxWidth().height(1.px).backgroundColor(Colors.Gray.opacity(0.4f)))
        }





}



@Composable
fun SnfEtutButtons(
    selected: Boolean,
    onEdit: () -> Unit,
    onDelete: () -> Unit
) {
    Column(
        modifier = Modifier.fillMaxHeight(),
        horizontalAlignment = Alignment.End,
        verticalArrangement = Arrangement.Bottom
    ) {
            if (selected) {
                RowButton {
                    FaEdit(
                        modifier = Modifier
                            .color(AppColors.Gray.opacity(0.9f))
                            .onClick {
                                onEdit()
                            },
                        size = IconSize.SM
                    )
                }

                RowButton(modifier = Modifier.margin(left = 10.px)) {
                    FaTrash(
                        modifier = Modifier
                            .color(AppColors.Red.opacity(0.8f))
                            .onClick {
                                onDelete()
                            },
                        size = IconSize.SM
                    )
                }
            }

    }
}


@Composable
fun SnfEtutAddMenu(id: String, onSelect: (EtutType) -> Unit) {
    DropDownMenu(
        butonModifier = SmallAddButonStyle.toModifier()
            .margin(right = 4.px)
            .onClick {
                it.stopPropagation()
                val menu = document.getElementById("drop$id") as HTMLDivElement
                if (menu.style.display == "block") {
                    menu.style.display = "none"
                } else {
                    Funcs.closeDropDowns()
                    menu.style.display = "block"
                }
            },
        rightAlign = true,
        id = "drop$id",
        content = {
            SpanText(
                " + ",
                modifier = Modifier
                    .fontSize(16.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(Colors.White)
                    .textAlign(TextAlign.Center)
            )
        }
    ) {
        EtutType.entries
            .filter { it.type != 3 && it.type != 4 }
            .forEachIndexed { idx, type ->
            if (idx > 0){
                VDivider(modifier = Modifier.margin(bottom = 1.px))
            }

            Row(modifier = EtutDropButonStyle.toModifier()
                .onClick {
                    onSelect(type)
                }
                .thenIf(type == EtutType.Deneme){
                    Modifier.color(AppColors.DarkRed.opacity(0.8f))
                }

                .fillMaxWidth(),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.CenterVertically) {
                Box(modifier = Modifier.width(20.px),
                    contentAlignment = Alignment.Center) {
                    if (type == EtutType.Video) {
                        FaYoutube(modifier = Modifier.width(16.px).color(AppColors.Red))
                    }else{
                        MyFaIcon(modifier = Modifier.width(16.px), icon = type.icon)
                    }
                }
                SpanText(
                    type.text,

                    )
            }


        }

    }
}

