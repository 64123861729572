package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.SnfEtutItem
import com.kelimesoft.etutpro.models.SnfEtutReport
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.utils.round
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*


@Composable
fun EtutSonucuGor(
    snfVM: SinifVM,
    etut: SnfEtutItem,
    onClose: () -> Unit

) {
    var etutReports: List<SnfEtutReport> by remember { mutableStateOf(listOf()) }

    val cellStyle = Modifier.padding(2.px)
        .textAlign(TextAlign.Center)
        .fontSize(14.px)


    LaunchedEffect(Unit) {
        etutReports = snfVM.getEtutSonucReport(etut.eid)

    }
    DialogView {
        Column(
            modifier = Modifier
                .position(Position.Absolute)
                .top(0.px)
                .maxWidth(560.px)
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .padding(4.px)
                .borderRadius(r = 8.px)
                .backgroundColor(Colors.White)
                .overflow(Overflow.Hidden),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            DialogTitle("Sonuc Raporu", onClose = {
                onClose()
            })
            Column(
                modifier = Modifier.fillMaxWidth()
                    .backgroundColor(AppColors.Gray.opacity(0.2f))
                    .borderRadius(r = 4.px)
                    .padding(4.px),
            ) {
                Table(
                    Modifier
                        .classNames("table", "table-bordered")
                        .fillMaxWidth()
                        .padding(leftRight = 6.px)
                        .toAttrs()
                ) {
                    // Table Header Row
                    Thead {
                        Tr {
                            // First cell for the User name
                            Td(
                                cellStyle
                                    .toAttrs()
                            ) { Text("Öğrenci") }
                            Td(
                                cellStyle
                                    .toAttrs()
                            ) { Text("Doğru") }

                            Td(
                                cellStyle
                                    .toAttrs()
                            ) { Text("Yanlış") }
                            Td(
                                cellStyle
                                    .toAttrs()
                            ) { Text("Net") }
                        }
                    }
                    Tbody {
                        etutReports.forEach { rep ->
                            Tr(
                                attrs = Modifier
                                    .background(AppColors.Gray.opacity(0.4f))
                                    .toAttrs()
                            ) {
                                Td(cellStyle
                                    .textAlign(TextAlign.Start)
                                    .padding(leftRight = 2.px)
                                    .toAttrs()) { Text(rep.name) }
                                Td(
                                    cellStyle
                                        .color(AppColors.NumGreen)
                                        .padding(leftRight = 2.px)
                                        .toAttrs()
                                ) { Text(rep.dogru.toString()) }
                                Td(
                                    cellStyle
                                        .color(AppColors.DarkRed)
                                        .padding(leftRight = 2.px)
                                        .toAttrs()
                                ) { Text(rep.yanlis.toString()) }
                                Td(cellStyle
                                    .padding(leftRight = 2.px)
                                    .toAttrs()) {
                                    Text(rep.net.round(1))
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}



