package com.kelimesoft.etutpro.styles

import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.opacity
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*


import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val CloseButtonStyle = CssStyle {
    base {
        Modifier
            .borderRadius(r = 20.px)
            .border(0.px)
            .padding(12.px)
            .backgroundColor(Colors.Red.copyf(alpha = 0.7f))
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .boxShadow(1.px, 1.px, 1.px, 1.px, color = Colors.Gray.copyf(alpha = 0.2f))
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "opacity", duration = 200.ms),
                CSSTransition(property = "box-shadow", duration = 300.ms),
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .boxShadow(1.px, 1.px, 2.px, 2.px, color = Colors.Gray.copyf(alpha = 0.3f))
            .backgroundColor(Colors.Red.copyf(alpha = 0.9f))
    }
}

val RowButtonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(30.percent)
            .size(24.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .cursor(Cursor.Pointer)
            .backgroundColor(Colors.Teal.copyf(alpha = 0.3f))
    }
}

val SmallAddButonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .size(22.px)
            .border(0.5.px, color = Colors.White, style = LineStyle.Solid)
            .borderRadius(r = 6.px)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .cursor(Cursor.Pointer)
            .backgroundColor(Colors.White.opacity(0.6f))
    }
}

val ContexMenuItemStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = 8.px)
            .cursor(Cursor.Pointer)
            .fontSize(13.px)
            .fillMaxWidth()
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .backgroundColor(Colors.Gray.opacity(0.2f))
    }
}

val ChevronButtonStyle = CssStyle {
    base {
        Modifier
            .size(30.px)
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 6.px)
            .margin(2.px)
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .backgroundColor(Colors.White.opacity(0.6f))
    }
}

val RoleButtonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 6.px)
            .cursor(Cursor.Pointer)
            .padding(leftRight = 4.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .backgroundColor(AppColors.Primary.opacity(0.4f))
    }
}

val AppBarButtonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(AppColors.AppBarButonColor)
            .borderRadius(r = 16.px)
            .margin(2.px)
            .padding(4.px)
            .border(0.px)
            .color(AppColors.ViewWhite)
            .cursor(Cursor.Pointer)

            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "border-radius", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .backgroundColor(AppColors.ViewWhite.opacity(0.3f))
            //.borderRadius(r = 22.px)

    }
}


val SnfKatilButtonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(AppColors.Red.opacity(0.2f))
            .borderRadius(r = 8.px)
            .margin(2.px)
            .padding(leftRight = 5.px, topBottom = 4.px)
            .border(0.px)
            .color(AppColors.ViewWhite)
            .cursor(Cursor.Pointer)

            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "border-radius", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .backgroundColor(AppColors.Red.opacity(0.5f))
        //.borderRadius(r = 22.px)

    }
}


val NavTextStyle = CssStyle {
    hover {
        Modifier
            .textDecorationLine(TextDecorationLine.Underline)
    }
}

val FaaliyetDersChevStyle = CssStyle {
    base {
        Modifier
            .cursor(Cursor.Pointer)
            .size(20.px)
            .padding(2.px)
            .borderRadius(r = 8.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .backgroundColor(Colors.Gray.opacity(0.4f))
    }
}

val EtutDropButonStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .cursor(Cursor.Pointer)
            .margin(bottom = 1.px)
            .fontSize(14.px)
            .whiteSpace(WhiteSpace.NoWrap)
            .fontWeight(FontWeight.SemiBold)
            .color(AppColors.TextColor)
            .borderRadius(r = 4.px)
            .textAlign(TextAlign.Start)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .backgroundColor(Colors.Gray.opacity(0.4f))
    }
}

val SnfDataItemButton = CssStyle {
    base {
        Modifier
            .backgroundColor(AppColors.GrayDark.opacity(0.5f))
            .borderRadius(r = 6.px)
            .margin(2.px)
            .padding(leftRight = 6.px, topBottom = 2.px)
            .border(0.px)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .backgroundColor(AppColors.Red.opacity(0.5f))
        //.borderRadius(r = 22.px)

    }
}







