package com.kelimesoft.etutpro.models

import com.kelimesoft.etutpro.viewmodels.*
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.core.PageContext
import org.jetbrains.compose.web.css.rgba

object AppData {
    var appUser = AppUser()

    object ViewModel {
        var ogrenciVM = OgrenciVM()
        var dersVM = DersVM()
        var etutVM = EtutVM()
        var ozetVM = OzetVM()
        var kaynakVM = KaynakVM()
        var profileVM = ProfileVM()
    }
    val appRoutes = Routes()

    fun logOut(context: PageContext){
        appUser = AppUser()
        ViewModel.ogrenciVM = OgrenciVM()
        ViewModel.etutVM = EtutVM()
        ViewModel.dersVM = DersVM()
        ViewModel.ozetVM = OzetVM()
        ViewModel.kaynakVM = KaynakVM()
        ViewModel.profileVM = ProfileVM()
        AppStorage.remove(AppStorage.userUUID)
        appRoutes.navigateTo(context, Screens.Login, true)
    }



}


object Values {
    const val FONT_FAMILY = "Sans-serif"
    const val FONT_TOHOMA = "Tahoma"
    const val GENEL_DENEME = "Genel Deneme"
    const val KONU_TEST = "Konu Testi"
    const val KITAP_OKUMA = "Kitap Okuma"


}

object Images {
    const val AppIcon = "/img/ogrenci_w.png"
    const val Ogrenci = "/img/ogrenci.png"
    const val Ogretmen = "/img/ogretmen.png"
    const val Veli = "/img/ogrenci_veli.png"
    const val AppBackGround1 = "/img/etut_background1.jpg"
    const val AppBackGround2 = "/img/etut_background2.jpg"
    const val OgrenciEkle = "/img/ogrenci_ekle.jpg"
    const val OgrenciW = "/img/ogrenci_w.png"
    const val OgretmenW = "/img/ogretmen_w.png"
    const val VeliW = "/img/veli_w.png"
    const val Sinif = "/img/sinif.png"
    const val SinifW = "/img/sinif_w.png"


    const val Ozet = "/img/ozet.png"
    const val Ders = "/img/ders_program.png"
    const val Etut = "/img/etut_program.png"

    const val Kuran = "/img/kuran.png"
    const val PdfIcon = "/img/pdficon.png"
    const val TempImgDir = "/imgtemp/"
    const val EtutDocs = "/etutdocs/"

    const val EtutBook = "/img/etut_book.png"

    const val imgPath1 = "./"
    const val imgPath2 = "../"
}


