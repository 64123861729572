package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable



@Serializable
data class NewSnfEtutBinder(
    var sid: Long = AppData.appUser.id,
    var snfid: Long,
    val etut: NewEtutItem,
    val docs: List<String> = listOf(),
)


@Serializable
data class SnfInfo(
    val id: Long,
    var name: String,
    var code: String,
    var snf: Int
)

@Serializable
data class SnfUserData(
    val snf: SnfInfo,
    var ogr: OgrenciInfo?
)

@Serializable
data class SnfReportsData(
    val start: String = "",
    val end: String = "",
    val reports: List<SnfReportItem> = listOf(),
)

@Serializable
data class SnfReportItem(
    val uuid: String,
    val name: String,
    val ders: String,
    val konu: String,
    val soru: Int,
    val dogru: Int,
    val yanlis: Int,
    val net: Double
)

@Serializable
data class SnfEtutReport(
    val name: String,
    val soru: Int,
    val dogru: Int,
    val yanlis: Int,
    val net: Double
)


enum class SnfPeriod(val text: String){
    Daily("Günlük"),
    Weekly("Hafta"),
    Monthly("Aylık")

}

@Serializable
data class SnfEtutItem(
    val eid: Long,
    val type: Int,
    val did: Long,
    val ders: String,
    val konu: String,
    val notlar: String,
    val kid: Long,
    val kaynak: String,
    val sayfa: String,
    val soru: Int,
    val cevap: String?, // Nullable type in Kotlin
    val konupuan: Int,
    val link: String,
    var belge: String,
    val snfid: Long,
    val tarih: String,
    val saat: String,
    val done: Int,
    val notdone: Int
)

@Serializable
data class SnfKaynak(
    val id: Long = 0L,
    val sid: Long = 0L,
    val kid: Long = 0L,
    val did: Long = 0L,
    var ders: String,
    val isim: String,
    val sayfa: Int,
    val kapak: String = "",
    val renk: Int = 0,
)


@Serializable
data class NewSnfKaynakBinding(
    var sid: Long = AppData.appUser.id,
    val snfid: Long,
    val snf: Int,
    val kaynak: SnfKaynak,
)