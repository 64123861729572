package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.BSTimeInput
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.MyBSSelect
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ders.DersGunuSaati
import com.kelimesoft.etutpro.pages.etut.AddEtutBottomButtons
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSCheckbox
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSelectElement


@Composable
fun AddSnfVideo(
    snfVm: SinifVM,
    etut: SnfEtutItem? = null,
    date: String,
    saat: String,
    onClose: () -> Unit,
    onEdit: (SnfEtutItem) -> Unit,
    onAdd: (NewEtutItem) -> Unit
) {
    var kaynakList: List<SnfKaynak> by remember { mutableStateOf(listOf()) }
    var dersList = snfVm.snfDersList.map { it.dersad }

    var ders by remember { mutableStateOf("") }
    var konu by remember { mutableStateOf("") }
    var kanalBilgi by remember { mutableStateOf("") }
    var sureDk by remember { mutableStateOf(0) }
    var link by remember { mutableStateOf("") }

    var saati by remember { mutableStateOf(saat) }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }
    var disableDers by remember { mutableStateOf(false) }

    LaunchedEffect(alertModel){
        if (alertModel != null){
            delay(3000)
            alertModel = null
        }
    }

    fun findEtutStartTime(){
        saati = AppData.ViewModel.etutVM.findEtutStartTime(date)
    }

    fun findDerdId(): Long{
        snfVm.snfDersList.firstOrNull { it.dersad == ders }?.let { drs ->
            return drs.oid
        }
        return 0
    }

    LaunchedEffect(true) {
        if (etut != null) {
            ders = etut.ders
            konu = etut.konu
            link = etut.link
            saati = etut.saat
            sureDk = etut.konupuan * 4
            delay(200)
            try {
                (document.getElementById("ders-select") as HTMLSelectElement).value = ders
            } catch (_: Exception) {
            }
            disableDers = true
        }else if (saati == "") {
            findEtutStartTime()
        }
    }

    fun saveEtut() {
        if (link.isEmpty()){
            alertModel = AlertModel("Video linkini ekleyiniz!", AlertIcon.Warning, AlertStyle.Danger)
            return
        }
        var etutDate = etut!!.tarih
        var etutlink = link

        val etutItem = etut.copy(
            saat = saati,
            konu = konu,
            konupuan = if (sureDk > 4) sureDk / 4 else 1,
            kaynak = kanalBilgi,
            type = EtutType.Konu.ordinal,
            link = etutlink,
            tarih = etutDate
        )
        onEdit(etutItem)
    }

    fun addEtut() {
        if (ders.isEmpty() ||konu.isEmpty()){
            alertModel = AlertModel("Ders ve konu seçiniz!", AlertIcon.Warning, AlertStyle.Danger)
            return
        }else if (link.isEmpty()){
            alertModel = AlertModel("Video linkini ekleyiniz!", AlertIcon.Warning, AlertStyle.Danger)
            return
        }
        val etutItem = NewEtutItem(
            type = EtutType.Video.type,
            did = findDerdId(),
            ders = ders,
            konu = konu,
            kaynak = kanalBilgi,
            puan = if (sureDk > 4) sureDk / 4 else 1,
            link = link,
            tarih = date,
            saat = saati
        )
        onAdd(etutItem)
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(520.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .overflow(Overflow.Hidden)
    ) {

        DialogTitle("Video Ders Ekle", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("etutadd-form")
                .toAttrs()
        ) {

            Row(
                modifier = Modifier.margin(left = 2.px)
                    .margin(bottom = 4.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                MyBSSelect(
                    modifier = Modifier
                        .attrsModifier {
                            attr("value", ders)
                        }
                        .fillMaxWidth(),
                    id = "ders-select",
                    items = dersList,
                    disabled = disableDers,
                    placeholder = "Ders Seçiniz",
                    onItemSelect = { idx, text ->
                        if (idx == -1) {
                            ders = ""
                        } else {
                            try {
                                dersList.find { it == text }?.let {
                                    ders = it
                                }
                            } catch (_: Exception) {
                                ders = ""
                            }
                        }
                    }
                )

                BSTimeInput(
                    modifier = Modifier
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    placeholder = "Ders Saati:",
                    value = saati,
                    onValueChange = {
                        saati = it
                    }
                )
            }

            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = konu,
                placeholder = "Konu",
                required = true,
                onValueChange = {
                    konu = it
                }
            )
        }

        BSInput(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = 3.px)
                .margin(bottom = 14.px),
            type = InputType.Text,
            value = link,
            required = true,
            placeholder = "Video linkini buraya ekleyiniz",
            onValueChange = {
                link = it
            }
        )

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
            ) {
            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(leftRight = 3.px)
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = kanalBilgi,
                placeholder = "Kanal veya Öğ.Adı",
                onValueChange = {
                    kanalBilgi = it
                }
            )

            DersGunuSaati("Süresi (dk)", sureDk) {
                sureDk = it
            }
        }

        AddEtutBottomButtons(onClose = {
            onClose()
        }, onSave = {
            if (etut != null){
                saveEtut()
            }else{
                addEtut()
            }

        })
    }

    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style
        )
    }

}






