package com.kelimesoft.etutpro.network

import com.kelimesoft.etutpro.models.*
import kotlinx.serialization.Serializable


@Serializable
data class BasicRequest(
    var id: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid.ifEmpty {
        AppStorage.getString(AppStorage.userUUID)
    }
)

@Serializable
data class LoginRequest(
    var user: String,
    var pass: String
)

@Serializable
data class PassChangeRequest(
    var id: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid,
    var old: String,
    var new: String
)

@Serializable
data class AddSnfRequest(
    var id: Long = AppData.appUser.id,
    var uuid: String = AppData.appUser.uuid,
    var snfad: String,
    var snf: Int
)

@Serializable
data class SnfReportRequest(
    var snfid: Long,
    var start: String,
    var period: Int
)

@Serializable
data class EtutReportRequest(
    var snfid: Long,
    var eid: Long
)


@Serializable
data class EtutDoneRequest(
    var id: Long,
    var type: Int,
    var done: Int,
    val dogru: Int,
    val yanlis: Int,
    val net: Double
)

@Serializable
data class EtutDoneWithCevap(
    var id: Long,
    var snf: Int,
    var usercevap: String,
)


@Serializable
data class SnfEtutListRequest(
    val snfid: Long,
    val start: String
)

@Serializable
data class SnfKaynakRequest(
    var sid: Long = AppData.appUser.id,
    var snfid: Long,
    var snf: Int
)

@Serializable
data class AddSnfOgrBinder(
    var snf: SnfInfo,
    val name: String,
    val code: String
)
