package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.etutpro.components.BSNumberInput
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.MyBSSelect
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.ImageUploadResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSCheckbox
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get

@Composable
fun AddSnfKaynak(
    snfVM: SinifVM,
    onClose: () -> Unit,
    onAdded: () -> Unit
) {


    var kaynadAd by remember { mutableStateOf("") }
    var kapakImg by remember { mutableStateOf("") }
    var sayfaSay by remember { mutableStateOf(0) }
    var uuid: String? by remember { mutableStateOf(null) }
    var dersler: List<String> by remember { mutableStateOf(listOf()) }
    var dersSelected by remember { mutableStateOf("") }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    val uuidList: MutableSet<String> by remember { mutableStateOf(mutableSetOf()) }

    var showMultipleAdd by remember { mutableStateOf(false) }

    val scope = rememberCoroutineScope()

    val selectDers = snfVM.secilenKaynakDersAdi
    val selectKaynak = snfVM.secilenDersKaynak

    fun createDersList() {
        dersler = snfVM.snfDersList
                .map { it.dersad }.toList().sortedBy { it }

    }

    fun resizeAndUploadImage(file: File) {
        val maxSize = 400
        val reader = FileReader()
        reader.onload = { readerEvent ->
            val image = Image()
            image.onload = {
                // Resize the image
                val canvas = document.createElement("canvas") as HTMLCanvasElement
                val context = canvas.getContext("2d") as? CanvasRenderingContext2D
                var width = image.width.toDouble()
                var height = image.height.toDouble()

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width
                        width = maxSize.toDouble()
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height
                        height = maxSize.toDouble()
                    }
                }
                canvas.width = width.toInt()
                canvas.height = height.toInt()
                context?.drawImage(image, 0.0, 0.0, width, height)
                val dataUrl = canvas.toDataURL("image/jpeg")
                val clearBase64 = dataUrl.replace(Regex("^data:.+;base64,"), "")
                scope.launch {
                    RestApi.uploadBase64Image(clearBase64)?.let { res ->
                        Json.decodeFromString<ImageUploadResponse>(res).let { eres ->
                            if (eres.data != null) {
                                val flink = eres.data
                                kapakImg = flink
                            }
                        }
                    }
                }

                //console.log("image-selected")
            }
            if (readerEvent.target != null) {
                image.src = readerEvent.target.asDynamic().result.toString()
            }
            //image.src = readerEvent.target.asDynamic().result as String
        }
        reader.readAsDataURL(file)

    }


    fun acceptFileDrop(dropEvt: SyntheticDragEvent) {
        dropEvt.dataTransfer?.items?.let { items ->
            if (items[0]?.kind == "file") {
                val file = items[0]?.getAsFile()
                if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                    scope.launch {
                        resizeAndUploadImage(file)
                    }
                } else {
                    scope.launch {
                        alertModel = AlertModel("Resim dosyalarını ekleyebilirsiniz", AlertIcon.Warning, AlertStyle.Danger)
                        delay(3000)
                        alertModel = null
                    }
                }
            }

        }
    }

    LaunchedEffect(dersSelected, snfVM.snfDersList){
        if (dersSelected.isNotEmpty() && AppData.appUser.role > 0 ){
            snfVM.snfDersList.firstOrNull { it.dersad == dersSelected }?.let {
                showMultipleAdd = it.sid == 0L
            }
        }else{
            showMultipleAdd = true
        }
    }

    LaunchedEffect(Unit) {
        if (selectKaynak != null){
            dersSelected = selectKaynak.ders
            kaynadAd = selectKaynak.isim
            kapakImg = selectKaynak.kapak
            sayfaSay = selectKaynak.sayfa
        }else if (selectDers.isNullOrEmpty()){
            createDersList()
        }else{
            dersSelected = selectDers
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {
        DialogTitle("Sınıf Kaynak Ekle", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("kaynak-form")
                .toAttrs()
        ) {

            if (selectDers.isNullOrEmpty() && selectKaynak == null){
                MyBSSelect(
                    modifier = Modifier
                        .attrsModifier {
                            attr("value", "")
                        }
                        .fillMaxWidth(),
                    id = "ders-select",
                    items = dersler,
                    placeholder = "Ders Seçiniz",
                    onItemSelect = { idx, text ->
                        if (idx == -1){
                            dersSelected = ""
                        }else{
                            try {
                                dersler.find { it == text }?.let {
                                    dersSelected = it
                                }
                            } catch (_: Exception) {
                                dersSelected = ""
                            }
                        }
                    }
                )
            }else{
                SpanText("Seçilen Ders: $dersSelected", modifier = Modifier
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px)
                    .color(AppColors.DarkRed.opacity(0.8f))
                    .margin(bottom = 4.px)
                )
            }
            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = kaynadAd,
                placeholder = "Kaynak Adını giriniz",
                required = true,
                onValueChange = {
                    kaynadAd = it
                }
            )

            Row(modifier = Modifier.fillMaxWidth()
                .margin(bottom = 10.px),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Column(horizontalAlignment = Alignment.CenterHorizontally) {

                    Box(
                        modifier = Modifier.size(76.px)
                            .onDrop { dropevt ->
                                dropevt.preventDefault()
                                acceptFileDrop(dropevt)
                            }
                            .onDragOver {
                                it.preventDefault()
                                //fileExist = true
                            },

                        ) {
                        if (kapakImg.isNotEmpty()) {
                            val imglink = "${Images.TempImgDir}${kapakImg}"
                            Box(
                                modifier = Modifier.fillMaxSize(),
                                contentAlignment = Alignment.BottomCenter
                            ) {
                                Image(
                                    modifier = Modifier
                                        .fillMaxSize()
                                        .maxWidth(64.px)
                                        .borderRadius(r = 10.px)
                                        .objectFit(ObjectFit.Contain),
                                    src = imglink
                                )

                                BSButton(modifier = Modifier
                                    .margin(bottom = 8.px),
                                    text = "Sil",
                                    size = ButtonSize.Small,
                                    variant = ButtonVariant.DangerOutline,
                                    onClick = {
                                        kapakImg = ""
                                    }
                                )
                            }
                        }else{
                            BSButton(
                                modifier = Modifier
                                    .fontSize(14.px)
                                    .size(64.px)
                                ,
                                text = "Kapak Ekle",
                                variant = ButtonVariant.SecondaryOutline,
                                onClick = {
                                    (document.getElementById("file-select") as HTMLInputElement).click()
                                }
                            )

                        }

                        Input(
                            type = InputType.File,
                            attrs = Modifier
                                .id("file-select")
                                .display(DisplayStyle.None)
                                .margin(bottom = 10.px)
                                .toAttrs {
                                    attr("name", "files")
                                    attr("multiple", "false")
                                    attr("accept", "image/*")
                                    //attr("accept", "image/*")
                                    onInput { evt ->
                                        evt.target.files?.let { files ->
                                            for (i in 0..<files.length) {
                                                val file = files[0]
                                                if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                                                    scope.launch {
                                                        resizeAndUploadImage(file)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }


                        )
                    }

                }
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    SpanText("Toplam sayfa sayısı:",
                        modifier = Modifier.fillMaxWidth()
                            .color(AppColors.TextColor)
                            .fontSize(14.px)

                    )
                    BSNumberInput(
                        type = InputType.Number,
                        value = sayfaSay.toString(),
                        placeholder = "Toplam Sayfa Sayısı",
                        required = true,
                        onValueChange = {
                            sayfaSay = it
                        }
                    )
                }

            }

            Row(horizontalArrangement = Arrangement.SpaceBetween) {
                BSButton(
                    text = "Kapat",
                    variant = ButtonVariant.DangerOutline,
                    onClick = {
                        onClose()
                    }
                )
                BSButton(
                    text = "Ekle",
                    variant = ButtonVariant.Success,
                    onClick = {
                        var kayId = selectKaynak?.id ?: 0L
                        var kaySid = selectKaynak?.sid ?: AppData.appUser.id
                        var kayKid = selectKaynak?.kid ?: 0L
                        var kayDid = selectKaynak?.did ?: snfVM.snfDersList.first { it.dersad == dersSelected }.oid
                        val yKaynak = SnfKaynak(id = kayId,  sid = kaySid, kid = kayKid, did = kayDid, ders = dersSelected,
                            isim = kaynadAd, sayfa = sayfaSay, kapak = kapakImg)
                        scope.launch {
                            val res = snfVM.addNewSnfKaynak(yKaynak)
                            if (res.result){
                                onAdded()
                            }else{
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }

                    }
                )
            }

        }

    }


    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style

        )
    }

}