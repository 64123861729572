package com.kelimesoft.etutpro.pages.ozet

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.models.AppData.ViewModel.ogrenciVM
import com.kelimesoft.etutpro.pages.ders.DersGunuSaati
import com.kelimesoft.etutpro.utils.dateToDbStr
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.forms.BSSelect
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.w3c.dom.HTMLSelectElement
import kotlin.js.Date

@Composable
fun HedefEkle(
    period: HedefPeriod?= null,
    hedef: Hedef? = null,
    onClose: () -> Unit,
    onSave: (Hedef) -> Unit
) {
    val dersVm = AppData.ViewModel.dersVM
    var dersler: List<String> by remember { mutableStateOf(listOf()) }
    var hdfType by remember { mutableStateOf(HedefType.KonuTesti)}
    var hdfPeriod by remember { mutableStateOf(period ?: HedefPeriod.Hafta)}
    var ders by remember { mutableStateOf("") }
    var notlar by remember { mutableStateOf("") }
    var soru by remember { mutableStateOf(0) }

    LaunchedEffect(true){
        var dlist: MutableList<String>
        if (dersVm.dersList.isEmpty()){
            val ogrenciVM = AppData.ViewModel.ogrenciVM
            var uuid: String? = null
            if (AppData.appUser.role > 0){
                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
            }
            dersVm.getDersList(uuid)
            dlist = dersVm.dersList
                .map { it.dersad }.toMutableList()
        }else{
            dlist = dersVm.dersList.map { it.dersad }.toMutableList()
        }

        dlist.add(Values.KITAP_OKUMA)
        dersler = dlist.toList()

        if (hedef != null){
            ders = hedef.ders
            notlar = hedef.notlar
            soru = hedef.soru
            hdfType = HedefType.entries.find { it.ordinal == hedef.type.ordinal } ?: HedefType.KonuTesti
            val select = (document.getElementById("ders-select") as HTMLSelectElement)
            select.value = ders
        }

    }

    fun saveHedef() {
        var hedefId = 0L
        var hedefStart = Date().dateToDbStr()
        var hedefEnd = "2030-12-31"
        var uuid = ""
        if (hedef != null){
            hedefId = hedef.id
            hedefStart = hedef.start
            hedefEnd = hedef.end
        }else{
            if (AppData.appUser.role > 0) {
                if (ogrenciVM.selectedOgrenci != null) {
                    uuid = ogrenciVM.selectedOgrenci!!.uuid
                }
            }
        }
        val nhedef = Hedef(hedefId, hdfPeriod, hdfType, ders, soru, notlar, hedefStart, hedefEnd)
        onSave(nhedef)
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("${hdfPeriod.name} Yeni Hedef", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("hedef-form")
                .toAttrs()
        ) {
            if (period == null){
                Column {
                    SpanText("Hedefiniz İçin Period Seçiniz: ", modifier = Modifier.color(AppColors.TextColor))
                    Row(modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween) {
                        HedefPeriod.entries.forEach { period ->
                            BSButton(
                                modifier = Modifier.maxHeight(40.px)
                                    .margin(leftRight = 6.px, bottom = 10.px),
                                text = period.name,
                                size = ButtonSize.Small,
                                variant = if (period == hdfPeriod) ButtonVariant.Secondary else ButtonVariant.SecondaryOutline
                            ) {
                                hdfPeriod = period
                            }

                        }
                    }
                }
            }

                Column {
                    SpanText("${hdfPeriod.name} Hedefiniz Nedir: ", modifier = Modifier.color(AppColors.TextColor))
                    Row(modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween) {
                        HedefType.entries.forEach { type ->
                            BSButton(
                                modifier = Modifier.maxHeight(40.px)
                                    .margin(leftRight = 6.px, bottom = 10.px),
                                text = type.text,
                                size = ButtonSize.Small,
                                variant = if (type == hdfType) ButtonVariant.Secondary else ButtonVariant.SecondaryOutline
                            ) {
                                hdfType = type
                            }

                        }
                    }
                }



            if (hdfType == HedefType.KonuTesti){
                BSSelect(
                    modifier = Modifier
                        .attrsModifier {
                            attr("value", ders)
                        }
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    id = "ders-select",
                    items = dersler,
                    placeholder = "Ders Seçiniz",
                    onItemSelect = {idx, text ->
                        try {
                            dersler.find { it == text }?.let {
                                ders = it
                            }
                        }catch (_:Exception){}
                    }
                )

                DersGunuSaati("${hdfPeriod.name} Soru sayısı", soru) {
                    soru = it
                }
            }else if (hdfType == HedefType.KitapOkuma){
                DersGunuSaati("${hdfPeriod.name} Sayfa sayısı", soru) {
                    soru = it
                }
            }else if (hdfType == HedefType.GenelDeneme){
                DersGunuSaati("${hdfPeriod.name} Soru sayısı", soru) {
                    soru = it
                }
            }

            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = notlar,
                placeholder = "Not ekleyebilirsiniz.",
                onValueChange = {
                    notlar = it
                }
            )

        }


        Row(modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween) {
            BSButton(
                text = "Kapat",
                variant = ButtonVariant.SecondaryOutline,
                onClick = {
                    onClose()
                }
            )

            BSButton(
                text = "Kaydet",
                variant = ButtonVariant.Success,
                onClick = {
                    saveHedef()
                }
            )
        }
    }

}