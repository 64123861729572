package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.ErrorText
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.forms.BSSelect
import com.stevdza.san.kotlinbs.models.InputValidation
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AddNewSnf(
    snfVM: SinifVM,
    onClose: () -> Unit,
) {
    val scope = rememberCoroutineScope()
    var snfAd by remember { mutableStateOf("") }
    var snfSnf by remember { mutableStateOf(-1) }
    //var snfCode by remember { mutableStateOf("") }
    var errText by remember { mutableStateOf("") }
    var nameValid by remember { mutableStateOf(InputValidation()) }
    var snfInfo: SnfInfo? by remember { mutableStateOf(null) }


    fun saveSnfInfo(){
        if (!Funcs.checkForm("snf-form")) {
            errText = "Formu eksiksiz doldurunuz!"
            return
        }else if (snfAd.length < 4){
            errText = "Sınıf adı en az 4 karaterden oluşmalıdır"
            return
        }else if (snfSnf < 0){
            errText = "Sınıf Derecesini Seçiniz!"
            return
        }
        scope.launch {
            snfVM.addNewSnf(snfAd, snfSnf)?.let {
                snfInfo = it
                snfVM.getSnfData()
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 6.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("Yeni Sınıf", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("snf-form")
                .toAttrs()
        ) {
            if (snfInfo == null) {
                BSInput(
                    modifier = Modifier

                        .fillMaxWidth()

                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = snfAd,
                    placeholder = "Sınıfın Adı (örnek: hattat-8A)",
                    required = true,
                    validation = nameValid,
                    //InputValidation(isInvalid = snfAd.length < 2, invalidFeedback = "En az 3 karakter giriniz"),
                    //disabled = if (ders != null && ders.sid != AppData.appUser.id) true else false,
                    onValueChange = {
                        snfAd = it.replace(" ", "")
                        if(errText.isNotEmpty()){
                            errText = ""
                        }
                    }
                )

                    BSSelect(
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(bottom = 14.px),
                        items = OgrenciSnf.snfNames(),

                        placeholder = "Sınıfınızı Seçiniz",
                        onItemSelect = {idx, text ->
                            try {
                                OgrenciSnf.entries.find { it.text == text }?.let {
                                    snfSnf = it.snf
                                }
                            }catch (_:Exception){}
                            //console.log(regSnf)
                        }
                    )

            }else {
                Column(modifier = Modifier
                    .padding(6.px)
                    .borderRadius(r = 6.px)
                    .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                ) {
                    SpanText("Sınıf Katılış Bilgileri")
                    VDivider()
                    Row(modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceAround) {
                        SpanText("Sınıf Adı: ")
                        SpanText(snfInfo!!.name, modifier = Modifier.fontSize(18.px).color(AppColors.NumGreen))
                    }
                    Row(modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceAround) {
                        SpanText("Sınıf Giriş Kodu: ")
                        SpanText(snfInfo!!.code, modifier = Modifier.fontSize(18.px).color(AppColors.NumGreen))
                    }
                }


            }

            //VDivider()

            Row(modifier = Modifier.margin(top = 6.px),
                horizontalArrangement = Arrangement.End
                ) {
                if (snfInfo == null){
                    BSButton(
                        text = "Sınıf Oluştur",
                        variant = ButtonVariant.Success,
                        onClick = {
                            saveSnfInfo()
                        }, modifier = Modifier.margin(2.px)
                    )
                }else {
                    BSButton(
                        text = "Kapat",
                        variant = ButtonVariant.Danger,
                        onClick = {
                            onClose()
                        }, modifier = Modifier.margin(2.px)
                    )
                }

            }
        }
    }

    if (errText.isNotEmpty()){
        ErrorText(errText)
    }
}


@Composable
fun SnfInfoDialog(
    snfVM: SinifVM,
    onClose: () -> Unit,
) {
    val selectSnf = snfVM.selectedSnf
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 6.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("Sınıf Katılış Bilgileri", fontSize = 15, onClose = onClose)
        Box(modifier = Modifier
            .fillMaxWidth()
            .padding(8.px)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(AppColors.Gray.opacity(0.2f))
                    .padding(6.px)
            ) {

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceAround
                ) {
                    SpanText("Sınıf Adı: ", modifier = Modifier.fontSize(16.px).color(AppColors.TextColor))
                    SpanText(selectSnf!!.name, modifier = Modifier.fontSize(18.px).color(AppColors.DarkRed))
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceAround
                ) {
                    SpanText("Sınıf Giriş Kodu: ", modifier = Modifier.fontSize(16.px).color(AppColors.TextColor))
                    SpanText(selectSnf!!.code, modifier = Modifier.fontSize(18.px).color(AppColors.DarkRed))
                }
            }
        }
        VDivider()
        Span(attrs = Modifier
            .fontSize(14.px).color(AppColors.TextColor)
            .padding(leftRight = 10.px, topBottom = 3.px)
            .toAttrs()
        ) {
            Text("Öğrencileriniz, ")
            Span(attrs = Modifier
                .fontWeight(FontWeight.SemiBold)
                .toAttrs()
            ) {
                Text("Sınıf adı ve Giriş kodu")
            }
            Text(" ile Öğrenci veya Veli hesaplarından ")
            Span(attrs = Modifier
                .fontWeight(FontWeight.SemiBold)
                .toAttrs()
            ) {
                Text("Sınıfa Katıl butonu")
            }
            Text(" ile katılım sağlayabilir.")
        }
    }

}