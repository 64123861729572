package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.ApiResponse
import com.kelimesoft.etutpro.network.BoolResponse
import com.kelimesoft.etutpro.network.OgrenciListResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.utils.Funcs
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData


class ProfileVM: BaseVM() {
    var alertModel: AlertModel? by mutableStateOf(null)

    suspend fun changeUserPass(old: String, new1: String, new2: String): Boolean {
        var errText = ""
        if (new1 !== new2){
            errText = "Yeni şifreniz ve tekrarı aynı değil!"
        }else if (new1.length < 6){
            errText = "Yeni şifreniz en az 7 karakterden oluşmalı!"
        }else if (new1.isEmpty()){
            errText = "Önceki şifrenizi giriniz!"
        }
        if (new1 == old){
            return true
        }
        if (errText.isNotEmpty()){
            alertModel = AlertModel(errText, AlertIcon.Warning, AlertStyle.Danger)
            delay(3000)
            alertModel = null
            return false
        }else{
            RestApi.changeUserPass(old, new1)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { ores ->
                    if (ores.data != null) {
                        alertModel = AlertModel("Şifreniz başarıyla değiştirildi.",
                            AlertIcon.Checkmark, AlertStyle.Success
                        )
                        delay(3000)
                        alertModel = null
                        return true
                    } else {
                        alertModel = AlertModel(ores.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                        delay(3000)
                        alertModel = null
                    }
                }
            }
        }
        return false

    }



}