import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.kelimesoft.etutpro.pages.HomePage() }
        ctx.router.register("/ders") { com.kelimesoft.etutpro.pages.DersPage() }
        ctx.router.register("/etut") { com.kelimesoft.etutpro.pages.EtutPage() }
        ctx.router.register("/flip") { com.kelimesoft.etutpro.pages.Flip() }
        ctx.router.register("/giris") { com.kelimesoft.etutpro.pages.Giris() }
        ctx.router.register("/kaynak") { com.kelimesoft.etutpro.pages.Kaynak() }
        ctx.router.register("/ozet") { com.kelimesoft.etutpro.pages.OzetPage() }
        ctx.router.register("/page404") { com.kelimesoft.etutpro.pages.Page404() }
        ctx.router.register("/profil") { com.kelimesoft.etutpro.pages.Profil() }
        ctx.router.register("/snf") { com.kelimesoft.etutpro.pages.SinifPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.ALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("close-button", com.kelimesoft.etutpro.styles.CloseButtonStyle)
        ctx.theme.registerStyle("row-button", com.kelimesoft.etutpro.styles.RowButtonStyle)
        ctx.theme.registerStyle("small-add-buton", com.kelimesoft.etutpro.styles.SmallAddButonStyle)
        ctx.theme.registerStyle("contex-menu-item",
                com.kelimesoft.etutpro.styles.ContexMenuItemStyle)
        ctx.theme.registerStyle("chevron-button", com.kelimesoft.etutpro.styles.ChevronButtonStyle)
        ctx.theme.registerStyle("role-button", com.kelimesoft.etutpro.styles.RoleButtonStyle)
        ctx.theme.registerStyle("app-bar-button", com.kelimesoft.etutpro.styles.AppBarButtonStyle)
        ctx.theme.registerStyle("snf-katil-button",
                com.kelimesoft.etutpro.styles.SnfKatilButtonStyle)
        ctx.theme.registerStyle("nav-text", com.kelimesoft.etutpro.styles.NavTextStyle)
        ctx.theme.registerStyle("faaliyet-ders-chev",
                com.kelimesoft.etutpro.styles.FaaliyetDersChevStyle)
        ctx.theme.registerStyle("etut-drop-buton", com.kelimesoft.etutpro.styles.EtutDropButonStyle)
        ctx.theme.registerStyle("snf-data-item-button",
                com.kelimesoft.etutpro.styles.SnfDataItemButton)
        ctx.theme.registerStyle("ozet-ders-col", com.kelimesoft.etutpro.styles.OzetDersColStyle)
        ctx.theme.registerStyle("ozet-etut-row", com.kelimesoft.etutpro.styles.OzetEtutRowStyle)
        ctx.theme.registerStyle("search-bar-styles", com.kelimesoft.etutpro.styles.SearchBarStyles)
        ctx.theme.registerStyle("drop-down", com.kelimesoft.etutpro.styles.DropDownStyle)
        ctx.theme.registerStyle("drop-menu-item", com.kelimesoft.etutpro.styles.DropMenuItemStyle)
        ctx.theme.registerStyle("etut-row", com.kelimesoft.etutpro.styles.EtutRowStyle)
        ctx.theme.registerStyle("left-pane-button",
                com.kelimesoft.etutpro.styles.LeftPaneButtonStyle)
        com.kelimesoft.etutpro.initSilk(ctx)
        com.kelimesoft.etutpro.initializeBreakpoints(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "EtutPro"))
    renderComposable(rootElementId = "root") {
        com.kelimesoft.etutpro.MyApp {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
