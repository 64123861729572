package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.EtutItem
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.styles.SnfKatilButtonStyle
import com.kelimesoft.etutpro.viewmodels.EtutVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

//done 0 not done, 1 dogru, 2 yanlis, 3 boş
data class TestCevap(
    val soru: Int,
    val dcev: String = "X",
    val ucev: String = "Y",
) {
    var cevap by mutableStateOf("")
}


@Composable
fun TestSonucuGir(
    etutVM: EtutVM,
    onComplete: () -> Unit,
    onClose: () -> Unit

) {
    var cevapList: SnapshotStateList<TestCevap> = remember { mutableStateListOf() }
    var errText by remember { mutableStateOf("") }
    fun createCevapString(){
        if (!etutVM.textGirEtutItem!!.done) {
            var userCevap = ""
            cevapList.forEach { cv ->
                userCevap += if (cv.cevap.isEmpty()) {
                    "-"
                } else {
                    cv.cevap.lowercase()
                }
            }
            etutVM.textGirEtutItem!!.usercevap = userCevap
        }
    }
    fun compareCevaps() {
        val anahtar = etutVM.textGirEtutItem!!.cevap
        val usercevap = etutVM.textGirEtutItem!!.usercevap
        if (anahtar!!.length != usercevap!!.length) {
            return
        }
        for (i in anahtar.indices) {
            val tcev = TestCevap(soru = i+1, dcev = anahtar[i].uppercase(), ucev = usercevap[i].uppercase())
            cevapList.add(tcev)
        }
    }

    LaunchedEffect(Unit) {
        if (etutVM.textGirEtutItem!!.done){
            compareCevaps()
        }else if (etutVM.textGirEtutItem!!.usercevap != null){
                etutVM.textGirEtutItem!!.usercevap!!.forEachIndexed { i, uc ->
                    val c = TestCevap(soru = i + 1)
                    c.cevap = if(uc == '-') "" else uc.uppercase()
                    cevapList.add(c)
                }
        }else{
            for (i in 1..etutVM.textGirEtutItem!!.soru) {
                cevapList.add(TestCevap(i))
            }
        }
    }
    DialogView {
        Column(
            modifier = Modifier
                .position(Position.Absolute)
                .top(20.px)
                .maxWidth(400.px)
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .padding(4.px)
                .borderRadius(r = 8.px)
                .backgroundColor(Colors.White)
                .overflow(Overflow.Hidden),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            DialogTitle("Test Sonucu", onClose = {
                createCevapString()
                onClose()
            })
            Column(modifier = Modifier.fillMaxWidth()
                .backgroundColor(AppColors.Gray.opacity(0.2f))
                .borderRadius(r = 4.px)
                .padding(4.px),
            ) {
                cevapList.forEach { cev ->
                    cevapRow(cev, etutVM.textGirEtutItem!!.done)
                }

            }
            if (!etutVM.textGirEtutItem!!.done){
                VDivider()
                if (errText.isNotEmpty()){
                    SpanText(errText,
                        modifier = Modifier
                            .fontSize(FontSize.Smaller)
                            .color(AppColors.DarkRed)
                    )
                }
                BSButton(
                    modifier = Modifier.margin(topBottom = 4.px),
                    text = "Tamamla",
                    variant = ButtonVariant.PrimaryOutline
                ){
                    etutVM.textGirEtutItem?.let { et ->
                        createCevapString()
                        if (et.usercevap!!.length < et.soru / 2){
                            errText = "Testin büyük bir bölümünü henüz cevaplamadınız!"
                        }else{
                            onComplete()
                        }

                    }

                }
            }

        }
    }

    LaunchedEffect(errText){
        if (errText.isNotEmpty()){
            delay(3000)
            errText = ""
        }
    }

}

@Composable
fun cevapRow(cevap: TestCevap, done: Boolean, options: Int = 4) {
    var cevList: List<String> = listOf("A", "B", "C", "D")
    if (options == 5){
        cevList = listOf("A", "B", "C", "D", "E")
    }
    fun getColor(ctext: String) : CSSColorValue {
        if (done){
            if (ctext == cevap.dcev && cevap.ucev == cevap.dcev){
                return AppColors.NumGreen2
            }else if (ctext == cevap.dcev){
                return AppColors.WaterfallGreen.opacity(0.7f)
            }else if (ctext == cevap.ucev){
                return AppColors.DarkRed
            }
        }else {
            if (cevap.cevap == ctext) {
                return AppColors.DetailBlue
            }
        }
        return Colors.Transparent
    }
    Row(
        modifier = Modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        SpanText(cevap.soru.toString(), modifier = Modifier
            .width(40.px)
            .padding(right = 6.px)
            .fontSize(FontSize.Small)
            .textAlign(TextAlign.End)
        )
        cevList.forEach {ctext ->
            Button(
                attrs = Modifier
                    .borderRadius(r = 8.px)
                    .margin(leftRight = 4.px, topBottom = 1.px)
                    .border(width = 1.px, style = LineStyle.Solid, color = AppColors.TextColor)
                    .backgroundColor(getColor(ctext))
                    .onClick {
                        if(ctext == cevap.cevap){
                            cevap.cevap = ""
                        }else{
                            cevap.cevap = ctext
                        }

                    }
                    .toAttrs()
            ) {
                Span(
                    attrs = Modifier
                        .margin(leftRight = 3.px)
                        .padding(topBottom = 1.px)
                        //.color(AppColors.ViewWhite)
                        .fontSize(13.px).toAttrs()
                ) {
                    Text(ctext)
                }
            }
        }


    }
}




