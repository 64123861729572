package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.SnfPeriod
import com.kelimesoft.etutpro.models.SnfReportsData
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.utils.round
import com.kelimesoft.etutpro.utils.rountTo
import com.kelimesoft.etutpro.viewmodels.AccumulatedReport
import com.kelimesoft.etutpro.viewmodels.SinifVM

import com.stevdza.san.kotlinbs.components.BSIconButton
import com.stevdza.san.kotlinbs.icons.BSIcons
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.varabyte.kobweb.browser.dom.observers.ResizeObserver
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.palette.SilkWidgetColorGroups
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement

@Composable
fun SnfOzet(snfVM: SinifVM) {
    val scope = rememberCoroutineScope()

    LaunchedEffect(snfVM.snfId) {
        snfVM.loading = true
        if (snfVM.snfId > 0L) {
            snfVM.getSnfReport()
        }
        snfVM.loading = false
    }

        TumSinif(snfVM,
            onBack = {
                scope.launch {
                    snfVM.previousPeriod()
                }

            }, onForward = {
                scope.launch {
                    snfVM.nextPeriod()
                }
            }, onPeriod = {
                scope.launch {
                    snfVM.periodChange(it)
                }

            })

}

@Composable
fun ScoreTable(
    snfVM: SinifVM
    //userList: Map<Pair<String, String>,List<AccumulatedReport>>,
    //dersMap: Set<String>?,
    //start: String, end: String
) {
    // Define table styles if necessary
    //var scoreHighest by remember { mutableStateOf(0.0) }
    var snfOrtalama by remember { mutableStateOf(0.0) }
    var highestNet by remember { mutableStateOf(0.0) }

    LaunchedEffect(snfVM.scoresByUuidAndName) {
        highestNet = 0.0
        var tnet = 0.0
        var soruCozenSay = 0
        snfVM.scoresByUuidAndName.forEach { (_, items) ->
            val userTop = items.sumOf { it.totalNet }
            tnet += userTop
            if (userTop > highestNet) {
                highestNet = userTop.rountTo(1)
            }
            if (userTop > 0){
                soruCozenSay += 1
            }
        }
        snfOrtalama = tnet / soruCozenSay
    }

    val tableStyle = Modifier
        .classNames("table", "table-stripped")
        .fillMaxWidth()

    val cellStyle = Modifier.padding(2.px)
        .textAlign(TextAlign.Center)
        .fontSize(14.px)

    val sayiStyle = Modifier.padding(1.px)
        .textAlign(TextAlign.Center)
        .fontSize(12.px)
        .color(AppColors.TextColor)

    ScoreTableTitle(snfVM.snfReportData?.start ?: "", snfVM.snfReportData?.end ?: "", snfOrtalama)

    Div(
        Modifier
            .overflow(overflowX = Overflow.Auto,
                overflowY = Overflow.Hidden)
            .scrollBehavior(ScrollBehavior.Smooth)
            .styleModifier {
                property("scrollbar-width", "thin")
            }
            .width(100.percent) // Ensure the container spans the entire width of the parent
            .toAttrs()
    ) {
        Table(
            tableStyle
                .margin(bottom = (-6).px)
                .padding(leftRight = 6.px)
                .toAttrs()
        ) {
            // Table Header Row
            Thead {
                Tr {
                    // First cell for the User name
                    Th(
                        cellStyle
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) { Text("Öğrenci") }
                    // Loop through dersList to create columns with sub-columns
                    snfVM.uniqueDersNames.forEach { ders ->
                        Th(cellStyle.toAttrs {
                            attr("colspan", "4")
                        }) { Text(ders) }
                    }
                    Th(
                        cellStyle
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) { Text("NetToplam") }
                }
            }
            Tbody {
                // Sub-header row for "Doğru", "Yanlış", and "Net"
                Tr(attrs = Modifier.classNames("table-primary").toAttrs()) {
                    // Empty cell under "User" column
                    Td(cellStyle.toAttrs()) { }

                    // Loop through dersList and add sub-columns "Doğru", "Yanlış", and "Net"
                    snfVM.uniqueDersNames.forEach { _ ->
                        Td(sayiStyle.toAttrs()) { Text("Soru") }
                        Td(sayiStyle.toAttrs()) { Text("D") }
                        Td(sayiStyle.toAttrs()) { Text("Y") }
                        Td(sayiStyle.toAttrs()) { Text("Net") }
                    }
                    Td(cellStyle.toAttrs()) { }
                }
                if (snfOrtalama > 0.0) {
                    snfVM.scoresByUuidAndName.forEach { (user, items) ->
                        if (snfVM.selectedOgr != null){
                            if (user.first == snfVM.selectedOgr!!.uuid){
                                UserTableRow(user, items, snfVM.uniqueDersNames, snfOrtalama, highestNet)
                            }
                        }else{
                            UserTableRow(user, items, snfVM.uniqueDersNames, snfOrtalama, highestNet)
                        }

                    }
                }
            }
        }
    }


}


// Example usage:
@Composable
fun TumSinif(
    snfVM: SinifVM,
    onBack: () -> Unit,
    onForward: () -> Unit,
    onPeriod: (SnfPeriod) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .overflow(Overflow.Hidden)
            .padding(leftRight = 4.px)
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 3.px),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            BSIconButton(
                icon = BSIcons.ARROW_LEFT,
                size = ButtonSize.Small

            ) {
                onBack()
            }

            RadioButtonGroup(snfVM.selectedPeriod) {
                onPeriod(it)
            }

            BSIconButton(
                icon = BSIcons.ARROW_RIGHT,
                size = ButtonSize.Small

            ) {
                onForward()
            }
        }
        ScoreTable(snfVM)


        SnfKonuTable(snfVM)
    }

}

@Composable
fun ScoreTableTitle(start: String, end: String, snfOrtalama: Double){
    Row(
        modifier = Modifier.fillMaxWidth()
            .fontSize(16.px)
            .fontWeight(FontWeight.Medium)
            .color(AppColors.TextColor)
            .padding(leftRight = 10.px, top = 6.px),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            SpanText("Ders, Soru Tablosu")
            SpanText(if (start == end) "($start)" else "($start - $end)",
                modifier = Modifier
                    .margin(left = 8.px)
                    .color(AppColors.DetailBlue.opacity(0.7f))
                    .fontSize(13.px)
            )
        }

        Span(
            attrs = Modifier.color(AppColors.DarkRed)
                .toAttrs()
        ) {
            Text("Sınıf Net Ortalaması: ")
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.SemiBold)
                    .margin(left = 8.px)
                    .toAttrs()
            ) {
                Text(snfOrtalama.round(1))
            }
        }
    }
}


@Composable
fun SnfKonuTable(snfVM: SinifVM) {
    var selectDers = remember { mutableStateOf(snfVM.uniqueDersNames.firstOrNull() ?: "") }

    val tableStyle = Modifier
        .classNames("table", "table-bordered")
        .fillMaxWidth()

    val cellStyle = Modifier.padding(2.px)
        .textAlign(TextAlign.Center)
        .fontSize(14.px)


    val totalStyle = Modifier.padding(2.px)
        .textAlign(TextAlign.Center)
        .fontWeight(FontWeight.SemiBold)
        .color(AppColors.TextColor)
        .fontSize(15.px)
    Column(
        modifier = Modifier
            .margin(top = 6.px)
            .padding(leftRight = 4.px)
            .backgroundColor(Colors.White.opacity(0.8f))
            .borderRadius(r= 4.px)
            .fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .fontSize(16.px)
                .fontWeight(FontWeight.Medium)
                .color(AppColors.TextColor)
                .padding(leftRight = 10.px, top = 6.px),
        ) {
            SpanText("Ders, Konu Tablosu")
            Spacer()
        }
        DersRadioGroup(snfVM.uniqueDersNames, selectDers)
    }




    Column(modifier = Modifier
        .fillMaxWidth()
        .maxHeight(460.px)
        .scrollBehavior(ScrollBehavior.Smooth)
        .styleModifier {
            property("scrollbar-width", "thin")
        }
        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
    ) {
            Table(
                tableStyle
                    .padding(leftRight = 6.px)
                    .toAttrs()
            ) {
                // Table Header Row
                Thead {
                    Tr {
                        // First cell for the User name
                        Td(
                            cellStyle
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) { Text("Konu") }
                        Td(
                            cellStyle
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) { Text("Soru") }
                        Td(
                            cellStyle
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) { Text("Doğru") }

                        Td(
                            cellStyle
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) { Text("Yanlış") }
                        Td(
                            cellStyle
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()
                        ) { Text("Net") }
                    }
                }
                Tbody {
                    if (snfVM.selectedOgr != null){
                        var userDersKonuMap: Map<String, Map<String, Map<String, Any>>> by remember { mutableStateOf(
                            mapOf()
                        ) }
                        LaunchedEffect(snfVM.selectedOgr){
                            userDersKonuMap = snfVM.groupByUserDersKonu(snfVM.selectedOgr!!.uuid)
                        }
                        userDersKonuMap.forEach { (ders, konuMap) ->
                            // For each `ders`, iterate over each `konu`
                            if (selectDers.value == ders) {
                                val groupedByKonu = konuMap["konu"]
                                val dersTotals = konuMap["ders"]


                                groupedByKonu?.forEach { (konu, stats) ->
                                    Tr {
                                        Td(cellStyle.textAlign(TextAlign.Start).toAttrs()) { Text(konu) }
                                        Td(
                                            cellStyle
                                                .color(AppColors.TextColor)
                                                .toAttrs()
                                        ) { Text((stats as? Map<*, *>)?.get("s").toString()) }
                                        Td(
                                            cellStyle
                                                .color(AppColors.NumGreen)
                                                .toAttrs()
                                        ) { Text((stats as? Map<*, *>)?.get("d").toString()) }
                                        Td(
                                            cellStyle
                                                .color(AppColors.DarkRed)
                                                .toAttrs()
                                        ) { Text((stats as? Map<*, *>)?.get("y").toString()) }
                                        Td(
                                            cellStyle
                                                .fontWeight(FontWeight.SemiBold)
                                                .color(AppColors.TextColor)
                                                .toAttrs()
                                        ) { Text(((stats as? Map<*, *>)?.get("n") as? Double)?.round(1) ?: "0.0") }
                                    }
                                }
                                Tr(
                                    attrs = Modifier
                                        .background(AppColors.Gray.opacity(0.4f))
                                        .toAttrs()
                                ) {
                                    Td(totalStyle.textAlign(TextAlign.Start).toAttrs()) { }
                                    Td(totalStyle.toAttrs()) { Text(dersTotals?.get("s").toString()) }
                                    Td(
                                        totalStyle
                                            .color(AppColors.NumGreen)
                                            .toAttrs()
                                    ) { Text(dersTotals?.get("d").toString()) }
                                    Td(
                                        totalStyle
                                            .color(AppColors.DarkRed)
                                            .toAttrs()
                                    ) { Text(dersTotals?.get("y").toString()) }
                                    Td(totalStyle.toAttrs()) { Text((dersTotals?.get("n") as? Double)?.round(1) ?: "0.0") }
                                }
                            }

                        }

                    }else{
                        snfVM.scoresMapByDersKonu.forEach { (ders, konuMap) ->
                            // For each `ders`, iterate over each `konu`
                            if (selectDers.value == ders) {
                                val groupedByKonu = konuMap["konu"]
                                val dersTotals = konuMap["ders"]


                                groupedByKonu?.forEach { (konu, stats) ->
                                    Tr {
                                        Td(cellStyle.textAlign(TextAlign.Start).toAttrs()) { Text(konu) }
                                        Td(
                                            cellStyle
                                                .color(AppColors.TextColor)
                                                .toAttrs()
                                        ) { Text((stats as? Map<*, *>)?.get("s").toString()) }
                                        Td(
                                            cellStyle
                                                .color(AppColors.NumGreen)
                                                .toAttrs()
                                        ) { Text((stats as? Map<*, *>)?.get("d").toString()) }
                                        Td(
                                            cellStyle
                                                .color(AppColors.DarkRed)
                                                .toAttrs()
                                        ) { Text((stats as? Map<*, *>)?.get("y").toString()) }
                                        Td(
                                            cellStyle
                                                .fontWeight(FontWeight.SemiBold)
                                                .color(AppColors.TextColor)
                                                .toAttrs()
                                        ) { Text(((stats as? Map<*, *>)?.get("n") as? Double)?.round(1) ?: "0.0") }
                                    }
                                }
                                Tr(
                                    attrs = Modifier
                                        .background(AppColors.Gray.opacity(0.4f))
                                        .toAttrs()
                                ) {
                                    Td(totalStyle.textAlign(TextAlign.Start).toAttrs()) { }
                                    Td(totalStyle.toAttrs()) { Text(dersTotals?.get("s").toString()) }
                                    Td(
                                        totalStyle
                                            .color(AppColors.NumGreen)
                                            .toAttrs()
                                    ) { Text(dersTotals?.get("d").toString()) }
                                    Td(
                                        totalStyle
                                            .color(AppColors.DarkRed)
                                            .toAttrs()
                                    ) { Text(dersTotals?.get("y").toString()) }
                                    Td(totalStyle.toAttrs()) { Text((dersTotals?.get("n") as? Double)?.round(1) ?: "0.0") }
                                }
                            }

                        }
                    }

                }


        }
    }

}


@Composable
fun UserTableRow(
    user: Pair<String, String>,
    items: List<AccumulatedReport>,
    dersMap: Set<String>?,
    snfOrt: Double,
    highOrt: Double
) {
    val cellStyle = Modifier.padding(2.px)
        .textAlign(TextAlign.Center)
        .fontSize(14.px)

    var userNet by remember { mutableStateOf(0.0) }
    var userSuccessfull by remember { mutableStateOf(false) }

    LaunchedEffect(items) {
        var tnet = 0.0
        items.forEach {
            tnet += it.totalNet
        }
        userNet = tnet.rountTo(1)
    }
    LaunchedEffect(snfOrt, userNet) {
        userSuccessfull = userNet > snfOrt * 1.1
    }

    Tr(attrs = Modifier
        .thenIf(userSuccessfull) {
            Modifier.classNames("table-success")
        }
        .toAttrs()) {
        val (uuid, name) = user

        Td(
            cellStyle
                .textAlign(TextAlign.Start)
                .toAttrs()
        ) { Text(name) }

        dersMap?.forEach { ders ->
            var soru = 0
            var dogru = 0
            var yanlis = 0
            var net = 0.0
            items.find { it.ders == ders }?.let { item ->
                soru = item.totalSoru
                dogru = item.totalDogru
                yanlis = item.totalYanlis
                net = item.totalNet
            }
            Td(
                cellStyle
                    .borderLeft((0.3).px, LineStyle.Solid, AppColors.Gray.opacity(0.6f))
                    .color(AppColors.TextColor).toAttrs()
            ) { Text(if (soru > 0) soru.toString() else "-") }
            Td(cellStyle.color(AppColors.NumGreen).toAttrs()) { Text(if (soru > 0) dogru.toString() else "-") }
            Td(cellStyle.color(AppColors.DarkRed).toAttrs()) { Text(if (soru > 0) yanlis.toString() else "-") }
            Td(
                cellStyle
                    .fontWeight(FontWeight.SemiBold)
                    .borderRight((0.3).px, LineStyle.Solid, AppColors.Gray.opacity(0.6f))
                    .color(AppColors.TextColor).toAttrs()
            ) { Text(net.round(1)) }
        }

        Td(cellStyle
            .fontSize(16.px)
            .fontWeight(FontWeight.SemiBold)
            .color(AppColors.AppBarColorBlue)
            .thenIf(userNet >= highOrt) {
                Modifier.background(AppColors.AwardColor)
            }
            .toAttrs()
        )
        { Text(userNet.round(1)) }

    }
}


@Composable
fun RadioButtonGroup(period: SnfPeriod, onSelect: (SnfPeriod) -> Unit) {
    var selPerd by remember { mutableStateOf(period) }

    Div(attrs = Modifier
        .classNames("btn-group")
        .height(30.px)
        .toAttrs {
            attr("role", "group")
            attr("aria-label", "etüt sınıf rapor periodu")
        }) {
        SnfPeriod.entries.forEach { perd ->
            Input(type = InputType.Radio, attrs = Modifier
                .attrsModifier {
                    attr("autocomplete", "off")

                }
                .id("radioBtn${perd.ordinal}")
                .classNames("btn-check")
                .toAttrs {
                    onInput {
                        onSelect(perd)
                        selPerd = perd
                    }
                    checked(perd == selPerd)
                })
            Label(forId = "radioBtn${perd.ordinal}", attrs = Modifier
                .fontSize(12.px)
                .toAttrs {
                    classes("btn", "btn-outline-secondary")
                }) {
                Text(perd.text)
            }
        }
    }
}


@Composable
fun DersRadioGroup(dersMap: Set<String>, selDers: MutableState<String>) {
    /*
    var resizeObserver: ResizeObserver? by remember { mutableStateOf(null) }
    var isOverflowing by remember { mutableStateOf(false) }
    fun observeDivOverflow(): ResizeObserver? {
        val divElement = document.getElementById("ders-div") as? HTMLElement ?: return null
        val resizeObserver = ResizeObserver { entries, _ ->
            isOverflowing = divElement.scrollWidth > divElement.clientWidth
            console.log("ResizeObserver detected overflow change: $isOverflowing")
        }
        // Start observing the div element
        resizeObserver.observe(divElement)
        return resizeObserver
    }
    LaunchedEffect(Unit){
        resizeObserver = observeDivOverflow()
    }
    DisposableEffect(true) {
        onDispose {
            resizeObserver?.disconnect()
        }
    }*/
    LaunchedEffect(Unit){
        val divElement = document.getElementById("radioBtn${selDers.value}") as? HTMLInputElement
        divElement?.checked = true
    }


    val breakpoint = rememberBreakpoint()
    Box(modifier = Modifier
        .padding(leftRight = if (breakpoint >= Breakpoint.MD) 8.percent else 2.percent)
        .fillMaxWidth()) {
        Div(attrs = Modifier
            .id("ders-div")
            .classNames("btn-group")
            .height(32.px)

            .width(100.percent)
            .minWidth(380.px)
            .overflow(overflowX = Overflow.Auto, overflowY = Overflow.Hidden)
            //.padding(top = 1.px, bottom = if (isOverflowing) 20.px else 1.px)
            .toAttrs {
                attr("role", "group")
                attr("aria-label", "etüt sınıf rapor periodu")
            }) {
            dersMap.forEach {ders ->
                Input(type = InputType.Radio, attrs = Modifier
                    .attrsModifier {
                        attr("autocomplete", "off")
                    }
                    .id("radioBtn$ders")
                    .classNames("btn-check")
                    .toAttrs {
                        onInput {
                            selDers.value = ders
                        }
                        checked(ders == selDers.value)
                    })
                Label(forId = "radioBtn$ders", attrs = Modifier
                    .fontSize(12.px)
                    .toAttrs {
                        classes("btn", "btn-outline-primary")
                    }) {
                    Text(ders)
                }
            }
        }
    }
}



