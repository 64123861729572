package com.kelimesoft.etutpro.pages.sinif

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.ImageUploadResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.pages.ders.DersGunuSaati
import com.kelimesoft.etutpro.pages.etut.AddEtutBottomButtons
import com.kelimesoft.etutpro.pages.etut.DocEtut
import com.kelimesoft.etutpro.pages.etut.DocType
import com.kelimesoft.etutpro.pages.etut.DocTypeSelect
import com.kelimesoft.etutpro.styles.DropDownStyle
import com.kelimesoft.etutpro.styles.DropMenuItemStyle
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.utils.toBase64
import com.kelimesoft.etutpro.viewmodels.SinifVM
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get

@Composable
fun AddSnfEtut(
    snfVm: SinifVM,
    typ: EtutType? = null,
    etut: SnfEtutItem? = null,
    kaynak: SnfKaynak? = null,
    date: String,
    saat: String,
    onKaynak: () -> Unit,
    onClose: () -> Unit,
    onEdit: (SnfEtutItem, List<DocEtut>) -> Unit,
    onAdd: (NewEtutItem, List<DocEtut>) -> Unit
) {
    val pageLimit = 12
    var numberOfDocs = 0
    val scope = rememberCoroutineScope()


    var kaynakList: List<SnfKaynak> by remember { mutableStateOf(listOf()) }
    var dersList = snfVm.snfDersList.map { it.dersad }

    var dersAd by remember { mutableStateOf("") }
    var konu by remember { mutableStateOf("") }
    var soru by remember { mutableStateOf(0) }
    var konuPuan by remember { mutableStateOf(0) }
    var type by remember { mutableStateOf(EtutType.KonuTest) }
    var link by remember { mutableStateOf("") }
    var kaynakId: Long by remember { mutableStateOf(0) }
    var dersKaynak by remember { mutableStateOf("") }
    var etutBelge by remember { mutableStateOf("") }

    var sayfalar by remember { mutableStateOf("") }
    var saati by remember { mutableStateOf("08:30") }

    var notlar by remember { mutableStateOf("") }
    var cevaplar by remember { mutableStateOf("") }

    var docLinks by remember { mutableStateOf(listOf<DocEtut>()) }
    var doctype: DocType? by remember { mutableStateOf(null) }

    val docPageCount: Int = docLinks.sumOf { it.page }

    //val uuidList: MutableSet<String> by remember { mutableStateOf(mutableSetOf()) }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    var disableDers by remember { mutableStateOf(false) }
    var disableKaynak by remember { mutableStateOf(false) }
    var disablePuan by remember { mutableStateOf(false) }

    fun cevapAnahtarView(): String {
        return cevaplar.uppercase().toList()
            .mapIndexed { index, char -> "${index + 1}-$char" }
            .joinToString(", ")
    }

    fun findDerdId(): Long{
        snfVm.snfDersList.firstOrNull { it.dersad == dersAd }?.let { drs ->
            return drs.oid
        }
        return 0
    }

    fun findEtutStartTime(){
        saati = AppData.ViewModel.etutVM.findEtutStartTime(date)
    }
    LaunchedEffect(alertModel){
        if (alertModel != null){
            delay(3000)
            alertModel = null
        }
    }

    LaunchedEffect(true) {
        if (typ != null) {
            type = typ
            findEtutStartTime()
            disableDers = false
            disableKaynak = false
        } else if (etut != null) {
            dersAd = etut.ders
            konu = etut.konu
            soru = etut.soru
            dersKaynak = etut.kaynak
            link = etut.link
            saati = etut.saat
            sayfalar = etut.sayfa
            if (etut.type == EtutType.Konu.type){
                konuPuan = etut.konupuan
            }
            if (etut.belge.isNotEmpty()){
                doctype = DocType.Belge
                etutBelge = etut.belge
            }
            EtutType.entries.find { it.type == etut.type }?.let {
                type = it
            }
            if (etut.type < 2) {
                //konu testi olacak
                //view yerleşsin diye bekliyorum
                delay(200)
                try {
                    (document.getElementById("ders-select") as HTMLSelectElement).value = dersAd
                } catch (_: Exception) {
                }
                //doctype = DocType.Link
            }
            delay(100)
            disableDers = true
            disableKaynak = true
        }else if (kaynak != null){
            saati = saat
            dersAd = kaynak.ders
            kaynakId = kaynak.kid
            dersKaynak = kaynak.isim
            //findEtutStartTime()
            delay(200)
            try {
                (document.getElementById("ders-select") as HTMLSelectElement).value = dersAd
            } catch (_: Exception) {
            }
            delay(200)
            disableDers = true
            disableKaynak = true

        }
    }

    fun saveEtut() {
        val etutDate = etut!!.tarih
        var etutPuan = 0
        if (type.type == EtutType.Konu.type){
            etutPuan = if (konuPuan > 0) konuPuan else 1
        }

        var doclinks = docLinks
        var etutlink = link
        if (doctype == DocType.Link) {
            doclinks = listOf()
        } else {
            etutlink = ""
        }

        val editedItem =
            etut.copy(
                konu = konu,
                notlar = notlar,
                kid = kaynakId,
                kaynak = dersKaynak,
                sayfa = sayfalar,
                soru = soru,
                cevap = cevaplar,
                konupuan = etutPuan,
                link = etutlink,
                belge = etutBelge,
                tarih = etutDate,
                saat = saati
            )

        onEdit(editedItem, doclinks)
    }

    fun addEtut() {
        var errText = ""
        if (dersAd.isEmpty()){
            errText = "Ders seçiniz!"
        }else if(cevaplar.isNotEmpty() && cevaplar.length != soru){
            errText = "Cevap sayısı soru sayısıyla tutarsız: (Soru: $soru, Cevap: ${cevaplar.length})"
        }else if (soru <= 0 && (type == EtutType.KonuTest
                    || type == EtutType.Deneme)){
           errText = "Soru sayısını giriniz!"
        }
        if (errText.isNotEmpty()) {
            alertModel = AlertModel(
                errText, AlertIcon.Warning, AlertStyle.Danger)
            return
        }

        var etutPuan = 0
        if (type.type == EtutType.Konu.type){
            etutPuan = if (konuPuan > 0) konuPuan else 1
        }
        var doclinks = docLinks
        val etutItem = NewEtutItem(
            type = type.type,
            did = findDerdId(),
            ders = dersAd,
            konu = konu,
            notlar = notlar,
            kid = kaynakId,
            kaynak = dersKaynak,
            sayfa = sayfalar,
            soru = soru,
            cevap = cevaplar.ifEmpty { null },
            puan = etutPuan,
            tarih = date,
            saat = saati
        )
        //console.log(etutItem.toString())
        onAdd(etutItem, doclinks)
    }

    fun resizeAndUploadImage(file: File, doc: DocEtut) {
        val maxSize = 1260
        val reader = FileReader()
        reader.onload = { readerEvent ->
            val image = Image()
            image.onload = {
                // Resize the image
                val canvas = document.createElement("canvas") as HTMLCanvasElement
                val context = canvas.getContext("2d") as? CanvasRenderingContext2D
                var width = image.width.toDouble()
                var height = image.height.toDouble()

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width
                        width = maxSize.toDouble()
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height
                        height = maxSize.toDouble()
                    }
                }
                canvas.width = width.toInt()
                canvas.height = height.toInt()
                context?.drawImage(image, 0.0, 0.0, width, height)
                val dataUrl = canvas.toDataURL("image/jpeg")
                val clearBase64 = dataUrl.replace(Regex("^data:.+;base64,"), "")
                //imageBase64 = dataUrl
                //newCow.image = clearBase64
                // sunucuya base64 formatında gönderiyorum
                scope.launch {
                    RestApi.uploadBase64Image(clearBase64)?.let { res ->
                        //console.log("imageupload:", res)
                        Json.decodeFromString<ImageUploadResponse>(res).let { eres ->
                            if (eres.data != null) {
                                val flink = eres.data
                                //console.log("filename:", flink)
                                doc.name = flink
                                val nlist = docLinks.toMutableList()
                                nlist.add(doc)
                                docLinks = nlist.toList()
                            }
                        }
                    }
                }
                //console.log("image-selected")
            }
            if (readerEvent.target != null) {
                image.src = readerEvent.target.asDynamic().result.toString()
            }
            //image.src = readerEvent.target.asDynamic().result as String
        }
        reader.readAsDataURL(file)

    }

    fun uploadPdf(file: File, doc: DocEtut){
        scope.launch {
            file.toBase64()?.let { pdf64 ->
                //console.log("pdfbase64:", pdf64)
                val clearBase64 = pdf64.replace(Regex("^data:.+;base64,"), "")
                RestApi.uploadTempPdf(clearBase64)?.let { res ->
                    Json.decodeFromString<ImageUploadResponse>(res).let { eres ->
                        if (eres.data != null) {
                            val flink = eres.data
                            //console.log("filename:", flink)
                            var nlist = docLinks.toMutableList()
                            doc.name = flink
                            nlist.add(doc)
                            docLinks = nlist.toList()
                        }
                    }
                }
            }

        }
    }

    fun acceptFileDrop(dropEvt: SyntheticDragEvent) {
        var docsay = docPageCount
        dropEvt.dataTransfer?.items?.let { items ->
            for (i in 0 until items.length) {
                if (items[i]?.kind == "file") {
                    val file = items[i]?.getAsFile()
                    if (docsay < pageLimit) {
                        if (file?.type?.matches(Regex("^(application/pdf)$")) == true) {
                            val reader = FileReader()
                            reader.readAsBinaryString(file)
                            reader.onloadend = {
                                val result = reader.result as String
                                val count = result.split(Regex("/Type[\\s]*/Page[^s]")).size - 1
                                if (docsay + count <= pageLimit) {
                                    docsay += count
                                    numberOfDocs += 1
                                    val sira = numberOfDocs
                                    uploadPdf(file, DocEtut("", count, sira))
                                }else{
                                    scope.launch {
                                        alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                                        delay(3000)
                                        alertModel = null
                                    }
                                }
                            }

                        } else if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                            docsay += 1
                            numberOfDocs += 1
                            val sira = numberOfDocs
                            //if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                            var ndoc = DocEtut("", 1, sira)
                            scope.launch {
                                resizeAndUploadImage(file, ndoc)
                            }
                        } else {
                            scope.launch {
                                alertModel = AlertModel("Pdf ve resim dosyalarını ekleyebilirsiniz", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    } else {
                        scope.launch {
                            alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                            delay(3000)
                            alertModel = null
                        }
                    }
                }
            }
        }
    }

    fun selectImage() {
        (document.getElementById("file-select") as HTMLInputElement).click()
    }

    fun removeDoc(doc: DocEtut) {
        val nlist = docLinks.toMutableList()
        try {
            nlist.remove(doc)
            docLinks = nlist.toList()
        }catch (e: Exception){}

    }

    fun updateKaynakList(){
        kaynakList = snfVm.kaynakMap[dersAd] ?: listOf()
    }


    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(520.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .overflow(Overflow.Hidden)
    ) {

        DialogTitle(type.text, onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("etutadd-form")
                .toAttrs()
        ) {

            Row(modifier = Modifier.margin(left = 2.px)
                .margin(bottom = 4.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (type == EtutType.KonuTest || type == EtutType.Konu) {
                    MyBSSelect(
                        modifier = Modifier
                            .attrsModifier {
                                attr("value", dersAd)
                            }
                            .fillMaxWidth(),
                        id = "ders-select",
                        items = dersList,
                        disabled = disableDers,
                        placeholder = "Ders Seçiniz",
                        onItemSelect = { idx, text ->
                            if (idx == -1){
                                dersAd = ""
                            }else{
                                try {
                                    dersList.find { it == text }?.let {
                                        dersAd = it
                                        updateKaynakList()
                                    }
                                } catch (_: Exception) {
                                    dersAd = ""
                                }
                            }
                        }
                    )
                }
                BSTimeInput(
                    modifier = Modifier
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    placeholder = "Ders Saati:",
                    value = saati,
                    onValueChange = {
                        saati = it
                    }
                )
            }

        }
        BSInput(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 14.px),
            type = InputType.Text,
            value = konu,
            placeholder = if (type.type < 2) "Konu" else "Sınav Adı",
            required = true,
            onValueChange = {
                konu = it
            }
        )

        if (dersAd.isNotEmpty()) {
            Row(
                modifier = Modifier
                    .margin(bottom = 4.px),
                verticalAlignment = Alignment.Bottom,

                ) {
                if (etut == null && kaynak == null) {
                    DropDownMenu(
                        menuModifier = Modifier,
                        butonModifier = DropDownStyle.toModifier()
                            .width(38.px)
                            .height(38.px)
                            .padding(6.px)
                            //.margin(topBottom = 8.px)
                            .whiteSpace(WhiteSpace.NoWrap)
                            .onClick {
                                it.stopPropagation()
                                val menu = document.getElementById("add-drop") as HTMLDivElement
                                if (menu.style.display == "block") {
                                    menu.style.display = "none"
                                } else {
                                    Funcs.closeDropDowns()
                                    menu.style.display = "block"
                                }
                            },
                        id = "add-drop",
                        content = {
                            FaCaretDown(
                                modifier = Modifier
                                    .color(AppColors.GrayDark),
                                size = IconSize.LG
                            )

                        }) {
                        kaynakList.forEach { kynk ->
                            Button(
                                attrs = DropMenuItemStyle.toModifier()
                                    .minWidth(150.px)
                                    .padding(3.px)
                                    .color(AppColors.TextColor)
                                    .fontSize(14.px)
                                    .onClick {
                                        it.stopPropagation()
                                        it.preventDefault()
                                        dersKaynak = kynk.isim
                                        kaynakId = kynk.kid
                                        Funcs.closeDropDowns()
                                    }
                                    .toAttrs()) {
                                Text(kynk.isim)
                            }
                        }
                        VDivider()
                        Button(
                            attrs = DropMenuItemStyle.toModifier()
                                .minWidth(150.px)
                                .padding(3.px)
                                .color(AppColors.DarkRed.opacity(0.7f))
                                .fontSize(14.px)
                                .margin(bottom = 2.px)
                                .onClick {
                                    it.stopPropagation()
                                    it.preventDefault()
                                    snfVm.selectDersAdi(dersAd)
                                    onKaynak()
                                    Funcs.closeDropDowns()

                                }
                                .toAttrs()) {
                            Text("+ Yeni Kaynak Ekle")

                        }
                    }
                }
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(leftRight = 6.px),

                    type = InputType.Text,
                    disabled = disableKaynak,
                    value = dersKaynak,
                    placeholder = "Kaynak kitap giriniz veya seçiniz",
                    onValueChange = {
                        dersKaynak = it
                    }
                )

                BookPageRangeInput(
                    modifier = Modifier
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    label = "Sayfalar:",
                    placeholder = "(örnek) 1-15",
                    value = sayfalar,
                    onValueChange = {
                        sayfalar = it
                    }
                )
            }
        }


        if (type == EtutType.KonuTest || type == EtutType.Deneme) {
            DersGunuSaati("${type.text} Soru sayısı", soru, disablePuan) {
                soru = it
            }
        } else if (type == EtutType.Konu) {
            DersGunuSaati("Ders notu sayfa sayısı",
                konuPuan,
                disablePuan
            ) {
                konuPuan = it
            }
        }

        if (type == EtutType.Konu || type == EtutType.KonuTest) {
            if (etut == null) {
                Row(
                    modifier = Modifier.fillMaxWidth()
                        .padding(topBottom = 6.px)
                        .margin(bottom = 4.px)
                        .onDrop { dropevt ->
                            dropevt.preventDefault()
                            if (doctype != DocType.Link){
                                doctype = DocType.Belge
                                if (docLinks.size < pageLimit) {
                                    acceptFileDrop(dropevt)
                                }
                            }

                        }
                        .onDragOver {
                            it.preventDefault()
                            //fileExist = true
                        },
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    SpanText(
                        "Pdf veya Resim dosyaları ekleyebilirsiniz",
                        modifier = Modifier
                            .padding(leftRight = 6.px)
                            .fontSize(14.px)
                            .color(AppColors.DarkRed.opacity(0.7f))
                    )
                    DocTypeSelect(DocType.Belge, selected = doctype == DocType.Belge, onSelect = {
                        doctype = DocType.Belge
                        if (docLinks.size < pageLimit) {
                            selectImage()
                        }
                    })
                }
                Input(
                    type = InputType.File,
                    attrs = Modifier
                        .id("file-select")
                        .display(DisplayStyle.None)
                        .margin(bottom = 10.px)
                        .toAttrs {
                            attr("name", "files")
                            attr("multiple", "true")
                            attr("placeholder", "Select File")
                            attr("accept", "image/*|application/pdf")
                            //attr("accept", "image/*")
                            onInput { evt ->
                                var docsay = docPageCount
                                evt.target.files?.let { files ->
                                    for (i in 0..<files.length) {
                                        if (docsay < pageLimit) {
                                            val file = files[i]
                                            if (file?.type?.matches(Regex("^(application/pdf)$")) == true) {
                                                val reader = FileReader()
                                                reader.readAsBinaryString(file)
                                                reader.onloadend = {
                                                    val result = reader.result as String
                                                    val count = result.split(Regex("/Type\\s*/Page[^s]")).size - 1
                                                    if (docsay + count <= pageLimit) {
                                                        docsay += count
                                                        numberOfDocs += 1
                                                        val sira = numberOfDocs
                                                        uploadPdf(file, DocEtut("", count, sira))
                                                    }else{
                                                        scope.launch {
                                                            alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                                                            delay(3000)
                                                            alertModel = null
                                                        }
                                                    }
                                                }

                                            } else if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                                                docsay += 1
                                                numberOfDocs += 1
                                                val sira = numberOfDocs
                                                val ndoc = DocEtut("", 1, sira)
                                                //if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                                                scope.launch {
                                                    resizeAndUploadImage(file, ndoc)
                                                }
                                            }
                                        } else {
                                            scope.launch {
                                                alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                                                delay(3000)
                                                alertModel = null
                                            }
                                        }
                                    }
                                }
                            }
                        }

                )
            }
            else if (etut.belge.isNotEmpty()){
                //val imglink = "${Images.EtutDocs}${etut.belge}"
                //console.log("imglink: ", imglink)
                Box(
                    modifier = Modifier.fillMaxSize(),
                    contentAlignment = Alignment.BottomCenter
                ) {
                    Image(
                        modifier = Modifier
                            .fillMaxSize()
                            .maxWidth(128.px)
                            .borderRadius(r = 6.px)
                            .objectFit(ObjectFit.Contain),
                        src = Images.PdfIcon
                    )
                    BSButton(modifier = Modifier.margin(bottom = 8.px),
                        text = "Sil",
                        size = ButtonSize.Small,
                        variant = ButtonVariant.DangerOutline,
                        onClick = {
                            etut.belge = ""
                            etutBelge = ""
                        }
                    )
                }
            }

            if (doctype == DocType.Belge && docLinks.isNotEmpty()) {
                Row(modifier = Modifier.fillMaxWidth()
                    //.scrollBehavior(ScrollBehavior.Smooth)
                    .overflow(Overflow.Auto)
                    .whiteSpace(WhiteSpace.NoWrap)
                    .onDrop { dropevt ->
                        dropevt.preventDefault()
                        acceptFileDrop(dropevt)
                    }
                    .onDragOver {
                        it.preventDefault()
                        //fileExist = true
                    }
                    .height(180.px),
                    horizontalArrangement = Arrangement.Start
                ) {
                    docLinks.forEachIndexed { index, doc ->
                        //console.log("image:", Images.TempImgDir, doc)
                        val imglink = "${Images.TempImgDir}${doc.name}"
                        //console.log("imglink: ", imglink)
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.BottomCenter
                        ) {
                            Image(
                                modifier = Modifier
                                    .fillMaxSize()
                                    .maxWidth(170.px)
                                    .borderRadius(r = 10.px)
                                    .objectFit(ObjectFit.Contain),
                                src = if (doc.name.endsWith(".pdf")) Images.PdfIcon else imglink
                            )

                            BSButton(modifier = Modifier.margin(bottom = 8.px),
                                text = "${index + 1} - Sil",
                                size = ButtonSize.Small,
                                variant = ButtonVariant.DangerOutline,
                                onClick = {
                                    removeDoc(doc)
                                }
                            )
                        }

                    }
                }
            }
        }

        if (type == EtutType.KonuTest){

            Column(
                modifier = Modifier.fillMaxWidth()
                    .padding(3.px)
                    .backgroundColor(AppColors.Gray.opacity(0.3f))
            ) {
                SpanText("Cevap anahtarını \"abcdcab\" şeklinde boşluk bırakmadan girebilirsiniz.",
                    modifier = Modifier
                        .padding(leftRight = 3.px)
                        .color(AppColors.DetailBlue)
                        .fontSize(12.px)
                )
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth(),
                    type = InputType.Text,
                    value = cevaplar,
                    placeholder = "Cevap Anahtarı",
                    onValueChange = { nval ->
                        cevaplar = nval.filter { it.lowercaseChar() in snfVm.cevapCharSet }
                        /*
                        cevaplar = newValue.filter {
                            it.lowercaseChar() in 'a'..'e' || it == '-' || it == ','
                         }
                         */
                    }
                )
                SpanText(cevapAnahtarView(),
                    modifier = Modifier
                        .padding(leftRight = 2.px, topBottom = 2.px)
                        .color(AppColors.WaterfallGreen)
                        .fontSize(FontSize.Smaller)
                )

            }

        }
        AddEtutBottomButtons(onClose = {
            onClose()
        }, onSave = {
            if (etut != null){
                saveEtut()
            }else {
                addEtut()
            }

        })

    }

    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style
        )
    }

}