package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.PageBox
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.LoginResponse
import com.kelimesoft.etutpro.network.RestApi
import com.stevdza.san.kotlinbs.components.BSSpinner
import com.stevdza.san.kotlinbs.models.SpinnerVariant
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.dom.Text

@Page("/")
@Composable
fun HomePage() {
    IfUserExist(Screens.Home){
        //Routes.activeRoute = Routes.Home
        RouterPage()
    }
}

@Composable
fun IfUserExist(screen: Screens, content: @Composable () -> Unit) {
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()
    var userUUID by remember { mutableStateOf("") }

    LaunchedEffect(true){
        //console.log(AppData.appUser.uuid)
       if(AppData.appUser.uuid.isEmpty()){
            val uuid = AppStorage.getString(AppStorage.userUUID, "")
           if (uuid.isNotEmpty()){
               scope.launch {
                   if (getAppUser()){
                       AppData.appRoutes.activeRoute = screen
                       userUUID = uuid
                   }else{
                       AppData.appRoutes.navigateTo(context,Screens.Login)
                   }
               }
           }else{
               AppData.appRoutes.navigateTo(context,Screens.Login, true)
           }
       }else{
           AppData.appRoutes.activeRoute = screen
           userUUID = AppData.appUser.uuid
       }
    }
    if (userUUID.isNotEmpty()) {
        content()
    }else{
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            BSSpinner(variant = SpinnerVariant.Default)
        }

    }
}


suspend fun getAppUser(): Boolean {
    RestApi.getAppUser()?.let { res ->
        Json.decodeFromString<LoginResponse>(res).let { ares ->
            if (ares.data != null) {
                AppData.appUser = ares.data
                AppStorage.setString(AppStorage.userUUID, AppData.appUser.uuid)
                return true
            } else if (ares.error != null) {
                return false
            }
        }
    }
    return false
}


