package com.kelimesoft.etutpro.pages.kaynak

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.DialogType
import com.kelimesoft.etutpro.components.DialogView
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ozet.OzetSubFaaliyet
import com.kelimesoft.etutpro.styles.ContexMenuItemStyle
import com.kelimesoft.etutpro.styles.FaaliyetDersChevStyle
import com.kelimesoft.etutpro.styles.OzetDersColStyle
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.viewmodels.EtutVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.left
import com.varabyte.kobweb.compose.css.top
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

@Composable
fun EtutKaynakList(
    etutVM: EtutVM,
    uid: String? = null,
    onKaynak: () -> Unit,
    onAddDers: () -> Unit,
    onDrag: (DersKaynak) -> Unit,
    onDragType: (KaynakType) -> Unit
) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val kaynakVM = AppData.ViewModel.kaynakVM
    val dersVM = AppData.ViewModel.dersVM

    var uuid: String? by remember { mutableStateOf(uid) }
    var expandedDers = remember { mutableStateOf("") }
    var allSoruPuan by remember { mutableStateOf(0) }

    var showKaynakDel by remember { mutableStateOf(false) }



    LaunchedEffect(etutVM.etutListDersMap) {
        var sorupuan = 0
        etutVM.allEtutList.forEach {
            if (it.type == 0) {
                sorupuan += it.soru
            } else if (it.type == 1) {
                sorupuan += it.puan.toInt()
            }
        }
        allSoruPuan = sorupuan
    }

    LaunchedEffect(ogrenciVM.selectedOgrenci, AppData.appUser.role, kaynakVM.kaynakMap) {
        if (uid == null){
            if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci != null) {
                uuid = ogrenciVM.selectedOgrenci!!.uuid
            } else {
                uuid = AppData.appUser.uuid
            }
        }
        kaynakVM.getKaynakList(uuid)

    }
    Box(
        modifier = Modifier
            .fillMaxHeight()
            .width(if (breakpoint < Breakpoint.MD) 200.px else 250.px)
            .padding(leftRight = 2.px)
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(AppColors.Gray.opacity(0.2f))
                .boxShadow(
                    1.px,
                    1.px,
                    1.px,
                    1.px,
                    color = AppColors.GrayDark.opacity(0.1f)
                )
                .borderRadius(4.px)

        ) {
            OzetSubFaaliyet(
                title = "Dersler / Soru: $allSoruPuan",
                fontSize = 13.px
            ) {
                kaynakVM.kaynakMap.forEach { map ->
                    var color = AppColors.colorDersList[(0..8).random()]
                    dersVM.dersList.find { it.dersad == map.key }?.let {
                        color = AppColors.colorDersList[it.renk]
                    }
                    Column(
                        modifier = OzetDersColStyle
                            .toModifier()
                            .onMouseLeave {
                                etutVM.kaynakDersOver = ""
                            }
                            .borderRadius(4.px)
                            .backgroundColor(color.opacity(0.3f))
                            .fillMaxWidth(),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        KaynakDersView(etutVM, map, expandedDers)

                        if (expandedDers.value == map.key) {
                            map.value.forEach { kaynak ->
                                if (kaynak.id > 0) {
                                    KaynakInfo(etutVM, map.key, kaynak, onEdit = {
                                        kaynakVM.selectKaynak(kaynak)
                                        onKaynak()
                                    }, onDelete = {
                                        kaynakVM.selectKaynak(kaynak)
                                        showKaynakDel = true
                                    }) {
                                        onDrag(kaynak)
                                    }
                                }
                            }

                            BSButton(
                                modifier = Modifier
                                    .height(22.px)
                                    .fontSize(12.px)
                                    .margin(leftRight = 10.px, topBottom = 2.px),
                                text = "+Kaynak Ekle",
                                size = ButtonSize.Small,
                                variant = ButtonVariant.SecondaryOutline,
                                onClick = {
                                    kaynakVM.selectDersAdi(map.key)
                                    onKaynak()
                                }
                            )

                        }


                    }

                }

                BSButton(
                    modifier = Modifier.height(24.px)
                        .fontSize(13.px)
                        .margin(leftRight = 10.px, topBottom = 2.px),
                    text = "+Ders Ekle",
                    size = ButtonSize.Small,
                    variant = ButtonVariant.SuccessOutline,
                    onClick = {
                        onAddDers()
                    }
                )
            }

            SpanText(
                "Kaynakları sürükleyerek etüt ekleyebilirsiniz.",
                modifier = Modifier
                    .color(AppColors.DarkRed)
                    .fontSize(FontSize.Smaller)
                    .padding(6.px)
            )

            OzetSubFaaliyet(
                title = "Diğer Kaynaklar",
                fontSize = 13.px
            ) {
                Column(modifier = Modifier.fillMaxWidth()) {
                    KaynakType.entries.forEach { ktip ->
                        DigerKaynakItem(ktip, onDrag = {
                            onDragType(ktip)
                        })
                    }
                }

            }

        }


        if (showKaynakDel) {
            DialogView {
                KaynakDelAlert(onDelete = {
                    scope.launch {
                        kaynakVM.deleteSelectKaynak()
                        showKaynakDel = false
                    }
                }){
                    showKaynakDel = false
                }
            }
        }
    }

}

@Composable
fun KaynakDersView(
    etutVM: EtutVM,
    dersMap: Map.Entry<String, List<DersKaynak>>, expanded: MutableState<String>
) {
    val scope = rememberCoroutineScope()
    var soruSay by remember { mutableStateOf(0) }
    LaunchedEffect(etutVM.etutListDersMap, dersMap.value) {
        var soru = 0
        etutVM.etutListDersMap[dersMap.key]?.forEach {
            if (it.type == 0) {
                soru += it.soru
            } else if (it.type == 1) {
                soru += it.puan.toInt()
            }
        }
        soruSay = soru
    }


    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .onMouseOver {
                    val nkay = etutVM.kaynakDersOver
                    scope.launch {
                        delay(200)
                        if (nkay == etutVM.kaynakDersOver && nkay != dersMap.key){
                            etutVM.kaynakDersOver = dersMap.key
                        }
                    }
                }
                .onMouseLeave {
                    etutVM.kaynakDersOver = ""
                }
                .cursor(Cursor.Pointer)
                .onClick {
                    if (expanded.value == dersMap.key) {
                        expanded.value = ""
                    } else {
                        expanded.value = dersMap.key
                    }
                },
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(
                attrs = Modifier
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(AppColors.TextColor.opacity(0.8f))
                    .toAttrs()
            ) {
                Text(dersMap.key.ifEmpty { "Ders belirtilmemiş" })
            }
            Spacer()

            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .fontWeight(if (soruSay > 0) FontWeight.Medium else FontWeight.Normal)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text(soruSay.toString())
            }

            Box(
                modifier = FaaliyetDersChevStyle.toModifier(),
                contentAlignment = Alignment.Center
            ) {
                if (expanded.value == dersMap.key) {
                    FaChevronDown(
                        size = IconSize.SM,
                        modifier = Modifier.color(AppColors.DetailBlue)
                    )
                } else {
                    FaChevronRight(size = IconSize.SM, modifier = Modifier.color(AppColors.GrayDark))
                }

            }

        }
    }
}


@Composable
fun KaynakInfo(
    etutVM: EtutVM, ders: String, kaynak: DersKaynak,
    onEdit: () -> Unit,
    onDelete: () -> Unit,
    onDrag: () -> Unit
) {
    var soruSay by remember { mutableStateOf(0) }
    LaunchedEffect(etutVM.etutListDersMap) {
        var soru = 0
        etutVM.etutListDersMap[ders]?.filter { it.kaynak == kaynak.isim }?.forEach {
            if (it.type == 0) {
                soru += it.soru
            } else if (it.type == 1) {
                soru += it.puan
            }
        }
        soruSay = soru
    }
    Div(
        attrs = Modifier.fillMaxWidth()
            .position(Position.Relative)
            .display(DisplayStyle.InlineBlock)
            .toAttrs()
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .cursor(Cursor.Move)
                .padding(2.px)
                .backgroundColor(Colors.White)
                .borderRadius(r = 4.px)
                .margin(bottom = 3.px)
                .draggable(true)
                .onDragStart {
                    onDrag()
                }
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .thenIf(kaynak.sid == AppData.appUser.id) {
                        Modifier
                            .onContextMenu {
                                //it.stopPropagation()
                                it.preventDefault()
                                val menu = document.getElementById("drop-kay${kaynak.id}") as HTMLDivElement
                                if (menu.style.display == "block") {
                                    menu.style.display = "none"
                                } else {
                                    Funcs.closeDropDowns()
                                    menu.style.display = "block"
                                }
                            }
                    },
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Box(
                    modifier = Modifier.width(28.px),

                    contentAlignment = Alignment.CenterStart
                ) {
                    Image(
                        modifier = Modifier
                            .objectFit(ObjectFit.Contain)
                            .margin(2.px)
                            .maxWidth(18.px)
                            .borderRadius(4.px),
                        src = if (kaynak.kapak.isEmpty()) Images.EtutBook else "https://etutpro.com/kaynak-thumb/" + kaynak.kapak,
                        alt = "kgs, tyt etüt kaynak kitapları"

                    )
                }

                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .color(AppColors.TextColor.opacity(0.7f))
                        .fillMaxWidth()
                        .toAttrs()
                ) {
                    Text(kaynak.isim)
                }

                //Spacer()

                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .color(AppColors.TextColor)
                        .fontWeight(if (soruSay > 0) FontWeight.Medium else FontWeight.Normal)
                        .padding(right = 4.px)
                        .toAttrs()
                ) {
                    Text(soruSay.toString())
                }


            }

            Div(
                attrs = Modifier
                    .id("drop-kay${kaynak.id}")
                    .classNames("app-menu")
                    .display(DisplayStyle.None)
                    .position(Position.Absolute)
                    .styleModifier {
                        property("right", "0")
                    }
                    .backgroundColor(AppColors.DropMenu)
                    .borderRadius(r = 6.px)
                    .boxShadow(0.px, 8.px, 16.px, 0.px, Color.rgba(0, 0, 0, 0.2f))
                    .minWidth(120.px)
                    //.maxWidth(200.px)
                    .padding(10.px)
                    .zIndex(4)
                    .toAttrs()
            ) {
                Column {
                    SpanText("Edit", modifier = ContexMenuItemStyle
                        .toModifier()
                        .onClick {
                            onEdit()
                        })

                    VDivider(modifier = Modifier.margin(bottom = 1.px))
                    SpanText("Sil", modifier = ContexMenuItemStyle
                        .toModifier()
                        .onClick {
                            onDelete()
                        })


                }

            }

        }
    }
}


@Composable
fun KaynakDelAlert(onDelete: () -> Unit, onClose: () -> Unit) {
    Column (modifier = Modifier.padding(4.px)) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
                .padding(4.px)
                .borderRadius(r = 6.px)
                .backgroundColor(Colors.White),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            DialogTitle("Kaynak Sil", onClose = onClose)

            SpanText(text = "Seçtiğiniz kaynak silinecek.", modifier = Modifier
                .color(AppColors.DarkRed)
                .fontSize(FontSize.Small)
            )

            BSButton(
                modifier = Modifier
                    .margin(topBottom = 6.px),
                text = "Sil",
                size = ButtonSize.Small,
                variant = ButtonVariant.Danger,
                onClick = {
                    onDelete()
                }
            )

        }

        Box(modifier = Modifier.minHeight(70.px)) {  }

    }


}


@Composable
fun DigerKaynakItem(
    type: KaynakType,
    onDrag: () -> Unit
) {
    var x by remember { mutableStateOf(0) }  // Store current X position
    var y by remember { mutableStateOf(0) }
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(2.px)
                .cursor(Cursor.Move)
                .backgroundColor(AppColors.TextColor.opacity(0.7f))
                .borderRadius(r = 4.px)
                .margin(bottom = 3.px)
                .draggable(true)

                .onDragStart {
                    onDrag()
                }
                .padding(2.px)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(bottom = 2.px)
                ,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Box(modifier = Modifier.width(30.px),
                    contentAlignment = Alignment.Center) {
                    if (type == KaynakType.Video) {
                        FaYoutube(modifier = Modifier.color(AppColors.Red), size = IconSize.LG)
                    }else{
                        FaFilePdf(modifier = Modifier.color(AppColors.DarkRed), style = IconStyle.FILLED, size = IconSize.LG)
                    }
                }

                Span(
                    attrs = Modifier
                        .fontSize(13.px)
                        .color(Colors.White)
                        .fillMaxWidth()
                        .padding(2.px)
                        .margin(left = 2.px)
                        .toAttrs()
                ) {
                    Text(type.text)
                }

            }

    }
}








