package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.models.AppData.ViewModel.ogrenciVM
import com.kelimesoft.etutpro.styles.RowButtonStyle
import com.kelimesoft.etutpro.styles.SearchBarStyles
import com.kelimesoft.etutpro.utils.Funcs
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLDivElement


@Composable
fun EtutSearch() {
    var etutVM = AppData.ViewModel.etutVM
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()

    var searchText by remember { mutableStateOf("") }
    Row(verticalAlignment = Alignment.CenterVertically) {
        Input(
            type = InputType.Text,
            attrs = SearchBarStyles.toModifier()

                .attrsModifier {
                    attr("placeholder", "Ara")
                }
                .thenIf(breakpoint > Breakpoint.SM){
                    Modifier.minWidth(160.px)
                }
                .then(
                    other = Modifier.maxWidth(110.px)
                )
                .toAttrs {
                    onInput {
                        searchText = it.value
                    }
                }
        )

        SearchDropDownMenu(
            butonModifier = RowButtonStyle.toModifier()
                .margin(right = 4.px)
                .onClick {
                    it.stopPropagation()
                    if (searchText.length > 2){
                        val menu = document.getElementById("search-box") as HTMLDivElement
                            //Funcs.closeDropDowns()
                        menu.style.display = "block"
                        var uuid: String? = null
                        scope.launch {
                            if (AppData.appUser.role > 0) {
                                ogrenciVM.getOgrenciList(false)
                                if (ogrenciVM.selectedOgrenci != null) {
                                    uuid = ogrenciVM.selectedOgrenci!!.uuid
                                }
                            }
                            etutVM.getSearchEtut(searchText, uuid)
                        }


                    }

                },
            rightAlign = true,
            id = "search-box",
            content = {
                RowButton(modifier = Modifier.margin(left = 10.px)) {
                    FaIcon("search",
                        modifier = Modifier
                            .color(AppColors.TextColor.opacity(0.7f)),
                        size = IconSize.LG,
                        style = IconCategory.SOLID
                        )
                }
            }
        ) {
            Column(modifier = Modifier
                .fillMaxWidth()
                .minWidth(300.px)
                .maxWidth(500.px)
                .overflow(Overflow.Hidden)
            ) {

                Row(modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically) {
                    SpanText("Arama Sonucu:",
                        modifier = Modifier.fillMaxWidth()
                            .fontSize(12.px)
                            .color(AppColors.TextColor))
                    Spacer()
                    BSButton(
                        text = "Kapat",
                        size = ButtonSize.Small,
                        variant = ButtonVariant.Danger
                    ){
                        Funcs.closeDropDowns()
                    }
                }
                VDivider(modifier = Modifier.padding(topBottom = 4.px))

                Column(modifier = Modifier.fillMaxWidth()
                    .maxHeight(500.px)
                    .overflow(Overflow.Auto)
                ) {
                    etutVM.searchResult.forEach { etut ->
                        SearchEtutRow(etut)
                    }



                }


            }

        }

    }

}


@Composable
fun SearchEtutRow(
    etut: EtutItem
) {
    val context = rememberPageContext()
    val dersVM = AppData.ViewModel.dersVM
    val typeIcon = EtutType.entries.find { it.type == etut.type }?.icon ?:  "file-contract"
    var dersText by remember { mutableStateOf(etut.ders) }

    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }

    LaunchedEffect(true, ogrenciVM.selectedOgrenci) {
        if (etut.type < 2) {
            if (dersVM.dersList.isNotEmpty()) {
                dersVM.dersList.find { it.dersad == etut.ders }?.let {
                    dersColor = AppColors.colorDersList[it.renk]
                }

            }
        }else if (etut.type == EtutType.Kitap.ordinal){
            dersText = "Kitap Okuma"
            dersColor = AppColors.PrimaryDark
        }else if (etut.type == EtutType.Deneme.ordinal){
            dersColor = AppColors.DarkRed
            dersText = "Sınav / Deneme"
        }
    }

    Box(
        modifier = Modifier.fillMaxWidth()
            .borderRadius(r = 6.px)
            .padding(2.px),
        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .minHeight(70.px)
                .boxShadow(
                    1.px,
                    1.px,
                    1.px,
                    1.px,
                    color = if (etut.done) AppColors.PrimaryDark.opacity(0.2f) else Colors.DarkBlue.opacity(0.2f)
                )
                .backgroundColor(
                    if (etut.done) AppColors.PrimaryDark.opacity(0.1f) else Colors.White
                )
                .borderRadius(r = 6.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.CenterVertically) {
                MyFaIcon(Modifier.color(dersColor), icon = typeIcon)
                SpanText(
                    dersText,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(left = 2.px)

                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
                Spacer()
                SpanText(
                    etut.tarih,
                    modifier = Modifier
                        .fillMaxWidth()
                        .textAlign(TextAlign.End)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .fontStyle(FontStyle.Italic)
                        .color(AppColors.TextColor.opacity(0.7f))
                        .fontSize(12.px)
                        .fontWeight(FontWeight.Normal)
                        .padding(1.px)
                )

            }


            SpanText(
                etut.konu,
                modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor.opacity(0.9f))
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(1.px)
            )

            if (etut.soru > 0) {
                SpanText(
                    if (etut.type == 2) "Sayfa Sayısı: ${etut.soru}" else "Soru Sayısı: ${etut.soru}",
                    modifier = Modifier.fillMaxWidth()
                        .color(AppColors.TextColor)
                        .fontSize(15.px)
                        .padding(1.px)
                )
            }

            if (etut.kaynak.isNotEmpty()) {
                Row(modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Start,
                    verticalAlignment = Alignment.CenterVertically
                    ) {
                    SpanText(
                        etut.kaynak,
                        modifier = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                    if (etut.sayfa.isNotEmpty()) {
                        SpanText(
                            " (syf: ${etut.sayfa})",
                            modifier = Modifier
                                .color(AppColors.Gray)
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }

                }

            }

            if (etut.link.isNotEmpty()) {
                Row(modifier = Modifier
                    .backgroundColor(AppColors.AppBarButonColor)
                    .margin(4.px)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        context.router.navigateTo(etut.link)
                    }
                    .borderRadius(r = 10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FaYoutube(modifier = Modifier.color(AppColors.Red), size = IconSize.XL)
                    SpanText(
                        "Video İzle",
                        modifier = Modifier.fillMaxWidth()
                            .margin(leftRight = 4.px)
                            .color(AppColors.ViewWhite)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }
            }
            //Box(modifier = Modifier.fillMaxWidth().height(1.px).backgroundColor(Colors.Gray.opacity(0.4f)))
        }

    }

}